<script setup>
import AppPage from "@/components/AppPage.vue";
import AppTable from "@/components/AppTable.vue";
import {columnsSkillSet} from "@/pages/jobMgmt/skillSet/skill-set.js";
import {deleteSkill, getMasterSkill, postSkill, UpdateSkill} from "@/lib/api.js";
import {
  MESSAGE_FAILED,
  MESSAGE_SUCCESS_CREATED,
  MESSAGE_SUCCESS_DELETED,
  MESSAGE_SUCCESS_UPDATED
} from "@/constants/default-messages.js";
import useNotify from "@/plugins/notify/index.js";
import {ref} from "vue";
// import QViewButtonGroup from "@/components/button/QViewButtonGroup.vue";
import QEditButtonGroup from "@/components/button/QEditButtonGroup.vue";
import QDeleteButtonGroup from "@/components/button/QDeleteButtonGroup.vue";
import FormSkillSet from "@/pages/jobMgmt/skillSet/FormSkillSet.vue";
import {CONFIRMATION_TITLE_LABEL, DELETE_CONFIRMATION_LABEL} from "@/constants/label.js";
import useLoader from "@/plugins/loader/index.js";
import useConfirmation from "@/plugins/confirmation/index.js";
const tableSkill = ref(null);
const loader = useLoader();
const confirmation = useConfirmation();
const notify = useNotify();
const keyword = ref('');
const modal = ref(false);
const mode = ref('CREATE');
const rows = ref([]);
const form = ref({
  id: null,
  name:'',
  code: '',
})

const handleTambah = () => {
  mode.value = 'CREATE'
  modal.value = true;
}

const fetchSkillSet = (offset, limit) => {
  const params = {
    offset,
    limit,
    keyword: keyword.value,
    order: 'desc',
    sort: 'id'
  }

  return getMasterSkill(params).then(res => {
    return {
      rows: res.data,
      total: res.total
    }
  }).catch((err) => {
    notify.show({
      message: err.message || err.messages || err.error || MESSAGE_FAILED,
      type: 'error'
    })
  })
}

const handleSave = () => {
  loader.show();
  return postSkill(form.value).then(() => {
    notify.show({
      message: MESSAGE_SUCCESS_CREATED,
      type: 'success'
    })
    modal.value = false;
    tableSkill.value.reset();
    loader.hide();
  }).catch((err) => {
    notify.show({
      message: err.message || err.messages || err.error || MESSAGE_FAILED,
      type: 'error'
    })
  })
}

const onDeleteItem = (id) => {
  confirmation
      .setTitle(CONFIRMATION_TITLE_LABEL)
      .setMessage(DELETE_CONFIRMATION_LABEL)
      .setOnConfirm(() => {
        loader.show();
        return deleteSkill(id)
            .then(() => {
              notify.show({
                message: MESSAGE_SUCCESS_DELETED,
                type: 'success'
              })
              tableSkill.value.reset();
            })
            .catch((err) => {
              notify.show({
                message: err.message || err.messages || err.error || MESSAGE_FAILED,
                type: 'error'
              })
            })
            .finally(() => {
              loader.hide();
            })
      }).show();
}

const handleUpdate = () => {
  loader.show();
  return UpdateSkill(form.value.id, form.value).then(() => {
    notify.show({
      message: MESSAGE_SUCCESS_UPDATED,
      type: 'success'
    })
    modal.value = false;
    tableSkill.value.reset();
    loader.hide();
  }).catch((err) => {
    notify.show({
      message: err.message || err.messages || err.error || MESSAGE_FAILED,
      type: 'error'
    })
  })
}

const handleShowModalEdit = (row) => {
  mode.value = 'EDIT'
  form.value.id = row.id;
  form.value.name = row.name;
  form.value.code = row.code;
  modal.value = true;
}

</script>

<template>
  <AppPage title="Keahlian">

    <template v-slot:default>
      <div class="flex justify-end q-mb-md">
        <q-btn id="btn-add-skill" icon="add" unelevated label="Tambah" color="primary" @click="handleTambah"/>
      </div>
      <AppTable
          ref="tableSkill"
          enable-filter
          id="table-list-skill-set"
          :columns="columnsSkillSet"
          :fetch="fetchSkillSet"
          v-model:keyword="keyword"
          v-model:rows="rows"
          rows-per-page="10"
      >
        <template v-slot:body-cell-no="props">
          <q-td :props="props">
            {{props.rowIndex + 1}}
          </q-td>
        </template>
        <template v-slot:body-cell-action="props">
          <q-td :props="props" class="q-gutter-x-xs">
            <QEditButtonGroup @click="handleShowModalEdit(props.row)" />
            <QDeleteButtonGroup @click="onDeleteItem(props.row?.id)"/>
          </q-td>
        </template>
      </AppTable>
      <FormSkillSet
          v-model="modal"
          :mode="mode"
          v-model:form="form"
          @save="handleSave"
          @update="handleUpdate"
      />
    </template>
  </AppPage>
</template>