export const columnsQuestionMaster = [
    {
        label: 'No',
        name: 'no',
        field: 'no',
        align: 'center',
        headerStyle: 'width: 1rem'
    },
    {
        label: 'Nama',
        name: 'name',
        field: 'name',
        align: 'left'
    },
    {
        label: 'Deskripsi',
        name: 'description',
        field: 'description',
        align: 'left'
    },
    {
        label: 'Aksi',
        name: 'action',
        field: 'action',
        align: 'center',
        headerStyle: 'width: 10rem'

    }
]
