export const columnsReference = [
    {
        label: 'Type',
        name: 'type',
        field: 'type',
        align: 'left'
    },
    {
        label: 'Code',
        name: 'code',
        field: 'code',
        align: 'left'
    },
    {
        label: 'Value',
        name: 'name',
        field: 'name',
        align: 'left'
    },
    {
        label: 'Description',
        name: 'description',
        field: 'description',
        align: 'left'
    },
    {
        label: 'Aksi',
        field: 'action',
        name: 'action',
        align: 'center',
        headerStyle: 'width: 10rem'
    }
];


