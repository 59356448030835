import {useQuasar} from "quasar";

const useNotify = () => {
    const $q = useQuasar();
    const show = ({message, type, timeout = 3000}) => {
        const position = 'bottom'
        let color = 'primary';
        if (type === 'error') {
            color = 'red'
        } else if (type === 'warning') {
            color = 'yellow'
        }
        return $q.notify({
            message,
            type,
            color,
            position,
            timeout
        })
    }

    return {
        show
    }
}

export default useNotify;
