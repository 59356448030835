<script setup>
import AppPage from "@/components/AppPage.vue";
import {computed, ref} from "vue";
import {getListEmailLoggier, sendEmail, updateAndSendEmail} from "@/lib/api.js";
import AppTable from "@/components/AppTable.vue";
import useLoader from "@/plugins/loader/index.js";
import useNotify from "@/plugins/notify/index.js";
import useConfirmation from "@/plugins/confirmation/index.js";
import {CONFIRMATION_TITLE_LABEL} from "@/constants/label.js";
import QViewButtonGroup from "@/components/button/QViewButtonGroup.vue";
import QActionButton from "@/components/button/QActionButton.vue";

const dialog = ref(false);
const rows = ref([]);
const detail = ref([]);
const keyword = ref('');
const notify = useNotify();
const loader = useLoader();
const confirm = useConfirmation();
const form = ref({
  body: null,
  email: null,
  id: null,
  status: null
})

const columns = computed(() => {
  return [
    {
      label: 'No',
      name: 'no',
      field: 'no',
      align: 'center',
      headerStyle: 'width: 1rem'
    },
    {
      label: 'Email',
      name: 'email',
      field: 'email',
      align: 'left'
    },
    {
      label: 'Lowongan',
      name: 'lowongan',
      field: (row) => row.job?.job_title,
      align: 'left'
    },
    {
      label: 'Tahapan',
      name: 'tahapan',
      field: (row) => row.state_flow?.mststate?.name,
      align: 'left'
    },
    {
      label: "Status",
      name: "status",
      field: "status",
      align: "center"
    },
    {
      label: 'Markup',
      name: 'message',
      field: 'message',
      align: 'left'
    },
    {
      label: 'Tag',
      name: 'tag',
      field: 'tag',
      align: 'left'
    },
    {
      label: 'Aksi',
      name: 'action',
      field: 'action',
      align: 'center',
      headerStyle: 'width: 10rem'

    }
  ]
})

const fetch = (offset, limit) => {
  const params = {
    offset,
    limit,
    keyword: keyword.value,
    order: 'desc',
  }

  return getListEmailLoggier(params)
      .then((res) => {
        return {
          rows: res.data,
          total: res.total
        }
      }).catch((error) => {
        notify.show({message: error.message, type: 'error'})
      }).finally(() => {
        loader.hide()
      })
}
const doSendEmail = (id) => {
  confirm
      .setTitle(CONFIRMATION_TITLE_LABEL)
      .setMessage(`Apakah anda yakin ingin mengirim kembali ke email ini?`)
      .setOnConfirm(() => {
        loader.show();
        sendEmail(id)
            .then(res => {
              notify.show({message: res.message, type: 'success'})
            }).catch(err => {
          notify.show({message: err.message, type: 'error'})
        }).finally(() => {
          loader.hide();
        })
      })
      .setOnCancel(() => { return false; }).show();

}

const showDetail = (row) => {
  form.value.id = row.id;
  form.value.body = row.body;
  form.value.email = row.email;
  form.value.status = row.status;
  dialog.value = true;
}

const closeDialog = () => {
  dialog.value = false;
  form.value.id = null;
  form.value.body = null;
  form.value.email = null;
  form.value.status = null;
}

const doSave = () => {
  if (!form.value.body) {
    notify.show({message: 'isi email wajib diisi', type: 'warning'})
    return false;
  }

  confirm
      .setTitle(CONFIRMATION_TITLE_LABEL)
      .setMessage('Apakah anda yakin ingin mengubah isi dan mengirim kembali ke email ini?')
      .setOnConfirm(() => {
        loader.show();
        updateAndSendEmail(form.value)
            .then(res => {
              notify.show({message: res.message, type: 'success'})
            }).catch(err => {
          notify.show({message: err.message, type: 'error'})
        }).finally(() => {
          loader.hide()
        })
      }).setOnCancel(() => { return false; }).show();
}

</script>

<template>
  <AppPage :title="'Email Logger Pelamar'">
    <AppTable
        :columns="columns"
        v-model:rows="rows"
        :fetch="fetch"
    >
      <template v-slot:body-cell-no="props">
        <q-td :props="props">
          {{ props.rowIndex + 1 }}
        </q-td>
      </template>
      <template v-slot:body-cell-status="props">
        <q-td :props="props">
          <q-badge v-if="props.row.status === 'SUCCESS'" color="green">{{props.row.status}}</q-badge>
          <q-badge v-else color="red">{{props.row.status}}</q-badge>
        </q-td>
      </template>
      <template v-slot:body-cell-action="props">
        <q-td :props="props" class="q-gutter-x-xs">
          <QViewButtonGroup @click="showDetail(props.row)"></QViewButtonGroup>
          <QActionButton :icon="'send'" v-if="props.row.status !== 'SUCCESS'" @click="doSendEmail(props.row.id)"></QActionButton>
        </q-td>
      </template>
    </AppTable>

    <q-dialog v-model="dialog" persistent>
      <q-card class="w-75">
        <q-card-section class="q-gutter-md">
          <slot name="title">
            <p class="text-h6 q-mb-none text-bold">Detil</p>
          </slot>
          <div>
            <label class="text-label">Isi Email<span class="text-red">*</span></label>
            <q-editor
                for="input-description"
                v-model="form.body"
                type="textarea"
                :toolbar="[['bold', 'italic', 'strike', 'underline', 'unordered', 'ordered']]"
            />
          </div>
        </q-card-section>
        <div class="q-ma-md">
          <q-btn label="Kembali" color="grey" size="md" @click="closeDialog"></q-btn>
          <q-btn label="Simpan & Kirim" color="green" size="md" @click="doSave" v-if="form.status === 'FAILED'" class="float-right"></q-btn>
        </div>
      </q-card>
    </q-dialog>

  </AppPage>
</template>

<style scoped>

</style>