<script setup>
import AppModal from "@/components/AppModal.vue";
import {ref} from "vue";
import AppTable from "@/components/AppTable.vue";
import {columnsJobVacancy} from "@/pages/jobMgmt/jobVacancy/job-vacancy.js";
import QViewButtonGroup from "@/components/button/QViewButtonGroup.vue";
import QEditButtonGroup from "@/components/button/QEditButtonGroup.vue";
import QDeleteButtonGroup from "@/components/button/QDeleteButtonGroup.vue";
import AppPage from "@/components/AppPage.vue";
import router from "@/router/index.js";
import {deleteJobVacancy, getJobVacancy, getPositionList, getReferences, getStateMaster} from "@/lib/api.js";
import useNotify from "@/plugins/notify/index.js";
import {MESSAGE_FAILED,} from "@/constants/default-messages.js";
import {currencyFormat} from "@/lib/Helper.js";
import dayjs from '@/lib/DateHelper.js'
import QStepButtonGroup from "@/components/button/QStepButtonGroup.vue";
import useConfirmation from "@/plugins/confirmation/index.js";
import useLoader from "@/plugins/loader/index.js";
import FilterCardLowongan from "@/components/FilterCardLowongan.vue";
import VInput from "@/components/input/VInput.vue";
import VPreloadedSelect from "@/components/input/VPreloadedSelect.vue";
import {WORKPLACE_TYPE} from "@/constants/reference.js";
const filter = ref({
  job_title: null,
  position_id: null,
  location: null,
  workplace_type: null,
  state_flows: null
});
const workplacetypeOptions = ref([]);
const positionOptions = ref([]);
const stateOptions = ref([]);
const table = ref(null)
const confirm = useConfirmation();
const loader = useLoader();
const modal = ref(false);
const keyword = ref('');
const notify = useNotify();
const handleTambah = () => {
  router.push({
    name: 'FormJobVacancy',
    params: {
      action: 'create'
    }
  })
}

const handleView = (id) => {
  router.push({
    name: 'FormJobVacancy',
    params: {
      action: 'view',
      id
    }
  })
}

const handleStep = (id) => {
  router.push({
    name: 'JobVacancyFlow',
    params: {
      id
    }
  })
}

const handleEdit = (id) => {
  router.push({
    name: 'FormJobVacancy',
    params: {
      action: 'edit',
      id
    }
  })
}

const handleDelete = (id) => {
  confirm
      .setTitle('Konfirmasi')
      .setMessage('Apakah anda yakin akan menghapus lowongan pekerjaan ini?')
      .setOnConfirm(() => {
        loader.show();
        deleteJobVacancy(id).then(() => {
          notify.show({message: 'Lowongan pekerjaan ini berhasil dihapus', type: 'success'})
        }).catch(err => {
          notify.show({message: err?.message || MESSAGE_FAILED, type: 'error'})
        }).finally(() => {
          table.value.reset()
          loader.hide();
        })
      }).show()
}

const rows = ref([]);

const fetchJobVacancy = (offset, limit) => {
  const params = {
    offset,
    limit,
    keyword: keyword.value,
    order: 'desc',
    sort: 'id'
  }
  return getJobVacancy(params).then((res) => {
    return {
      rows: res.data,
      total: res.total
    }
  }).catch((err) => {
    notify.show({
      message: err.message || err.messages || err.error || MESSAGE_FAILED,
      type: 'error'
    })
  })
}

const getFilterApplicants = () => {
  const filterData = {};
  if (filter.value.job_title) filterData.job_title = filter.value.job_title;
  if (filter.value.position_id) filterData.position_id = filter.value.position_id;
  if (filter.value.location) filterData.location = filter.value.location;
  if (filter.value.workplace_type) filterData.workplace_type = filter.value.workplace_type;
  if (filter.value.state_flows) filterData.state_flows = filter.value.state_flows;
  return filterData;
}

const mappingRowsValue = (applicants) => {
  return applicants.reduce((acc, item) => {
    acc.push({
      ...item,
      job_title: item.job_title,
      position_id: item.position_id,
      location: item.location,
      workplace_type: item.workplace_type,
      state_flows: item.state_flows
    });
    return acc;
  }, []);
}

const fetchfilterJobVacancy = () => {
  const filterData = getFilterApplicants();
  return getJobVacancy(filterData).then((res) => {
    if (res.data) {
      rows.value = mappingRowsValue(res.data);
    }
  })
}

const resetFilter = () => {
  for (let item in filter.value) {
    filter.value[item] = null
  }
  fetchfilterJobVacancy();
}

const fetchWorkplacetype = () => {
  return getReferences(WORKPLACE_TYPE).then((res) => {
    return res
  }).catch(() => {
  })
}

const fetchPosition = () => {
  return getPositionList()
      .then((res) => {
        return res.data;
      })
      .catch(() => {

      })
}


const fetchState = () => {
  return getStateMaster()
      .then((res) => {
        return res.data;
      })
      .catch(() => {

      })
}

</script>

<template>
  <AppPage title="Lowongan Pekerjaan">
    <template v-slot:default>

      <FilterCardLowongan @filter="fetchfilterJobVacancy" @reset="resetFilter">
        <div class="row q-gutter-xl q-mb-md">
          <div class="col">
            <div class="q-mb-sm">Judul Pekerjaan</div>
            <VInput v-model="filter.job_title"/>
          </div>
          <div class="col">
            <div class="q-mb-sm">Posisi</div>
            <VPreloadedSelect
                for="position-field-id"
                v-model="filter.position_id"
                :fetch="fetchPosition"
                :options="positionOptions"
            />
          </div>
        </div>
        <div class="row q-gutter-xl q-mb-md">
          <div class="col">
            <div class="q-mb-sm">Lokasi</div>
            <VInput v-model="filter.location"/>
          </div>
          <div class="col">
            <div class="q-mb-sm">Tipe Tempat Kerja</div>
            <VPreloadedSelect
                for="input-filter-workplace-type"
                v-model="filter.workplace_type"
                emit-value
                transformValueKey="code"
                :fetch="fetchWorkplacetype"
                :options="workplacetypeOptions"
            />
          </div>
        </div>
        <div class="row items-center q-gutter-xl q-mb-md">
          <div class="col">
            <div class="q-mb-sm">Tahapan</div>
            <VPreloadedSelect
                for="position-field-id"
                v-model="filter.state_flows"
                :fetch="fetchState"
                :options="stateOptions"
            />
          </div>
        </div>
      </FilterCardLowongan>

      <div class="flex justify-end q-mb-md">
        <q-btn id="btn-add-job-vacancy" icon="add" unelevated label="Tambah" color="primary" @click="handleTambah"/>
      </div>
      <AppTable ref= "table" id="table-list-job-vacancy" :columns="columnsJobVacancy" enable-filter v-model:rows="rows"
                v-model:keyword="keyword" :fetch="fetchJobVacancy">
        <template v-slot:body-cell-action="props">
          <q-td :props="props" class="q-gutter-x-xs">
            <QStepButtonGroup :id="`btn-action-step-${props.row.id}`" @click="handleStep(props.row.id)"/>
            <QViewButtonGroup :id="`btn-action-view-${props.row.id}`" @click="handleView(props.row.id)"/>
            <QEditButtonGroup :id="`btn-action-edit-${props.row.id}`" @click="handleEdit(props.row.id)"/>
            <QDeleteButtonGroup :id="`btn-action-delete-${props.row.id}`" @click="handleDelete(props.row.id)"/>
          </q-td>
        </template>
        <template v-slot:body-cell-min_salary="props">
          <q-td :props="props">
            <div>{{ currencyFormat(props.row?.min_salary) }}</div>
          </q-td>
        </template>

        <template v-slot:body-cell-max_salary="props">
          <q-td :props="props">
            <div>{{ currencyFormat(props.row?.max_salary) }}</div>
          </q-td>
        </template>

        <template v-slot:body-cell-start_at="props">
          <q-td :props="props">
            <div>{{ dayjs(props.row?.start_at, 'YYYY-MM-DD').format('DD-MM-YYYY') }}</div>
          </q-td>
        </template>

        <template v-slot:body-cell-end_date="props">
          <q-td :props="props">
            <div>{{ dayjs(props.row?.end_date, 'YYYY-MM-DD').format('DD-MM-YYYY') }}</div>
          </q-td>
        </template>

        <template v-slot:body-cell-position="props">
          <q-td :props="props">
            <div>{{props.row.position_name ? props.row.position_name :  props.row.position_name}}</div>
          </q-td>
        </template>

        <template v-slot:body-cell-latest_state="props">
          <q-td :props="props">
            {{ props.row.latest_state?.mststate?.name }}
          </q-td>
        </template>
      </AppTable>
    </template>
  </AppPage>
  <AppModal title="Job Vacancy Detail" v-model:modal="modal"></AppModal>
</template>

<style scoped></style>
