import { Quasar, Notify, Dialog } from "quasar";

const useQuasar = (app) => {
    app.use(Quasar, {
        plugins: {
            Notify,
            Dialog
        }
    })
}

export default useQuasar;
