<script setup>
import {computed} from "vue";

const props = defineProps({
  errors: {
    type: Array,
    default: null,
  },
  hideBottomSpace: {
    type: Boolean,
    default: true
  },
  readonly: {
    type: Boolean,
    default: false
  },
  modelValue: null,
  outlined: {
    type: Boolean,
    default: true
  }
})

const emits = defineEmits(['update:modelValue']);

const model = computed({
  get(){
   return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value);
  }
})
</script>

<template>
  <q-input
      class="full-width"
      :outlined="outlined"
      v-model="model"
      dense
      :readonly="readonly"
      :error="!!errors?.length && errors?.length > 0"
      :hide-bottom-space="hideBottomSpace"
      v-bind="{ ...$attrs, ...$props, ...$slots }"
  >
    <template v-slot:error>
      <slot name="error" v-if="!!errors?.length && errors?.length > 0">
        <div role="alert" v-for="err in errors" :key="err.$uid">
          {{ err?.$message }}
        </div>
      </slot>
    </template>
    <template #prepend v-if="!readonly">
      <slot name="prepend"></slot>
    </template>
    <template #append v-if="!readonly">
      <slot name="append"></slot>
    </template>
  </q-input>
</template>

<style scoped></style>
