import {
    email,
    helpers,
    integer,
    maxLength,
    maxValue,
    minLength,
    minValue,
    numeric,
    required,
    url,
    sameAs
} from "@vuelidate/validators";
import {
    CONFIRM_PASSWORD_LABEL,
    EMAIL_VALIDATE_LABEL,
    INTEGER_VALIDATE_LABEL,
    MAX_LENGTH_VALIDATE_LABEL,
    MAX_VALUE_VALIDATE_LABEL,
    MIN_LENGTH_VALIDATE_LABEL,
    MIN_VALUE_VALIDATE_LABEL, NUMERIC_VALIDATE_LABEL,
    requiredValidateLabel, URL_VALIDATE_LABEL
} from "@/constants/rules-label.js";


export const isRequired = (fieldname) => {
    return {
        required: helpers.withMessage(requiredValidateLabel(fieldname), required)}
}

export const limitValue = (min = 0, max) => {
    if (max) {
        return {
            minValue: helpers.withMessage(MIN_VALUE_VALIDATE_LABEL(min), minValue(min)),
            maxValue: helpers.withMessage(MAX_VALUE_VALIDATE_LABEL(max), maxValue(max)),
        }
    }
}

export const limitCharacter = (fieldname, min, max = null) => {
    if (max) {
        return {
            minLength: helpers.withMessage(MIN_LENGTH_VALIDATE_LABEL(fieldname, min), minLength(min)),
            maxLength: helpers.withMessage(MAX_LENGTH_VALIDATE_LABEL(fieldname, max), maxLength(max)),
        }
    } else {
        return {
            minLength: helpers.withMessage(MIN_LENGTH_VALIDATE_LABEL(fieldname, min), minLength(min)),
        }
    }
}

export const isNumeric = () => {
    return {
        numeric: helpers.withMessage(NUMERIC_VALIDATE_LABEL, numeric)
    }
}

export const isEmail = () => {
    return {
        email: helpers.withMessage(EMAIL_VALIDATE_LABEL, email)
    }
}

export const isInteger = () => {
    return {
        integer: helpers.withMessage(INTEGER_VALIDATE_LABEL, integer),
    }
}

export const isUrl = () => {
    return {
        url: helpers.withMessage(URL_VALIDATE_LABEL, url)
    }
}

export const isSame = (field) => {
    return {
        sameAs: helpers.withMessage(CONFIRM_PASSWORD_LABEL, sameAs(field))
    }
}