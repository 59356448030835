import {setIsActiveLabel} from "@/lib/Helper.js";

export const columnsSkillSet = [
    {
        label: 'No',
        field: 'no',
        name: 'no',
        align: 'left',
        headerStyle: 'width: 15px'
    },
    {
        label: 'Nama',
        field: 'name',
        name: 'name',
        align: 'left'
    },
    {
        label: 'Code',
        field: 'code',
        name: 'code',
        align: 'left'
    },
    {
        label: 'Status',
        field: row => setIsActiveLabel(row.is_active),
        name: 'is_active',
        align: 'left'
    },
    {
        label: 'Aksi',
        field: 'action',
        name: 'action',
        align: 'center',
        headerStyle: 'width: 10rem'
    }
]
