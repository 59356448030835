import {defineStore} from "pinia";
import {computed, reactive, toRefs} from "vue";

const storeJobCode = defineStore('job',
    () => {
        const state = reactive({
            code: null
        })
        const isJobCodeExist = computed(() => state.code !== null);
        const setState = (code) => {
            state.code = code;
        }
        return {
            ...toRefs(state),
            isJobCodeExist,
            setState
        }
    },
    {
        persist: true
    }
)

export default storeJobCode
