export const columnsState = [
    {
        label: 'Nama',
        name: 'name',
        field: 'name',
        align: 'left'
    },
    {
        label: 'Minimum Grade',
        name: 'minimum_grade',
        field: 'minimum_grade',
        align: 'left'
    },
    {
        label: 'Actor',
        name: 'actor',
        field: 'actor',
        align: 'left'
    },
    {
        label: 'Description',
        name: 'description',
        field: 'description',
        align: 'left'
    },
    {
        label: 'Aksi',
        field: 'action',
        name: 'action',
        align: 'center',
        headerStyle: 'width: 10rem'
    }
];


