import { useQuasar } from "quasar";
import { CLOSE_ACTION_MODAL_LABEL, OK_ACTION_MODAL_LABEL } from "@/constants/label.js";

class ConfirmationDialog {
    constructor(q) {
        this.$q = q;
        this.title = '';
        this.message = '';
        this.onConfirm = () => {};
        this.onCancel = () => {};
    }

    setTitle(t) {
        this.title = t;
        return this;
    }

    setMessage(m) {
        this.message = m;
        return this;
    }

    setOnConfirm(fn) {
        this.onConfirm = fn;
        return this;
    }

    setOnCancel(fn) {
        this.onCancel = fn;
        return this;
    }

    show() {
        this.$q
            .dialog({
                title: this.title,
                message: this.message,
                cancel: CLOSE_ACTION_MODAL_LABEL,
                ok: OK_ACTION_MODAL_LABEL,
                persistent: true
            })
            .onOk(this.onConfirm)
            .onCancel(this.onCancel);
    }
}

const useConfirmation = () => {
    const $q = useQuasar();
    return new ConfirmationDialog($q);
}

export default useConfirmation;
