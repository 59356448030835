import {defineStore} from "pinia";
import {computed, reactive, toRefs} from "vue";
import {PELAMAR_ROLE} from "@/constants/roles.js";

const useAuthStore = defineStore('auth',
    () => {
        const state = reactive({
            token: null,
            user: null,
            roles: null,
            email: null,
            is_verified: null,
        })
        const isAuthenticated = computed(() => state.token !== null && state.user !== null && state.roles !== null);
        const isPelamar = computed(() => state.roles !== null ? state.roles[0] === PELAMAR_ROLE && state.is_verified : false);

        const setState = (token, user, roles, email, isVerified) => {
            state.token = token;
            state.user = user;
            state.roles = roles;
            state.email = email;
            state.is_verified = isVerified;
        }
        return {
            ...toRefs(state),
            isAuthenticated, isPelamar,
            setState
        }
    },
    {
        persist: true
    }
)

export default useAuthStore
