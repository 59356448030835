<script setup>
import {useRoute, useRouter} from "vue-router";
import {onMounted, ref} from "vue";
import {refreshAccessToken, verifyAccount} from "@/lib/api.js";
import useNotify from "@/plugins/notify/index.js";

const router = useRouter();
const route = useRoute();
const isVerified = ref(false)
const notify = useNotify();

const goLogin = () => {
  router.push({
    name: 'LoginPage'
  })
}

const msg = ref();
const btnLoading = ref(false)

const verify = (token) => {
  verifyAccount(token).then((res) => {
    msg.value = res.message
    isVerified.value = true
  }).catch((err) => {
    msg.value = err.message
    isVerified.value = false
  })
}

const refreshToken = () => {
  btnLoading.value = true
  refreshAccessToken(route.query.token).then((res) => {
    notify.show({message: res.message, type: 'success'})
  }).catch((err) => {
    notify.show({message: err.message, type: 'error'})
  }).finally(() => {
    btnLoading.value = false
  })
}

onMounted(() => {
  if (!route.query.token){
    router.push({
      name: 'Unauthorized'
    })
  }
  else {
    verify(route.query.token)
  }
})

</script>

<template>
  <div style="margin-top: 10vh;" class="text-center">
    <div v-if="isVerified">
      <q-icon name="verified" size="100px" color="green" class="q-pb-lg"  />
      <h1 class="text-h4">{{msg}}</h1>
      <p style="font-size: 16px;">Silakan login kembali</p>

      <div class="q-ma-xl">
        <q-btn class="bg-primary" label="Login" text-color="white" @click="goLogin"></q-btn>
      </div>
    </div>

    <div v-else>
      <q-icon name="report_problem" size="100px" color="red" class="q-pb-lg"  />
      <h1 class="text-h6">{{msg}}</h1>
      <div class="q-ma-xl">
        <q-btn class="bg-primary" label="Refresh Token" text-color="white" @click="refreshToken" :loading="btnLoading"></q-btn>
      </div>
    </div>
  </div>
</template>

<style scoped></style>