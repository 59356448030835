<script setup>
import {ref} from "vue";
import useAuthStore from "@/stores/auth.js";

const props = defineProps({
  data: Object,
  useCopyClipboard: {
    type: Boolean,
    default: true
  },
  title: String,
  isPelamar: {
    type: Boolean,
    default: false
  },
  expected_salary: null
})

const isCopied = ref(false);

const copyJobCode = () => {
  navigator.clipboard.writeText(props.data.job_code)
  isCopied.value = true;
  setTimeout(() => {
    isCopied.value = false
  }, 2000)
}
const authState = useAuthStore();

</script>
<template>
  <q-card flat class="q-mb-md q-pb-md">
    <q-card-section>
      <div class="flex justify-between q-mb-xl">
        <div class="text-h6  text-bold">{{title}}</div>
        <slot name="top-action"></slot>
      </div>
        <div class="q-gutter-y-md">
          <div class="row">
            <div class="col-2 text-bold">
              Nama Lowongan
            </div>
            <div class="col-9">
              {{ data.job_title }}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-bold">
              Posisi
            </div>
            <div class="col-9">
              {{ data.mstposition?.name }}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-bold">
              Lokasi
            </div>
            <div class="col-9">
              {{ data.location }}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-bold">
              Jenis Tempat Kerja
            </div>
            <div class="col-9">
              {{ data.workplace_type }}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-bold">
              Jenis Pekerjaan
            </div>
            <div class="col-9">
              {{ data.employment_type }}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-bold">
              Kode Referensi Lowongan
            </div>
            <div class="col-9">
              {{ data.job_code }} <span v-if="useCopyClipboard"><q-btn :icon="!isCopied? 'content_copy' : 'done'" dense
                                                                       flat size="sm"
                                                                       @click="copyJobCode"></q-btn></span>
            </div>
          </div>
          <div class="row" v-if="isPelamar">
            <div class="col-2 text-bold">
              Ekspektasi gaji Anda
            </div>
            <div class="col-9">
              {{ expected_salary ? expected_salary.toLocaleString('id') : 'anda belum melamar' }}
            </div>
          </div>
        </div>
    </q-card-section>
  </q-card>
  <q-card v-if="authState.isPelamar">
    <q-card-section>
      <div class="row">
        <div class="col-2 text-bold">
          Persyaratan
        </div>
        <div class="col-9">
          <p v-html="data.requirements"></p>
        </div>
      </div>
    </q-card-section>
    <q-card-section>
      <div class="row">
        <div class="col-2 text-bold">
          Tanggung Jawab
        </div>
        <div class="col-9">
          <p v-html="data.responsibilities"></p>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>
<style scoped></style>
