export const adminMenu = [
    {
        name: 'Beranda',
        to: 'HomePage',
        icon: 'home'
    },
    {
        name: 'Manajemen Lowongan',
        icon: 'work',
        children: [
            {
                name: 'Pelamar',
                to: 'Applicants',
                icon: 'assignment_ind'
            },
            {
                name: 'Lowongan Pekerjaan',
                to: 'JobVacancy',
                icon: 'business_center'
            },
            {
                name: 'Master Posisi',
                to: 'JobPosition',
                icon: 'account_box'
            },
            {
                name: 'Master Keahlian',
                to: 'SkillSet',
                icon: 'build'
            },
            {
                name: 'Master Tahapan',
                to: 'state',
                icon: 'article'
            },
            {
                name: 'Kumpulan Pertanyaan',
                icon: 'auto_awesome_motion',
                to: 'questionMasterList'
            },
            {
                name: 'Bank Soal',
                icon: 'assignment',
                to: 'questionBankList'
            },
        ]
    },
    {
        name: 'Manajemen Akun',
        icon: 'account_circle',
        children: [
            {
                name: 'Pengguna',
                icon: 'person_outline',
                to: 'User'
            },
            {
                name: 'Peran',
                icon: 'assignment_ind',
                to: 'peran'
            },
            {
                name: 'Hak Akses',
                icon: 'verified_user',
                to: 'Permission'
            }
        ]
    },
    {
        name: 'Reference',
        icon: 'article',
        to: 'referenceMaster'
    },
    {
        name: 'Email Logger',
        icon: 'email',
        to: 'EmailLogger'
    },

];

export const pelamarMenu = [
    {
        name: 'Beranda',
        to: 'HomePage',
        icon: 'home'
    },
    {
        name: 'Lamar Pekerjaan',
        to: 'JobCode',
        icon: 'assignment_ind'
    }
]
