<script setup>
import {EXPORT_ACTION_LABEL} from "@/constants/label.js";
import {
  columnsApplicantsJob,
  defaultComment,
  defaultCommentPassed
} from "@/pages/jobMgmt/jobVacancy/job-vacancy.js";
import QViewButtonGroup from "@/components/button/QViewButtonGroup.vue";
import AppModal from "@/components/AppModal.vue";
import {computed, onMounted, ref, watch} from "vue";
import useNotify from "@/plugins/notify/index.js";
import useLoader from "@/plugins/loader/index.js";
import {currencyFormat} from "@/lib/Helper.js";
import QActionButton from "@/components/button/QActionButton.vue";
import useConfirmation from "@/plugins/confirmation/index.js";
import {postAuditApplicant, postAuditApplicantBulk} from "@/lib/api.js";
import DetailPelamar from "./DetailPelamar.vue";
import {MESSAGE_FAILED} from "@/constants/default-messages.js";
import FormPretest from "@/components/FormPretest.vue";

const props = defineProps({
  job: Object,
  rows: Array,
  selected: Array,
  id: String,
  currentState: Number,
  isFilterActive: Boolean,
  isDuplicateActive: {
    type: Boolean,
    default: false
  },
  hasForm: {
    type: Boolean,
    default: false
  },
  jobStateId: String
});

const latestState = computed(() => {
  return props.job.latest_state;
});

const getApplicantHasntAudited = () => {
  let applicants = []
  const rows = JSON.parse(JSON.stringify(props.rows));
  rows.forEach(row => {
    let filteredLog = row.step_log.filter(e => !e.is_audited && (latestState.value.state_id === e.state_id));
    if (filteredLog.length > 0) {
      const obj = mappingApplicantsHasntAudited(row);
      applicants.push(obj);
    }
  });
  return applicants;
}

const mappingApplicantsHasntAudited = (row) => {
  row.step_log.forEach(log => {
    if (log.state_id === latestState.value.state_id) {
      log.is_audited = true;
      log.is_pass = true
      log.comment = null
    }
  })
  return {
    applicant_id: row.applicant_id,
    state_id: latestState.value.state_id,
    job_id: props.job.id,
    step_log: row.step_log
  };
}

const isAudited = (row) => {
  return row.step_log.find(item => {
    return item.is_audited && props.currentState === Number(item.state_id)
  })
};

const isLatestState = () => {
  return props.currentState === Number(latestState.value.state_id);
}
const isPassed = (row) => {
  return row.step_log.find(item => {
    return item.is_pass && props.currentState === Number(item.state_id)
  })
};

const notify = useNotify();
const detailApplicants = ref({name: '', phone_no: ''});
const isPass = ref(1);
const comment = ref('');
const getComment = computed(() => {
  return isPass.value ? defaultCommentPassed(detailApplicants.value, props.job) : defaultComment(detailApplicants.value);
})
const emits = defineEmits(['update:selected', 'audited'])
const loader = useLoader();
const confirm = useConfirmation();

const selected = computed({
  get() {
    return props.selected
  },
  set(value) {
    emits('update:selected', value);
  }
})

const handleShowDetail = (row) => {
  detailApplicants.value = row
  modal.value = true;
}

const handleShowAssessment = (row) => {
  detailApplicants.value = row
  detailApplicants.value.job_title = props.job.job_title;
  comment.value = getComment.value;
  modalAssessment.value = true;
}

const handleShowHistories = (row) => {
  detailApplicants.value = row;
  detailApplicants.value.job_title = props.job.job_title;
  modalHistory.value = true;
}

const formQuestionDetail = ref({
  applicant_id: null
});

const modalQuestionPreview = ref(false);
const keyCounter = ref(1);
const handleShowQuestions = (row) => {
  keyCounter.value++;
  formQuestionDetail.value.applicant_id = row.applicant_id;
  modalQuestionPreview.value = true;
}

const doAssesment = () => {
  confirm
      .setTitle('Konfirmasi')
      .setMessage('Apakah anda yakin?')
      .setOnConfirm(() => {
        loader.show();
        const params = setAssesmentParams();
        postAuditApplicant(params).then(() => {
          emits('audited');
          notify.show({message: 'Data kandidat berhasil disimpan', type: 'success'})
        }).catch(err => {
          notify.show({message: err?.message || MESSAGE_FAILED, type: 'error'})
        }).finally(() => {
          loader.hide();
          modalAssessment.value = false;
        })
      }).show();
}

const setAssesmentParams = () => {
  return {
    job_id: props.job.id,
    applicant_id: detailApplicants.value.applicant_id,
    job_state_id: latestState.value.id,
    isPass: isPass.value === 1,
    comment: getComment.value,
    step_log: updateStepLogApplicant()
  }
}
const doPassAllApplicants = () => {
  const params = getApplicantHasntAudited();
  if (params.length < 1) {
    notify.show({message: 'Data kandidat sudah ter-audit semua. Anda bisa melanjutkan tahapan', type: 'warning'});
    return false;
  }
  confirmPassAllApplicants(params);
}

const countHasNotAuditedApplicant = computed(() => {
  let auditedApplicant = 0;
  props.rows.forEach((item) => {
    item.step_log.forEach((step_log) => {
      if (Number(step_log.state_id) === Number(props.currentState) && !step_log.is_audited) {
        auditedApplicant += 1;
      }
    })
  })

  return auditedApplicant;
})
const confirmPassAllApplicants = (params) => {
  confirm
      .setTitle('Konfirmasi')
      .setMessage(`Apakah anda yakin akan meloloskan ${countHasNotAuditedApplicant.value} dari ${props.rows.length} kandidat yang belum di proses pada tahapan ini?`)
      .setOnConfirm(() => {
        loader.show();
        postAuditApplicantBulk(params).then(() => {
          // @todo
          emits('audited');
          notify.show({message: 'Data kandidat berhasil disimpan', type: 'success'})
        }).catch(err => {
          notify.show({message: err.message, type: 'error'});
        }).finally(() => {
          loader.hide();
        });
      })
      .setOnCancel(() => {
        return false;
      }).show();
}



const updateStepLogApplicant = () => {
  const step_logs = detailApplicants.value.step_log;
  step_logs.forEach((row) => {
    if (row.state_id === latestState.value.state_id) {
      row.is_audited = true;
      row.is_pass = isPass.value === 1;
      row.comment = getComment.value
    }
  })
  return step_logs;
}
const handleCloseDetail = () => {
  modal.value = false;
  detailApplicants.value = null;
}

const modal = ref(false);
const modalAssessment = ref(false);
const modalHistory = ref(false);

const columnHistory = [
  { name: 'job_title', align: 'left', label: 'Nama Lowongan', headerStyle: 'font-weight: bolder',
    field: row => { return row.job.job_title },
    sortable: true
  },
  { name: 'location', align: 'center', label: 'Lokasi', headerStyle: 'font-weight: bolder',
    field: row => { return row.job.location },
    sortable: true
  },
  { name: 'position', align: 'center', label: 'Posisi', headerStyle: 'font-weight: bolder',
    field: row => { return row.job.mstposition.name ?? '' },
    sortable: true
  },
  { name: 'employment_type', align: 'center', label: 'Jenis Pekerjaan', headerStyle: 'font-weight: bolder',
    field: row => { return row.job.mstemployment_type.name ?? '' },
    sortable: true
  },
  { name: 'workplace_type', align: 'center', label: 'Jenis Tempat Kerja', headerStyle: 'font-weight: bolder',
    field: row => { return row.job.mstworkplace_type.name ?? ''},
    sortable: true
  },
]

</script>

<template>
  <div class="flex q-gutter-x-sm justify-end q-mb-md" v-show="!isDuplicateActive">
    <q-btn :label="'Loloskan Sisa Kandidat'"
           id="btn-bulk-audit"
           :title="'Loloskan Sisa Kandidat'"
           v-show="currentState === Number(latestState.state_id)"
           unelevated
           color="blue"
           @click="doPassAllApplicants"
    ></q-btn>
    <q-btn :label="EXPORT_ACTION_LABEL"
           :title="EXPORT_ACTION_LABEL"
           unelevated
           color="orange"
    ></q-btn>
  </div>
  <q-table
      flat
      :columns="columnsApplicantsJob"
      :rows="rows"
      v-model:selected="selected"
      table-header-class="bg-table-header"
      key="id"
      :selection="isDuplicateActive ? 'multiple' : 'none'"
  >
    <template v-slot:body-cell-status_recruitment="props">
      <q-td :props="props">
        <div v-if="isPassed(props.row)">
          <q-badge label="Lolos Tahapan" color="green" size="md"></q-badge>
        </div>
        <div v-else>
          <q-badge v-if="!isAudited(props.row)" label="Baru" color="blue" size="md"></q-badge>
          <q-badge v-else label="Tidak Lolos" color="red" size="md"></q-badge>
        </div>
      </q-td>
    </template>
    <template v-slot:body-cell-action="props">
      <q-td :props="props" class="q-gutter-x-xs">
        <QViewButtonGroup @click="handleShowDetail(props.row)" id="btn-view-detail-applicants"
                          :disabled="isDuplicateActive"/>
        <QActionButton id="btn-assessment"
                       @click="handleShowAssessment(props.row)" :title="'Asesmen'" :disabled="isDuplicateActive"/>
        <QActionButton color="purple" id="btn-history" @click="handleShowHistories(props.row)" :title="'Riwayat'" icon="search"/>
        <QActionButton v-if="hasForm" color="purple" id="btn-history" @click="handleShowQuestions(props.row)" :title="'Form isian kandidat'" icon="book"/>
      </q-td>
    </template>
    <template v-slot:body-cell-expected_salary="props">
      <q-td :props="props">
        {{ currencyFormat(props.row.expected_salary) }}
      </q-td>
    </template>
    <template v-slot:body-cell-cv_url="props">
      <q-td :props="props">
        <a :href="props.row.cv_url" target="_blank">Link</a>
      </q-td>
    </template>
  </q-table>
  <DetailPelamar
      v-model:modal="modal"
      @close-modal="handleCloseDetail"
      :detail-applicants="detailApplicants"
  />
  <AppModal
      v-model:modal="modalAssessment"
      title="Asesmen"
      persistent
      :width="'80vw'"
      :max-width="'80vw'"
  >
    <template v-slot:content>
      <q-card>
        <q-card-section class="q-gutter-y-xs">
          <label class="label">Apakah memenuhi persyaratan?</label>
          <div class="q-gutter-sm">
            <q-radio v-model="isPass" :val="1" label="Ya"></q-radio>
            <q-radio v-model="isPass" :val="0" label="Tidak"></q-radio>
          </div>
          <q-editor id="comment" :type="'textarea'" v-model="getComment" :label="'Komentar'"
                    ></q-editor>
        </q-card-section>
      </q-card>
    </template>
    <template v-slot:action>
      <QActionButton :label="'Simpan'" :size="'md'" :title="'Simpan'" :color="'green'" :icon="'save'"
                     @click="doAssesment"></QActionButton>
    </template>
  </AppModal>

  <AppModal
      :width="'80vw'"
      :max-width="'80vw'"
      v-model:modal="modalHistory"
      :title="'Riwayat Pelamar'"
      persistent
  >
    <template v-slot:content>
      <q-card-section v-if="detailApplicants">
        <div class="row text-weight-bold">
          <div class="col-1">
            Nama
          </div>
          <div class="col">
            {{ detailApplicants.name }}
          </div>
        </div>
        <div class="row q-pb-sm text-weight-bold">
          <div class="col-1">
            No HP
          </div>
          <div class="col">
            {{ detailApplicants.phone_no }}
          </div>
        </div>
        <q-table
            bordered
            class="w-100"
            :columns="columnHistory"
            :rows="detailApplicants.histories"
        >
        </q-table>
      </q-card-section>
    </template>
  </AppModal>

  <AppModal
      :width="'80vw'"
      :max-width="'80vw'"
      v-model:modal="modalQuestionPreview"
      :title="'Form isian kandidat'"
      persistent
  >
    <template v-slot:content>
     <FormPretest
         :ref="'previewformquestion' + formQuestionDetail.applicant_id"
         :key="'preview' + formQuestionDetail.applicant_id + keyCounter"
         :job_state_id="Number(jobStateId)"
         :has-form="hasForm"
         :is-answered="true"
         :applicant_id="Number(formQuestionDetail.applicant_id)"
     >
     </FormPretest>
   </template>
  </AppModal>
</template>

<style scoped>
#comment {
  min-height: 30em;
}
</style>
