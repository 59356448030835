<script setup>
import AppTable from "@/components/AppTable.vue";
import {ref} from "vue";
import QViewButtonGroup from "@/components/button/QViewButtonGroup.vue";
import AppPage from "@/components/AppPage.vue";
import {columns} from "@/pages/applicantsMgmt/applicants.js";
import {getApplicants, getReferences, getSkills} from "@/lib/api.js";
import {currencyFormat} from "@/lib/Helper.js";
import useLoader from "@/plugins/loader/index.js";
import useNotify from "@/plugins/notify/index.js";
import DetailPelamar from "@/pages/jobMgmt/jobVacancy/JobVacancyFlow/DetailPelamar.vue";
import FilterCard from "@/components/FilterCard.vue";
import VInput from "@/components/input/VInput.vue";
import VPreloadedSelect from "@/components/input/VPreloadedSelect.vue";
import {EDUCATION_TYPE} from "@/constants/reference.js";
const skillOptions = ref([]);
const notify = useNotify();
const loader = useLoader();
const educationOptions = ref([]);
const filter = ref({
  name: null,
  phone_no: null,
  email: null,
  education: null,
  skills: null
});
const table = ref(null);
const keyword = ref('');
const modal = ref(false);
const loading = ref(false);
const detailApplicants = ref(null);


const dialog = ref(false)

const handleShowDetail = (row) => {
  detailApplicants.value = row[0]
  modal.value = true;
}

const handleCloseDetail = () => {
  modal.value = false;
  detailApplicants.value = null;
}

const rows = ref([])

const fetchApplicants = (offset, limit) => {
  const params = {
    offset, limit, keyword: keyword.value, order: 'desc', sort: 'job_title'
  }
  loader.show()
  return getApplicants(params)
      .then((response) => {
        return {
          rows: response.data,
          total: response.total,
        }
      }).catch((error) => {
        notify.show({message: error.message, type: 'error'})
      }).finally(() => {
        loader.hide()
      })
}

const getFilterApplicants = () => {
  const filterData = {};
  if (filter.value.name) filterData.name = filter.value.name;
  if (filter.value.phone_no) filterData.phone_no = filter.value.phone_no;
  if (filter.value.email) filterData.email = filter.value.email;
  if (filter.value.education) filterData.education = filter.value.education;
  if (filter.value.skills) filterData.skills = filter.value.skills.join(",");
  return filterData;
}

const mappingRowsValue = (applicants) => {
  return applicants.reduce((acc, item) => {
      acc.push({
        ...item,
        name: item.name,
        phone_no: item.phone_no,
        email: item.email,
        education: item.education,
        skills: item.skills
      });
    return acc;
  }, []);
}

const fetchfilterApplicants = () => {
  const filterData = getFilterApplicants();
  return getApplicants(filterData).then((res) => {
    if (res.data) {
      rows.value = mappingRowsValue(res.data);
    }
  })
}

const resetFilter = () => {
  for (let item in filter.value) {
    filter.value[item] = null
  }
  fetchfilterApplicants();
}

const fetchEducation = () => {
  return getReferences(EDUCATION_TYPE).then((res) => {
    return res
  }).catch(() => {
  })
}

const filterSkills = (val, update, abort) => {
  if (val < 3) {
    abort();
    return;
  }
  update( async () => {
    const keyword = val.toLowerCase();
    await getSkills({ name: keyword }).then(res => {
      skillOptions.value = res.map(c => {
        return {
          label: c.name,
          value: c.code,
        }
      });
    }).catch(() => { skillOptions.value = null })
  })
}


</script>
<template>
    <AppPage title="Semua Pelamar">
      <template v-slot:default>
        <FilterCard @filter="fetchfilterApplicants" @reset="resetFilter">
          <div class="row q-gutter-xl q-mb-md">
            <div class="col">
              <div class="q-mb-sm">Nama</div>
              <VInput v-model="filter.name"/>
            </div>
            <div class="col">
              <div class="q-mb-sm">No. Telp</div>
              <VInput v-model="filter.phone_no"/>
            </div>
          </div>
          <div class="row q-gutter-xl q-mb-md">
            <div class="col">
              <div class="q-mb-sm">Pendidikan</div>
              <VPreloadedSelect
                  for="input-filter-workplace-type"
                  v-model="filter.education"
                  emit-value
                  :fetch="fetchEducation"
                  transformValueKey="code"
                  :options="educationOptions"
              />
            </div>
            <div class="col">
              <div class="q-mb-sm">Email</div>
              <VInput v-model="filter.email"/>
            </div>
          </div>
          <div class="row q-gutter-xl q-mb-md">
            <div class="col">
              <div class="q-mb-sm">Kemampuan Teknis</div>
              <q-select
                  multiple
                  input-debounce="300"
                  outlined
                  dense
                  use-input
                  v-model="filter.skills"
                  @filter="filterSkills"
                  :options="skillOptions"
                  clearable
                  option-value="value"
                  option-label="label"
                  emit-value
                  map-options
                  hint="Masukan kata kunci huruf untuk menampilkan hasil filter"
              >
              </q-select>
            </div>
          </div>
        </FilterCard>

        <AppTable ref= "table" :fetch="fetchApplicants" :columns="columns" v-model:rows="rows" v-model:keyword="keyword" enable-filter :loading="loading">
          <template v-slot:body-cell-action="props">
            <q-td :props="props" class="q-gutter-x-xs">
              <QViewButtonGroup @click="handleShowDetail(rows)"/>
<!--              <QDeleteButtonGroup :id="`btn-action-delete-${props.row.id}`" @click="handleDelete(props.row.id)"/>-->
            </q-td>
          </template>
          <template v-slot:body-cell-university_name="props">
            <q-td :props="props">
              <div>{{props.row.university_name ? props.row.university_name : props.row.university_other}}</div>
            </q-td>
          </template>

          <template v-slot:body-cell-jurusan="props">
            <q-td :props="props">
              <div>{{props.row.major_name ? props.row.major_name :  props.row.major_other}}</div>
            </q-td>
          </template>

          <template v-slot:body-cell-min_salary="props">
            <q-td :props="props">
              <div>{{ currencyFormat(props.row.min_salary) }}</div>
            </q-td>
          </template>
          <template v-slot:body-cell-max_salary="props">
            <q-td :props="props">
              <div>{{ currencyFormat(props.row.max_salary) }}</div>
            </q-td>
          </template>
          <template v-slot:body-cell-skills="props">
            <q-td :props="props">
              <div>{{props.row.skills.replaceAll(',',', ')}}</div>
            </q-td>
          </template>
          <template v-slot:body-cell-cv_url="props">
            <q-td :props="props">
              <a :href="props.row.cv_url" target="_blank">Link</a>
            </q-td>
          </template>
        </AppTable>
      </template>
    </AppPage>

  <DetailPelamar
      v-model:modal="modal"
      @close-modal="handleCloseDetail"
      :detail-applicants="detailApplicants"
  />

  <q-dialog v-model="dialog" class="items-center"
            seamless transition-show="fade"
            transition-hide="fade"
            transition-duration="500"
            position="top"
  >
    <q-card class="bg-green">
      <q-card-section class="row items-center">
        <div><q-icon name="check_circle" size="lg" color="white"/></div>
        <q-space/>
        <div class="text-weight-bold text-white text-center">Success generating and copying URL</div>
      </q-card-section>
    </q-card>
  </q-dialog>

</template>


<style lang="sass" scoped>
</style>
