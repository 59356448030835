<template>
  <q-dialog persistent>
    <q-card class="flex column items-center">
      <q-card-section>
        <q-spinner-bars color="orange" size="3em"/>
      </q-card-section>
      <div class="q-ma-md">
        <p class="no-margin">Tunggu sebentar ...</p>
      </div>
    </q-card>
  </q-dialog>
</template>
