<script setup>
import AppModal from "@/components/AppModal.vue";
import {ref} from "vue";
import AppTable from "@/components/AppTable.vue";
import {columnsState} from "@/pages/jobMgmt/state/state-master.js";
import QViewButtonGroup from "@/components/button/QViewButtonGroup.vue";
import QEditButtonGroup from "@/components/button/QEditButtonGroup.vue";
import QDeleteButtonGroup from "@/components/button/QDeleteButtonGroup.vue";
import AppPage from "@/components/AppPage.vue";
import router from "@/router/index.js";
import {deleteStateMaster, getStateMaster} from "@/lib/api.js";
import useNotify from "@/plugins/notify/index.js";
import {MESSAGE_FAILED} from "@/constants/default-messages.js";
import useConfirmation from "@/plugins/confirmation/index.js";
import useLoader from "@/plugins/loader/index.js";

const table = ref(null);
const confirm = useConfirmation();
const loader = useLoader();
const modal = ref(false);
const keyword = ref('');
const notify = useNotify();

const handleTambah = () => {
  router.push({
    name: 'StateMasterForm',
    params: {
      action: 'create'
    }
  })
}

const handleView = (id) => {
  router.push({
    name: 'StateMasterForm',
    params: {
      action: 'view',
      id
    }
  })
}

const handleEdit = (id) => {
  router.push({
    name: 'StateMasterForm',
    params: {
      action: 'edit',
      id
    }
  })
}

const handleDelete = (id) => {
  confirm
      .setTitle('Konfirmasi')
      .setMessage('Apakah anda yakin akan menghapus data ini?')
      .setOnConfirm(() => {
        loader.show();
        deleteStateMaster(id).then(() => {
          notify.show({message: 'Data ini berhasil dihapus', type: 'success'})
        }).catch(err => {
          notify.show({message: err?.message || MESSAGE_FAILED, type: 'error'})
        }).finally(() => {
          table.value.reset()
          loader.hide();
        })
      }).show()
}

const rows = ref([]);

const fetchState = (offset, limit) => {
  const params = {
    offset,
    limit,
    keyword: keyword.value,
    order: 'desc',
    sort: 'id'
  }
  return getStateMaster(params).then((res) => {
    return {
      rows: res.data,
      total: res.total
    }
  }).catch((err) => {
    notify.show({
      message: err.message || err.messages || err.error || MESSAGE_FAILED,
      type: 'error'
    })
  })
}
</script>

<template>
  <AppPage title="Tahapan">
    <template v-slot:default>
      <div class="flex justify-end q-mb-md">
        <q-btn id="btn-add-job-vacancy" icon="add" unelevated label="Tambah" color="primary" @click="handleTambah"/>
      </div>
      <AppTable ref="table" id="table-list-job-vacancy" :columns="columnsState" enable-filter v-model:rows="rows"
                v-model:keyword="keyword" :fetch="fetchState">
        <template v-slot:body-cell-action="props">
          <q-td :props="props" class="q-gutter-x-xs">
            <QViewButtonGroup :id="`btn-action-view-${props.row.id}`" @click="handleView(props.row.id)"/>
            <QEditButtonGroup :id="`btn-action-edit-${props.row.id}`" @click="handleEdit(props.row.id)"/>
            <QDeleteButtonGroup :id="`btn-action-delete-${props.row.id}`" @click="handleDelete(props.row.id)"/>
          </q-td>
        </template>
      </AppTable>
    </template>
  </AppPage>
  <AppModal title="Job Vacancy Detail" v-model:modal="modal"></AppModal>
</template>

<style scoped></style>
