<script setup>
import {CREATE_TITLE_MODAL_LABEL, SAVE_CONFIRMATION_LABELS, UPDATE_TITLE_MODAL_LABEL} from "@/constants/label.js";
import AppModal from "@/components/AppModal.vue";
import VInput from "@/components/input/VInput.vue";
import {computed} from "vue";
import {required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  mode: {
    type: String,
    default: 'CREATE'
  },
  form: Object
});

const modal = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emits('update:modelValue', val);
  }
});

const emits = defineEmits(['update:form', 'update:modelValue', 'save']);

const rules = {
  id: {required},
  name: {required},
};

const form = computed({
  get() {
    return props.form;
  },
  set(val) {
    emits('update:form', val);
  }
});



const $vuelidate = useVuelidate(rules, form.value);

const onResetForm = () => {
  $vuelidate.value.$reset();
  form.value.id = '';
  form.value.name = '';
};

const handleSubmit = () => {
  const valid = $vuelidate.value.$validate();
  if (!valid) return;
  if (props.mode === 'CREATE') {
    emits('save');
  } else {
    emits('update');
  }
};
</script>

<template>
  <AppModal :title="mode === 'CREATE' ? CREATE_TITLE_MODAL_LABEL : UPDATE_TITLE_MODAL_LABEL" v-model:modal="modal"
            @hide="onResetForm" persistent>
    <template v-slot:content>
      <div class="row column">
        <div class="text-weight-bold">ID Role</div>
        <VInput
            v-model="form.id"
            @blur="$vuelidate.id.$touch"
            :errors="$vuelidate.id.$errors"
        />
      </div>
      <div class="row column">
        <div class="text-weight-bold">Nama Role</div>
        <VInput
            v-model="form.name"
            @blur="$vuelidate.name.$touch"
            :errors="$vuelidate.name.$errors"
        />
      </div>
    </template>
    <template v-slot:action>
      <q-btn unelevated color="primary" class="q-mr-sm" id="btn-save-role" :label="SAVE_CONFIRMATION_LABELS"
             @click="handleSubmit"></q-btn>
    </template>
  </AppModal>
</template>
