import {escapeRegExp} from './escapeRegExp.js';
import {removeSeparators} from './removeSeparators';
import {removeInvalidChars} from './removeInvalidChars';

export const cleanValue = ({
                               value,
                               groupSeparator = ',',
                               decimalSeparator = '.',
                               allowDecimals = true,
                               decimalsLimit = 3,
                               allowNegativeValue = true,
                               prefix = ''
                           }) => {
    value = typeof value === 'string' ? value : String(value);

    if (value === '-') {
        return allowNegativeValue ? value : '';
    }

    const regNegative = new RegExp(`((^|\\D)-\\d)|(-${escapeRegExp(prefix)})`);
    const isNegative = regNegative.test(value);

    const matchPrefixWithValue = new RegExp(`(\\d+)-?${escapeRegExp(prefix)}`).exec(value);
    const prefixWithValue = matchPrefixWithValue ? matchPrefixWithValue[0] : '';
    const preValue = matchPrefixWithValue ? matchPrefixWithValue[1] : '';

    const withoutPrefix = prefix
        ? prefixWithValue
            ? value.replace(prefixWithValue, '').concat(preValue)
            : value.replace(prefix, '')
        : value;

    const withoutSeparators = removeSeparators(withoutPrefix, groupSeparator);
    const valueOnly = removeInvalidChars(withoutSeparators, [
        groupSeparator,
        decimalSeparator,
    ]);

    const includeNegative = isNegative && allowNegativeValue ? '-' : '';

    if (decimalSeparator && valueOnly.includes(decimalSeparator)) {
        const parts = valueOnly.split(decimalSeparator);
        const intPart = parts[0];
        const decimalPart = parts[1] || '';

        const trimmedDecimals = decimalsLimit >= 0 && decimalPart
            ? decimalPart.slice(0, decimalsLimit)
            : decimalPart;

        const includeDecimals = allowDecimals && trimmedDecimals
            ? `${decimalSeparator}${trimmedDecimals}`
            : '';

        return `${includeNegative}${intPart}${includeDecimals}`;
    }

    return `${includeNegative}${valueOnly}`;
};
