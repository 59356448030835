<script setup>
import {useRoute} from "vue-router";
import {computed, onMounted} from "vue";

const route = useRoute();
const props = defineProps({
  title: String,
  disableBreadCrumb: {
    type: Boolean,
    default: false
  },
  disableSlotMargin: {
    type: Boolean,
    default: false
  }
})

const breadCrumbs = computed(() => {
  let pathArray = route.path.split('/');
  pathArray.shift();
  return pathArray.reduce((breadcrumbsArray, path, index) => {
    breadcrumbsArray.push({
      path: path,
      to: breadcrumbsArray[index - 1]
          ? '/' + breadcrumbsArray[index - 1].path + '/' + path
          : '/' + path,
      name: route.matched[index]?.meta?.alias || path.charAt(0).toUpperCase() + path.slice(1)
    })
    return breadcrumbsArray;
  }, []);
})

</script>

<template>
  <div class="section">
    <div class="section-header">
      <h1>{{ title }}</h1>
      <div class="section-header-breadcrumb">
        <q-breadcrumbs v-if="!disableBreadCrumb">
          <q-breadcrumbs-el v-for="(item, index) in breadCrumbs" :key="index" :label="item.name" />
        </q-breadcrumbs>
      </div>
    </div>
  </div>
  <div :class="disableSlotMargin ? '' : 'q-mt-lg q-mx-lg'">
    <slot></slot>
  </div>
</template>

<style scoped></style>
