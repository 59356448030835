<script setup>
import AppPage from "@/components/AppPage.vue";
import AppTable from "@/components/AppTable.vue";
import {columnsRole} from "@/pages/akunMgmt/role/role.js";
import {getRole, putRole, deleteRole} from "@/lib/api.js";
import {ref} from "vue";
import {
  MESSAGE_FAILED,
  // MESSAGE_SUCCESS_CREATED,
  MESSAGE_SUCCESS_DELETED,
  MESSAGE_SUCCESS_UPDATED
} from "@/constants/default-messages.js";
import useNotify from "@/plugins/notify/index.js";
import QEditButtonGroup from "@/components/button/QEditButtonGroup.vue";
import QDeleteButtonGroup from "@/components/button/QDeleteButtonGroup.vue";
import {CONFIRMATION_TITLE_LABEL, DELETE_CONFIRMATION_LABEL} from "@/constants/label.js";
import useLoader from "@/plugins/loader/index.js";
import useConfirmation from "@/plugins/confirmation/index.js";
import FormRole from "@/pages/akunMgmt/role/FormRole.vue";

const title = 'Manajemen Peran';
const keyword = ref(null);
const rows = ref([]);
const notify = useNotify();
const modal = ref(false);
const form = ref({
  id: '',
  name: '',
});
const tableRole = ref(null);
const loader = useLoader();

const confirmation = useConfirmation();

const fetchRole = (offset, limit) => {
  const params = {
    offset,
    limit,
    keyword: keyword.value,
    order: 'desc',
    sort: 'id'
  };
  return getRole(params).then((res) => {
    return {
      rows: res.data,
      total: res.total
    };
  }).catch((err) => {
    notify.show({
      message: err.message || err.messages || err.error || MESSAGE_FAILED,
      type: 'error'
    });
  });
};

// const handleSave = () => {
//   loader.show();
//   return postRole(form.value).then(() => {
//     notify.show({
//       message: MESSAGE_SUCCESS_CREATED,
//       type: 'success'
//     });
//     modal.value = false;
//     tableRole.value.reset();
//     loader.hide();
//   }).catch((err) => {
//     notify.show({
//       message: err.message || err.messages || err.error || MESSAGE_FAILED,
//       type: 'error'
//     });
//   });
// };

const handleUpdate = () => {
  loader.show();
  return putRole(form.value.id, form.value).then(() => {
    notify.show({
      message: MESSAGE_SUCCESS_UPDATED,
      type: 'success'
    });
    modal.value = false;
    tableRole.value.reset();
    loader.hide();
  }).catch((err) => {
    notify.show({
      message: err.message || err.messages || err.error || MESSAGE_FAILED,
      type: 'error'
    });
  });
};

// const handleTambah = () => {
//   mode.value = 'CREATE';
//   modal.value = true;
// };

const mode = ref('CREATE');

const onDeleteItem = (id) => {
  confirmation
      .setTitle(CONFIRMATION_TITLE_LABEL)
      .setMessage(DELETE_CONFIRMATION_LABEL)
      .setOnConfirm(() => {
        loader.show();
        return deleteRole(id)
            .then(() => {
              notify.show({
                message: MESSAGE_SUCCESS_DELETED,
                type: 'success'
              });
              tableRole.value.reset();
            })
            .catch((err) => {
              notify.show({
                message: err.message || err.messages || err.error || MESSAGE_FAILED,
                type: 'error'
              });
            })
            .finally(() => {
              loader.hide();
            });
      }).show();
};

const handleEdit = (row) => {
  mode.value = 'EDIT';
  form.value.id = row.id;
  form.value.name = row.name;
  modal.value = true;
};

</script>

<template>
  <AppPage :title="title">
    <template v-slot:default>
<!--      <div class="flex justify-end q-mb-md">-->
<!--        <q-btn id="btn-add-role" icon="add" unelevated label="Tambah" color="primary" @click="handleTambah"/>-->
<!--      </div>-->
      <AppTable
          ref="tableRole"
          enable-filter
          :fetch="fetchRole"
          id="table-list-role"
          :columns="columnsRole"
          v-model:keyword="keyword"
          v-model:rows="rows"
      >
        <template v-slot:body-cell-no="props">
          <q-td :props="props">
            {{ props.rowIndex + 1 }}
          </q-td>
        </template>
        <template v-slot:body-cell-action="props">
          <q-td :props="props" class="q-gutter-x-xs">
            <QEditButtonGroup :id="`btn-action-edit-${props.row.id}`" @click="handleEdit(props.row.id)"/>
            <QDeleteButtonGroup :id="`btn-action-delete-${props.row.id}`" @click="onDeleteItem(props.row.id)"/>
          </q-td>
        </template>
      </AppTable>
    </template>
  </AppPage>
  <FormRole
      v-model="modal"
      :mode="mode"
      v-model:form="form"
      @save="handleSave"
      @update="handleUpdate"
  />
</template>
