<script setup>
import {ref} from "vue";

const emits = defineEmits(['reset', 'filter']);

const handleReset = () => {
  emits('reset');
}

const handleFilter = () => {
  emits('filter');
}

const expanded = ref(false);
</script>

<template>
  <q-expansion-item
      v-model="expanded"
      icon="filter_alt"
      label="Filter Pelamar"
      class="filter-card q-mb-lg"
      header-class="bg-grey-6 text-white text-weight-bold"
  >
    <q-card>
      <q-card-section class="q-px-lg q-py-md">
        <slot></slot>
      </q-card-section>
      <q-card-actions
          align="right"
          class="filter-card-actions q-px-lg q-pb-md"
      >
        <q-btn
          outline
          icon="refresh"
          label="Reset"
          @click="handleReset"
          color="primary"
        ></q-btn>
        <q-btn
          unelevated
          icon="filter_alt"
          label="Filter"
          @click="handleFilter"
          color="primary"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-expansion-item>
</template>
