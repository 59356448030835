<script setup>
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import AppPage from '@/components/AppPage.vue';
import VInput from '@/components/input/VInput.vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import {
  getDetailStateMaster,
  postStateMaster,
  updateStateMaster
} from '@/lib/api.js';
import useNotify from '@/plugins/notify/index.js';
import useLoader from '@/plugins/loader/index.js';
import { MESSAGE_SUCCESS_CREATED, MESSAGE_SUCCESS_UPDATED } from '@/constants/default-messages.js';

const notify = useNotify();
const route = useRoute();
const router = useRouter();
const action = ref(route?.params?.action || 'create');

const pageTitle = computed(() => {
  switch (action.value) {
    case 'create':
      return 'Buat State Baru';
    case 'update':
      return 'Ubah State';
    case 'view':
      return 'Detil State';
    case 'edit':
      return 'Edit State';
  }
  return '';
});

const isReadOnly = computed(() => action.value === 'view');

const loader = useLoader();
const stateId = ref(route?.params?.id || null);

const form = ref({
  id: null,
  name: null,
  minimum_grade: null,
  actor: null,
  description: null,
});

const rules = computed(() => ({
  name: { required },
  minimum_grade: { required },
  actor: { required },
  description: { required },
}));

const v$ = useVuelidate(rules, form);

const redirectBack = () => {
  router.push({ name: 'state' });
};

const handleSubmit = async () => {
  const valid = await v$.value.$validate();
  if (!valid) return;

  loader.show();
  const data = {
    id: form.value.id,
    name: form.value.name,
    minimum_grade: form.value.minimum_grade,
    actor: form.value.actor,
    description: form.value.description,
  };

  if (action.value === 'create') {
    handlePostState(data);
  } else {
    handleUpdateState(data);
  }
};

const handlePostState = (data) => {
  postStateMaster(data)
      .then(() => {
        notify.show({ message: MESSAGE_SUCCESS_CREATED, type: 'success' });
        redirectBack();
      })
      .catch((err) => {
        notify.show({ message: err.message, type: 'error' });
      })
      .finally(() => {
        loader.hide();
      });
};

const handleUpdateState = (data) => {
  updateStateMaster(stateId.value, data)
      .then(() => {
        notify.show({ message: MESSAGE_SUCCESS_UPDATED, type: 'success' });
        redirectBack();
      })
      .catch((err) => {
        notify.show({ message: err.message, type: 'error' });
      })
      .finally(() => {
        loader.hide();
      });
};

const fetchDetailState = async (id) => {
  try {
    loader.show();
    const data = await getDetailStateMaster(stateId.value ?? id);
    form.value = data;
  } catch (error) {
    notify.show({ message: error.message, type: 'error' });
  } finally {
    loader.hide();
  }
};

onMounted(async () => {
  if (action.value !== 'create') {
    await fetchDetailState();
  }
});
</script>

<template>
  <AppPage :title="pageTitle">
    <template v-slot:default>
      <q-card flat>
        <q-card-section>
          <div class="q-gutter-lg q-mt-md">
            <div class="row q-col-gutter-md">
              <div class="col-4">
                <label class="q-my-sm text-label">Nama <span class="text-red">*</span></label>
                <VInput
                    v-model="form.name"
                    :readonly="isReadOnly"
                />
              </div>
              <div class="col-4">
                <label class="q-my-sm text-label">Minimum Grade <span class="text-red">*</span></label>
                <VInput
                    v-model="form.minimum_grade"
                    :readonly="isReadOnly"
                />
              </div>
              <div class="col-4">
                <label class="q-my-sm text-label">Actor <span class="text-red">*</span></label>
                <VInput
                    v-model="form.actor"
                    :readonly="isReadOnly"
                />
              </div>
            </div>
            <div class="row q-col-gutter-md">
              <div class="col-12">
                <label class="q-my-sm text-label">Description <span class="text-red">*</span></label>
                <q-editor
                    v-model="form.description"
                    :readonly="isReadOnly"
                    :toolbar="[['bold', 'italic', 'strike', 'underline', 'unordered', 'ordered']]"
                />
              </div>
            </div>
          </div>
        </q-card-section>

      </q-card>
      <div v-if="action === 'create'">
        <div class="flex justify-end q-mt-md q-gutter-x-xs">
          <q-btn label="Batal" unelevated flat @click="redirectBack" />
          <q-btn label="Simpan" color="primary" unelevated @click="handleSubmit" />
        </div>
      </div>
      <div v-else>
        <div class="flex justify-end q-mt-md q-gutter-x-xs">
          <q-btn label="Kembali" color="secondary" unelevated @click="redirectBack" />
          <q-btn label="Simpan" color="orange" unelevated @click="handleSubmit" />
        </div>
      </div>
    </template>
  </AppPage>
</template>

<style scoped>
.text-label {
  font-weight: bold;
}
</style>
