<script setup>
import {computed} from "vue";

const props = defineProps({
  modal: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
  },
  disableCloseButton: {
    type: Boolean,
    default: false
  },
  withScrollableContent: {
    type: Boolean,
    default: false
  },
  width: {
    type: String,
    default: '44em'
  },
  maxWidth: {
    type: String,
    default: '80vw'
  }
})

const scrollableClass = props.withScrollableContent ? 'overflow-auto max-height-lg' : '';

const emits = defineEmits(['update:modal', 'hide'])
const modal = computed({
  get() {
    return props.modal
  },
  set(value) {
    emits("update:modal", value);
  }
})
</script>

<template>
  <q-dialog
      v-model="modal"
      v-bind="$attrs"
  >
    <q-card :style="{width: width, maxWidth: maxWidth}">
      <q-card-section class="bg-header">
        <div class="flex justify-between">
          <slot name="title">
            <p class="text-h6 q-mb-none">{{ title }}</p>
          </slot>
          <q-btn
              v-if="!disableCloseButton"
              flat
              v-close-popup
              round
              dense
              icon="close"
              @click="emits('hide')"
          />
        </div>
      </q-card-section>
      <q-card-section class="q-gutter-y-md" :class="`${scrollableClass}`">
        <slot name="content"></slot>
      </q-card-section>
      <q-card-actions class="flex justify-end">
        <slot name="action"></slot>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style scoped>
.bg-header {
  background: #7a7a7a;
  color: white;
  z-index: 1
}
</style>
