import PrivateLayout from "../layouts/PrivateLayout.vue";
import HomePage from "../pages/home/HomePage.vue";
import Applicants from "@/pages/applicantsMgmt/Applicants.vue";

import JobVacancy from "../pages/jobMgmt/jobVacancy/JobVacancyPage.vue";
import JobPosition from "../pages/jobMgmt/position/JobPositionPage.vue";
import SkillSetPage from "../pages/jobMgmt/skillSet/SkillSetPage.vue";
import JobVacancyForm from "@/pages/jobMgmt/jobVacancy/JobVacancyForm.vue";
import JobVacancyStep from "@/pages/jobMgmt/jobVacancy/JobVacancyFlow/JobVacancyFlow.vue";
import {PELAMAR_ROLE, SYSTEM_ADMIN_ROLE} from "@/constants/roles.js";
import Application from "@/pages/applicantsForm/ApplicantsForm.vue";
import JobCode from "@/pages/applicantsForm/JobCode.vue";
import QuestionMasterPage from "../pages/questionMaster/QuestionMasterPage.vue";
import QuestionMasterDetailPage from "@/pages/questionMaster/QuestionMasterDetailPage.vue";
import EmailLogger from "@/pages/email/EmailLoggerPage.vue";
import RolePage from "@/pages/akunMgmt/role/RolePage.vue";
import ReferencesMasterPage from "@/pages/referenceMaster/ReferencesMasterPage.vue";
import ReferencesMasterForm from "@/pages/referenceMaster/ReferencesMasterForm.vue";
import StateMasterPage from "@/pages/jobMgmt/state/StateMasterPage.vue";
import StateMasterForm from "@/pages/jobMgmt/state/StateMasterForm.vue";
import QuestionBankPage from "@/pages/questionBank/QuestionBankPage.vue";
import QuestionForms from "@/pages/questionMaster/QuestionForms.vue";
import FormSkillSet from "@/pages/jobMgmt/skillSet/FormSkillSet.vue";

const usePrivateRoutes = (routes) => {
    return routes.map((route) => ({
        ...route,
        meta: {
            layout: PrivateLayout,
            isNeedAuthentication: true,
            ...route.meta
        }
    }))
}

const privateRoutes = usePrivateRoutes([
    {
        path: '/home',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/application',
        name: 'Application',
        component: Application,
        meta: {
            requireRoles: [PELAMAR_ROLE],
            alias: 'Lamaran'
        }
    },
    {
        path: '/job',
        name: 'JobCode',
        component: JobCode,
        meta: {
            requireRoles: [PELAMAR_ROLE]
        }
    },
    {
        path: '/applicants-management',
        meta: {
            alias: 'Manajemen Pelamar'
        },
        children: [
            {
                path: 'applicants',
                name: 'Applicants',
                component: Applicants,
                meta: {
                    requireRoles: [SYSTEM_ADMIN_ROLE],
                    alias: 'Pelamar'
                }
            },
            {
                path: 'email-logger',
                name: 'EmailLogger',
                component: EmailLogger,
                meta: {
                    requireRoles: [SYSTEM_ADMIN_ROLE],
                    alias: 'Email'
                }
            }
        ]
    },
    {
        path: '/akun-management',
        meta: {
            alias: 'Manajemen Akun'
        },
        children: [
            {
                path: '/peran',
                name: 'peran',
                component: RolePage,
                meta: {
                    requireRoles: [SYSTEM_ADMIN_ROLE],
                    alias: 'Peran'
                }
            },
        ]
    },
    {
        path: '/job',
        meta: {
            alias: 'Manajemen Pekerjaan',
            requireRoles: [SYSTEM_ADMIN_ROLE]
        },
        children: [
            {
                path: 'vacancy',
                meta: {
                    alias: 'Lowongan'
                },
                children: [
                    {
                        path: '',
                        name: 'JobVacancy',
                        component: JobVacancy
                    },
                    {
                        path: 'step/:id',
                        name: 'JobVacancyFlow',
                        component: JobVacancyStep
                    },
                    {
                        path: ':action/:id?',
                        name: 'FormJobVacancy',
                        component: JobVacancyForm,
                        beforeEnter: (to, from, next) => {
                            const allowedActions = ['view', 'create', 'edit'];
                            if (allowedActions.includes(to.params.action)) {
                                next();
                            } else {
                                next('/error');
                            }
                        }
                    }
                ]
            },
            {
                path: 'position',
                name: 'JobPosition',
                meta: {
                    alias: 'Posisi'
                },
                component: JobPosition
            },
            {
                path: 'skill-set',
                name: 'SkillSet',
                meta: {
                    alias: 'Keahlian'
                },
                component: SkillSetPage
            },
            {
                path: ':action/:id?',
                name: 'FormSkillSet',
                component: FormSkillSet,
                beforeEnter: (to, from, next) => {
                    const allowedActions = ['view', 'create', 'edit'];
                    if (allowedActions.includes(to.params.action)) {
                        next();
                    } else {
                        next('/error');
                    }
                }
            },
            {
                path: 'state',
                name: 'state',
                meta: {
                    alias: 'Tahapan'
                },
                component: StateMasterPage
            },
            {
                path: ':action/:id?',
                name: 'StateMasterForm',
                component: StateMasterForm,
                beforeEnter: (to, from, next) => {
                    const allowedActions = ['view', 'create', 'edit'];
                    if (allowedActions.includes(to.params.action)) {
                        next();
                    } else {
                        next('/error');
                    }
                }
            }
        ]
    },
    {
        path: '/question-master',
        name: 'QuestionMaster',
        meta: {
            alias: 'Kumpulan Pertanyaan',
            requireRoles: [SYSTEM_ADMIN_ROLE]
        },
        children: [
            {
                path: '',
                name: 'questionMasterList',
                component: QuestionMasterPage
            },
            {
                path: 'view/:id',
                name: 'questionMasterDetail',
                component: QuestionMasterDetailPage
            },
            {
                path: ':action/:id?',
                name: 'QuestionForms',
                component: QuestionForms,
                beforeEnter: (to, from, next) => {
                    const allowedActions = ['view', 'create', 'edit'];
                    if (allowedActions.includes(to.params.action)) {
                        next();
                    } else {
                        next('/error');
                    }
                }
            }
        ],
    },
    {
        path: '/question-bank',
        name: 'QuestionBank',
        meta: {
            alias: 'Bank Pertanyaan',
            requireRoles: [SYSTEM_ADMIN_ROLE]
        },
        children: [
            {
                path: '',
                name: 'questionBankList',
                component: QuestionBankPage
            }
        ],
    },
    {
        path: '/reference-master',
        name: 'ReferenceMaster',
        meta: {
            alias: 'Reference',
            requireRoles: [SYSTEM_ADMIN_ROLE]
        },
        children: [
            {
                path: '',
                name: 'referenceMaster',
                component: ReferencesMasterPage
            },
            {
                path: ':action/:id?',
                name: 'ReferencesMasterForm',
                component: ReferencesMasterForm,
                beforeEnter: (to, from, next) => {
                    const allowedActions = ['view', 'create', 'edit'];
                    if (allowedActions.includes(to.params.action)) {
                        next();
                    } else {
                        next('/error');
                    }
                }
            }
        ],
    }
])

export default privateRoutes;
