export const EDIT_ACTION_LABEL = 'Ubah'
export const STEP_ACTION_LABEL = 'Lihat Step'
export const VIEW_ACTION_LABEL = 'Lihat Detail'
export const DELETE_ACTION_LABEL = 'Hapus'
export const APP_NAME_LABEL = 'RecruitMint'
export const PREV_ACTION_LABEL = 'Tahapan Sebelumnya'
export const BACK_ACTION_LABEL = 'Kembali'
export const NEXT_ACTION_LABEL = 'Tahapan Selanjutnya'
export const DONE_ACTION_LABEL = 'Selesai'
export const EXPORT_ACTION_LABEL = 'Ekspor'
export const CLOSE_ACTION_MODAL_LABEL = 'Batal';
export const OK_ACTION_MODAL_LABEL = 'Ok';
export const CONFIRMATION_TITLE_LABEL = 'Konfirmasi';
export const NEXT_STEP_CONFIRMATION_TITLE_LABEL = 'Apakah Anda yakin data yang anda inputkan sudah benar, dan akan dilanjutkan ke tahap selanjutnya?';
export const SAVE_CONFIRMATION_LABELS = 'Simpan';
export const DELETE_CONFIRMATION_LABEL = 'Apakah Anda yakin akan menghapus data?'

export const APPLY_CONFIRMATION_LABEL = 'Apakah anda yakin data yang diinput sudah benar untuk melamar posisi ini ?';

export const REGISTER_CONFIRMATION_LABEL = 'Apakah anda yakin input pendaftaran sudah benar ?';

export const CANCEL_REGISTER_LABEL = 'Apakah anda yakin membatalkan pendaftaran ? formulir yang sudah diisi akan hilang'

export const COMPLETE_BIODATA_LABEL = 'Apakah anda yakin input biodata sudah benar ?'
export const CREATE_TITLE_MODAL_LABEL = 'Tambah Data'
export const UPDATE_TITLE_MODAL_LABEL = 'Ubah Data'
