<script setup>
import { CREATE_TITLE_MODAL_LABEL, SAVE_CONFIRMATION_LABELS, UPDATE_TITLE_MODAL_LABEL } from "@/constants/label.js";
import AppModal from "@/components/AppModal.vue";
import VInput from "@/components/input/VInput.vue";
import { computed } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  mode: {
    type: String,
    default: 'CREATE'
  },
  form: {
    type: Object,
    default: () => ({})
  }
});

const emits = defineEmits(['update:form', 'update:modelValue', 'save', 'update']);

const form = computed({
  get() {
    return props.form;
  },
  set(val) {
    emits('update:form', val);
  }
});

const rules = {
  name: { required },
  code: { required }
};

const $vuelidate = useVuelidate(rules, form.value);

const modal = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emits('update:modelValue', val);
  }
});

const onResetForm = () => {
  $vuelidate.value.$reset();
  form.value.name = '';
  form.value.code = '';
};

const handleSubmit = () => {
  const valid = $vuelidate.value.$validate();
  if (!valid) return;
  if(props.mode === 'CREATE') {
    emits('save')
  } else emits('update')
}

</script>

<template>
  <AppModal :title="mode === 'CREATE' ? CREATE_TITLE_MODAL_LABEL : UPDATE_TITLE_MODAL_LABEL" v-model:modal="modal"
            @hide="onResetForm" persistent>
    <template v-slot:content>
      <div class="row column">
        <div class="text-weight-bold">Nama</div>
        <VInput
            v-model="form.name"
            @blur="$vuelidate.name.$touch"
            :errors="$vuelidate.name.$errors"
            hide-bottom-space
        />
      </div>
      <div class="row column">
        <div class="text-weight-bold">Code</div>
        <VInput
            v-model="form.code"
            @blur="$vuelidate.code.$touch"
            :errors="$vuelidate.code.$errors"
            v-if="mode === 'CREATE'"
            hide-bottom-space
        />
        <div v-else class="q-mb-md">
          <span>{{ form.code }}</span>
        </div>
      </div>
    </template>
    <template v-slot:action>
      <q-btn unelevated color="primary" class="q-mr-sm" id="btn-save-skill" :label="SAVE_CONFIRMATION_LABELS"
             @click="handleSubmit"></q-btn>
    </template>
  </AppModal>
</template>

<style scoped>
</style>
