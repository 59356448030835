<script setup>
import AppPage from "@/components/AppPage.vue";
import {computed, onMounted, reactive, ref} from "vue";
import {getListFormQuestionById} from "@/lib/api.js";
import {useRoute} from "vue-router";

const route = useRoute();
const id = ref(route?.params?.id);
const data = ref([]);
const question = computed(() => {
  if (data.value.data) {
    return JSON.parse(data.value.data);
  }
  return [];
})
const fetchMasterSoal = () => {
  return getListFormQuestionById(id.value)
      .then((res) => {
        if (res) {
          data.value = res;
        }
      })
}

onMounted(() => {
  fetchMasterSoal();
})

</script>

<template>
  <AppPage>
    <q-card>
      <q-card-section>
        <div class="text-h6 q-mb-xl text-bold">Informasi Master Soal</div>
        <div class="q-gutter-y-md">
          <div class="row">
            <div class="col-2 text-bold">
              Posisi
            </div>
            <div class="col">
              {{ data.mstposition?.name }}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-bold">
              Tahapan
            </div>
            <div class="col">
              Pre-Test
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-bold">
              Deskripsi
            </div>
            <div class="col">
              {{ data.description }}
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>

    <div v-if="question.length > 0" v-for="(item, index) in question" :key="index" class="q-mb-md">
      <q-card class="q-mt-lg" v-if="item.type === 'MULTIPLEGROUP'">
        <q-card-section>
          <div class="flex justify-between items-center">
            <div class="text-h5">{{ item.display_order }}. {{ item.header }}</div>
            <q-btn icon="add"></q-btn>
          </div>
          <ol type="a" class="text-h6">
            <li v-for="(attributes, index) in item.attributes" :key="index" class="q-ml-lg">
              <div>{{attributes.question}}</div>
              <div v-for="(option, index) in attributes.answer" :key="index" class="flex gap-2">
                <div><input type="radio" disabled/></div>
                <div>{{option}}</div>
              </div>
            </li>
          </ol>
        </q-card-section>
      </q-card>
    </div>

  </AppPage>
</template>

<style scoped></style>
