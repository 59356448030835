<script setup lang="js">
import {computed, onMounted, ref, watch} from 'vue';

const input = ref();
const isLoading = ref(false);
const isFailed = ref(false);

const props = defineProps({
  errors: {
    type: Array,
    default: null,
  },
  modelValue: null,
  options: Array,
  emitValue: {
    type: Boolean,
    default: true
  },
  fetch: Function,
  transformValueKey: {
    type: String,
    default: 'id'
  },
  transformLabelKey: {
    type: String,
    default: 'name'
  },
})

const options = ref(props.options);

const emits = defineEmits([
  'update: modelValue',
  'update: options'
])

const model = computed({
  get: () => props.modelValue,
  set: (v) => emits('update: modelValue', v)
});

onMounted(() => {
  onFetchData();
});

const retry = () => {
  onFetchData();
};

const onFetchData = () => {
  isLoading.value = true;
  props
      .fetch()
      .then((data) => {
        if (data) {
          options.value = transformToOptions(data);
        }
        isFailed.value = false;
      })
      .catch(() => {
        isFailed.value = true;
      })
      .finally(() => {
        isLoading.value = false;
      });
};

const transformToOptions = (items) => {
  return items.map(i => {
    return {
      label: i[props.transformLabelKey],
      value: i[props.transformValueKey]
    };
  })
}

watch(
    () => options.value,
    (v) => {
      emits('update: options', v);
    }
);
</script>

<template>
  <q-select
      ref="input"
      v-model="model"
      dense
      option-label="label"
      option-value="value"
      outlined
      :emit-value="emitValue"
      :error="!!errors?.length && errors?.length > 0"
      :options="options"
      :disable="isLoading"
      hide-bottom-space
      map-options
      v-bind="{ ...$attrs }"
      :loading="isLoading"
  >
    <template v-slot:error>
      <slot name="error" v-if="!!errors?.length && errors?.length > 0">
        <div role="alert" v-for="err in errors" :key="err.$uid">
          {{ err?.$message }}
        </div>
      </slot>
    </template>
  </q-select>
  <div v-if="isFailed" class="flex justify-end q-mt-xs">
    <q-btn label="retry" @click="retry"/>
  </div>
</template>
