<script setup>
import AppPage from "@/components/AppPage.vue";
import AppTable from "@/components/AppTable.vue";
import {ref} from "vue";
import {columnsQuestionMaster} from "./question-master.js";
import {getListFormQuestion, getQuestionBankList} from "@/lib/api.js";
import useLoader from "@/plugins/loader/index.js";
import useNotify from "@/plugins/notify/index.js";
import QViewButtonGroup from "@/components/button/QViewButtonGroup.vue";
import QEditButtonGroup from "../../components/button/QEditButtonGroup.vue";
import QDeleteButtonGroup from "../../components/button/QDeleteButtonGroup.vue";
import router from "@/router/index.js";

const title = 'Kumpulan Pertanyaan';
const rows = ref([]);
const notify = useNotify();
const loader = useLoader();
const keyword = ref('');
const fetchListFormQuestions = (offset, limit) => {
  const params = {
    offset,
    limit,
    keyword: keyword.value,
    order: 'desc',
  }

  return getQuestionBankList(params)
      .then((response) => {
        return {
          rows: response.data,
          total: response.total,
        }
      }).catch((error) => {
        notify.show({message: error.message, type: 'error'})
      }).finally(() => {
        loader.hide()
      })
};

const handleShowDetail = (id) => {
  router.push({
    name: 'questionMasterDetail',
    params: {
      id,
      action: 'view'
    }
  })
}

const handleShowEdit = (id) => {
  router.push({
    name: 'QuestionForms',
    params: {
      id,
      action: 'edit'
    }
  })
}

const handleTambah = () => {
  router.push({
    name: 'QuestionForms',
    params: {
      action: 'create'
    }
  })
}

</script>

<template>
  <AppPage
      :title="title"
  >
    <div class="flex justify-end q-mb-md">
      <q-btn id="btn-add-job-vacancy" icon="add" unelevated label="Tambah" color="primary" @click="handleTambah"/>
    </div>
    <AppTable
        :columns="columnsQuestionMaster"
        v-model:rows="rows"
        :fetch="fetchListFormQuestions"

    >
      <template v-slot:body-cell-no="props">
        <q-td :props="props">
          {{ props.rowIndex + 1 }}
        </q-td>
      </template>
      <template v-slot:body-cell-action="props">
        <q-td :props="props" class="q-gutter-x-xs">
          <QEditButtonGroup @click="handleShowEdit(props.row?.id)"/>
          <QDeleteButtonGroup/>
        </q-td>
      </template>
    </AppTable>
  </AppPage>
</template>

<style scoped></style>
