
export const columns = [
    { name: 'name', align: 'left', label: 'Nama', field: 'name', sortable: true},
    { name: 'phone_no', align:'left', label: 'No. Telp', field: 'phone_no', sortable: true },
    { name: 'email', align:'left', label: 'Email', field: 'email', sortable: true },
    { name: 'city', align:'left', label: 'Alamat', field: 'city', sortable: true },
    { name: 'university_name', align:'left', label: 'Institusi', field: 'university_name', sortable: true },
    { name: 'major_name', align:'left', label: 'Jurusan', field: 'major_name', sortable: true },
    { name: 'education_name', align:'left', label: 'Pendidikan', field: 'education_name', sortable: true },
    { name: 'skill_names', align:'left', label: 'Kemampuan Teknis', field: 'skill_names', sortable: true },
    // { name: 'min_salary', align:'left', label: 'Gaji Minimal', field: 'min_salary', sortable: true },
    // { name: 'max_salary', align:'left', label: 'Gaji Maksimal', field: 'max_salary', sortable: true },
    { name: 'cv_url', align:'left', label: 'CV', field: 'cv_url', sortable: true },
    { name: 'action', align:'center', label: 'Aksi', field: 'action'},
]

