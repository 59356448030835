<script setup>
import {computed, ref} from "vue";
import {useRoute, useRouter} from 'vue-router';
import {register} from "@/lib/api.js";
import {isRequired, limitCharacter, isEmail, isSame,} from "@/lib/ValidationHelper.js";
import AppPage from "@/components/AppPage.vue";
import useVuelidate from "@vuelidate/core";
import useNotify from "@/plugins/notify/index.js";
import {MESSAGE_SUCCESS_CREATED, MESSAGE_SUCCESS_REGISTRATION} from "@/constants/default-messages.js";
import useAuthStore from "@/stores/auth.js";
import useConfirmation from "@/plugins/confirmation/index.js";
import useLoader from "@/plugins/loader/index.js";
import {CANCEL_REGISTER_LABEL, CONFIRMATION_TITLE_LABEL, REGISTER_CONFIRMATION_LABEL} from "@/constants/label.js";
import AccountForm from "@/components/AccountForm.vue";

const notify = useNotify();
const route = useRoute();
const router = useRouter();
const confirm = useConfirmation();
const loader = useLoader();
const action = ref(route?.params?.action || 'create');
const authState = useAuthStore()

const form = ref({
  email:  null,
  password: null,
  confirm_password: null,
  username: null,
  name: null,
})

const rulesAccount = computed(() => ({
  email:  {
    ...isRequired('Email'),
    ...isEmail()},
  password: isRequired('Password'),
  confirm_password: {
    ...isRequired('Konfirmasi password'), ...isSame(form.value.password)},
  name: {
    ...isRequired('Nama'),
    ...limitCharacter('Nama',3)
  },
}))

const $vuelidateAccount = useVuelidate(rulesAccount,form);

const logout = async () => {
  authState.setState(null, null, null)
  return await router.push({name: 'LoginPage'})
}

const cancelRegister = () => {
  confirm
      .setTitle(CONFIRMATION_TITLE_LABEL)
      .setMessage(CANCEL_REGISTER_LABEL)
      .setOnConfirm(() => {
        logout()
      }).show()
}

const doSubmit = async () => {

  const valid = await $vuelidateAccount.value.$validate();
  if(!valid) return;

  confirm
      .setTitle(CONFIRMATION_TITLE_LABEL)
      .setMessage(REGISTER_CONFIRMATION_LABEL)
      .setOnConfirm(() => {
        loader.show()
        const data = {
          ...form.value,
          username: form.value.email.substring(0, form.value.email.indexOf("@")),
        }
        register(data)
            .then((response) => {
              if(response) {
                notify.show({
                  message: MESSAGE_SUCCESS_REGISTRATION,
                  type: 'success'
                })
                logout()
              }
            }).catch((err) => {
          notify.show({message: err.message, type: 'error'})
        }).finally(() => {
          loader.hide()
        })
      }).show()
}

</script>

<style lang="sass" scoped>
.my-card
  width: 60%
  margin: 0 auto

.my-select
  margin-top: 4%
  margin-bottom: 4%

.body
  background: rgb(255, 119, 0)
  background: linear-gradient(90deg, rgba(255, 119, 0, 1) 0%, rgb(255, 208, 136) 100%)
</style>



<template>
  <AppPage title="Formulir Registrasi">
    <template v-slot:default>
      <div class="flex justify-center q-mb-lg">
        <img src="../../assets/images/logo-ars.png" alt="logo-ars" style="width: 13rem; padding-top: 1%"/>
      </div>

      <q-card flat bordered class="my-card">
        <q-form class="q-gutter-md"
        >
          <q-card>
            <q-card-section>
              <AccountForm
                  :form="form"
                  title="Silakan register pada halaman di bawah"

              ></AccountForm>
            </q-card-section>
            <q-card-section class="q-pb-md">
              <div class="row q-pt-md">
                <div class="col-6">
                  <q-btn flat @click="cancelRegister" color="primary" label="Kembali" class="float-left" id="btn-back-login"/>
                </div>
                <div class="col-6">
                  <q-btn
                      id="btn-validate-education-submit"
                      color="primary"
                      @click="doSubmit"
                      label="Submit"
                      class="float-right"/>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-form>
      </q-card>
    </template>
  </AppPage>
</template>