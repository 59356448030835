import LoginPage from "../pages/auth/LoginPage.vue";
import PublicLayout from "../layouts/PublicLayout.vue";
import RegisterApplicants from "@/pages/applicantsForm/RegisterApplicants.vue";
import VerificationConf from "@/pages/auth/VerificationConf.vue";
import Unauthorized from "@/pages/errors/Unauthorized.vue";


const usePublicRoutes = (routes) => {
    return routes.map((route) => ({
        ...route,
        meta: {
            layout: PublicLayout,
            ...route.meta
        }
    }))
}

const publicRoutes = usePublicRoutes([
    {
        path: '/',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/confirmation',
        name: 'Confirmation',
        component: VerificationConf,
    },
    {
        path: '/register',
        name: 'RegisterApplicants',
        component: RegisterApplicants
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    },
])

export default publicRoutes;
