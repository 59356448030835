<script setup>
import {ref} from 'vue'
import useAuthStore from "@/stores/auth.js";
import {useRouter} from "vue-router";
import {APP_NAME_LABEL} from "../constants/label.js";
import AppMenu from "@/layouts/AppMenu.vue";
import {PELAMAR_ROLE, SYSTEM_ADMIN_ROLE} from "@/constants/roles.js";
import {adminMenu, pelamarMenu} from "@/layouts/menu.js";

const authState = useAuthStore();
const router = useRouter();
const user = authState.user;

const leftDrawerOpen = ref(false)

const toggleLeftDrawer = () => {
  leftDrawerOpen.value = !leftDrawerOpen.value
}

const logout = () => {
  authState.setState(null, null, null);
  router.push({
    name: 'LoginPage'
  })
}

const roles = authState.roles;

</script>

<template>
  <q-layout view="lHr Lpr lFr" class="bg-grey-1">

    <q-header elevated class="bg-header text-white" :style="{ left: leftDrawerOpen ? '250px !important' : '0' }">
      <q-toolbar>
        <q-btn dense flat round icon="menu" @click="toggleLeftDrawer"/>
        <q-toolbar-title>
        </q-toolbar-title>
        <q-space/>
        <div class="q-pa-sm q-gutter-sm row items-center no-wrap">
          <div style="font-size: 16px" class="text-black text-weight-bold">
            {{ user }}
          </div>
          <q-btn-dropdown size="md" flat color="black">
            <q-list>
              <q-item clickable @click="logout">
                <q-item-section avatar>
                  <q-icon name="logout"/>
                </q-item-section>
                <q-item-section>Logout</q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer show-if-above v-model="leftDrawerOpen" side="left">
      <q-scroll-area class="fit">
        <q-toolbar-title class="row items-center justify-center q-mt-md" style="min-height: 56px;">
          <span class="q-mt-md text-bold">{{ APP_NAME_LABEL }}</span>
        </q-toolbar-title>
        <AppMenu v-if="roles.includes(SYSTEM_ADMIN_ROLE)" :menu="adminMenu"/>
        <AppMenu v-if="roles.includes(PELAMAR_ROLE)" :menu="pelamarMenu"/>
      </q-scroll-area>
    </q-drawer>

    <q-page-container :style="{ paddingLeft: leftDrawerOpen ? '250px !important' : '0' }" class="q-pb-lg">
        <router-view/>
    </q-page-container>

  </q-layout>
</template>

<style>
.bg-header {
  background: rgb(255, 119, 0);
  background: linear-gradient(90deg, rgba(255, 119, 0, 1) 0%, rgb(239, 226, 141) 100%);
}

.bg-header-orange {
  background: rgba(255, 119, 0, 10);
}

.q-layout .q-item__section--avatar {
  min-width: 10px !important;
}

.q-layout {
  color: #606060;
}

.q-layout .q-item {
  padding-top: 16px;
  padding-bottom: 16px;
}

aside {
  width: 250px !important;
}
</style>
