<script setup>
import {computed, reactive, ref} from "vue";
import useAuthStore from "@/stores/auth.js";
import { useRouter } from 'vue-router';
import {login} from "@/lib/api.js";
import useNotify from "@/plugins/notify/index.js";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import VInput from "@/components/input/VInput.vue";
import {requiredValidateLabel} from "@/constants/rules-label.js";
import {PELAMAR_ROLE} from "@/constants/roles.js";
const form = reactive({
  username: '',
  password: '',
})
const loading = ref(false);
const isPwd = ref(true);
const authState = useAuthStore();
const router = useRouter();
const notify = useNotify();

const rules = {
  username: { required: helpers.withMessage(requiredValidateLabel('Email'), required) },
  password: { required: helpers.withMessage(requiredValidateLabel('Password'), required) }
}

const $vuelidate = useVuelidate(rules, computed(() => form))

const doRegister = () => {
  if (!authState.isAuthenticated) {
    router.push({
      name: 'RegisterApplicants'
    })
  }
}


const isVerified = (is_verified, role) => {
  return ((is_verified === 't' && role === PELAMAR_ROLE) || (role !== PELAMAR_ROLE))
}

const doLogin = async () => {
  const valid =  await $vuelidate.value.$validate();
  if(!valid) return;
  loading.value = true;
  login(form)
      .then((res) => {
        if (res && isVerified(res.is_verified, res.roles[0])) {
          authState.setState(res.token, res.name, res.roles, res.email, res.is_verified);
          router.push({
            name: 'HomePage'
          })
        }
        else {
          notify.show({
            message: 'Akun anda belum diverifikasi, silakan klik link yang telah dikirim di Email',
            type: 'error'
          })
        }
      })
      .catch((err) => {
        notify.show({
          message: err.message,
          type: 'error'
        })
      })
      .finally(() => {
        loading.value = false;
      })
}

</script>

<template>
  <div style="height: 100vh;" class="flex items-center bg-grey-1">
    <div style="margin: 0 auto; width: 25rem">
      <div class="flex justify-center q-mb-lg">
        <img src="../../assets/images/logo-ars.png" alt="logo-ars" style="width: 20rem"/>
      </div>
      <q-card class="shadow-2 q-py-md q-px-md">
        <q-card-section>
          <div class="text-h6 text-bold text-center">Masuk</div>
          <div class="text-center">Masuk di bawah ini untuk mengakses Akun Anda</div>
        </q-card-section>
        <q-card-section>
          <q-form class="q-gutter-y-md" id="login-form">
            <VInput
              type="text"
              v-model="form.username"
              :hide-bottom-space="false"
              @keyup.enter="doLogin"
              label="Email"
              @blur="$vuelidate.username.$touch()"
              :errors="$vuelidate.username.$errors"
              for="username-field"
              ></VInput>
            <VInput
                :type="isPwd ? 'password' : 'text'"
                v-model="form.password"
                for="password-field"
                @keyup.enter="doLogin"
                label="Password"
                @blur="$vuelidate.password.$touch()"
                :hide-bottom-space="false"
                :errors="$vuelidate.password.$errors"
            >
              <template v-slot:append>
                <q-icon
                    :name="isPwd ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="isPwd = !isPwd"
                />
              </template>
            </VInput>
            <q-btn
                id="login-btn"
                label="Masuk"
                no-caps
                class="full-width"
                style="border-radius: 10px"
                :loading="loading"
                @click="doLogin"
                color="black">
            </q-btn>
          </q-form>
        </q-card-section>
        <q-card-section>
          <div class="text-center">Belum punya akun?</div>
          <div class="flex justify-center">
            <q-btn id="sign-up-btn" flat dense no-caps color="blue" @click="doRegister">Daftar</q-btn>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<style scoped>
body{
  background: red;
}
</style>
