<script setup>
import {getInstitutionsByName, getMajor, getReferences, getSkills, postApplicants} from "@/lib/api.js";
import {isInteger, isNumeric, isRequired, isUrl, limitCharacter, limitValue} from "@/lib/ValidationHelper.js";
import {helpers, integer, requiredIf} from "@vuelidate/validators";
import {INTEGER_VALIDATE_LABEL} from "@/constants/rules-label.js";
import {computed, onMounted, reactive, ref} from "vue";
import useVuelidate from "@vuelidate/core";
import VPreloadedSelect from "@/components/input/VPreloadedSelect.vue";
import VInput from "@/components/input/VInput.vue";
import QDateInput from "@/components/input/VDateInput.vue";
import {SAVE_CONFIRMATION_LABELS} from "@/constants/label.js";
import useNotify from "@/plugins/notify/index.js";
import useConfirmation from "@/plugins/confirmation/index.js";
import useAuthStore from "@/stores/auth.js";

const authState = useAuthStore()
const notify = useNotify();
const kodeNegara = ref('+62')
const genderOptions = ref([]);
const religionOptions = ref([]);
const institutionOptions = reactive([])
const educationOptions = ref([])
const experienceOptions = ref([])
const majorOptions = reactive([])
const skillOptions = reactive([]);
const tab = ref('personal')

const confirm = useConfirmation()

const props = defineProps({
  isReadOnly: {
    type: Boolean,
    default: false
  },
  title: String,
  form: Object
});
const biodataForm = computed({
  get(){
    return props.form
  },
  set(val){emits('update:biodata',val)}
})

const getSkillNames = computed(() => {
  const skills = biodataForm.value.skills.includes(',') ? biodataForm.value.skills.split(',') : biodataForm.value.skills;

  const selectedNames = skills.map(value => {
    const option = skillOptions.value.find(opt => opt.value === value);
    return option ? option.label : null;
  });

  return selectedNames.join(', ');
})


const emits = defineEmits(['update:biodata']);

const filterInstitutions = (val, update, abort) => {
  if (val < 3) {
    abort();
    return;
  }
  update( async () => {
    const keyword = val.toLowerCase();
    await getInstitutionsByName({ name: keyword }).then(res => {
      institutionOptions.value = res.map(c => {
        return {
          label: c.name,
          value: c.code
        }
      });
    }).catch(() => { institutionOptions.value = null })
  })
}

const filterMajor = (val, update, abort) => {
  if (val < 3) {
    abort();
    return;
  }
  update( async () => {
    const keyword = val.toLowerCase();
    await getMajor({ name: keyword }).then(res => {
      majorOptions.value = res.map(c => {
        return {
          label: c.name,
          value: c.code
        }
      });
    }).catch(() => { majorOptions.value = null })
  })
}

const filterSkills = (val, update, abort) => {
  if (val < 3) {
    abort();
    return;
  }
  update( async () => {
    const keyword = val.toLowerCase();
    await getSkills({ name: keyword }).then(res => {
      skillOptions.value = res.map(c => {
        return {
          label: c.name,
          value: c.code,
        }
      });
    }).catch(() => { skillOptions.value = null })
  })
}

const fetchEducation = () => {
  return getReferences('EDUCATION_REF').then((res) => {
    return res
  }).catch(() => {})
}
const fetchExperience = () => {
  return getReferences('REF_LEVEL').then((res) =>
  {
    return res}).catch(() => {})
}
const fetchGender = () => {
  return getReferences('REF_GENDER').then((res) =>
  {
    for (let i=0; i<res.length; i++){
      genderOptions.value[i] = ({value: res[i].code, label: res[i].name})
    }
  }).catch(() => {})
}
const fetchReligion = () => {
  return getReferences('REF_RELIGION').then((res) =>
  {
    return res}).catch(() => {})
}
const fetchSkills = () => {
  getSkills().then(res => {
    skillOptions.value = res.map(c => {
      return {
        label: c.name,
        value: c.code,
      }
    });
  }).catch(() => {})
}

const maxValue = computed(() => {
  if (!!biodataForm.value.education) {
    return (biodataForm.value.education.includes('SMA') || biodataForm.value.education.includes('SMK')) ? 100 : 4;
  }
  else{
    return 0
  }
})

const rulesBiodata = computed(() => ({
  name: {
    ...isRequired('Nama'),
    ...limitCharacter('Nama',3)
  },
  gender: isRequired('Jenis kelamin'),
  religion: isRequired('Agama'),
  birth_date: isRequired('Tanggal lahir'),
  city: isRequired('Alamat domisili'),
  phone_no : {
    integer: helpers.withMessage(INTEGER_VALIDATE_LABEL, integer),
    ...isRequired('Nomor telepon'),
    ...limitCharacter('Nomor telepon', 10, 13)
  },
  education: isRequired('Jenjang pendidikan'),
  university: isRequired('Nama institusi'),
  university_other: {
    requiredIf: helpers.withMessage('Masukkan nama institusi jika tidak ada pilihan', requiredIf(isOther(biodataForm.value.university)))
  },
  major: isRequired('Jurusan'),
  major_other: {
    requiredIf: helpers.withMessage('Masukkan nama jurusan jika tidak ada pilihan', requiredIf(isOther(biodataForm.value.major)))
  },
  grade:  {...isRequired('Nilai Akhir'), ...isNumeric('IPK'), ...limitValue(0,maxValue.value)},
  graduation_year:  {
    ...isRequired('Tahun lulus'),
    ...isInteger(),
    ...limitCharacter('Tahun lulus', 4, 4)
  },
  cv_url:  {
    ...isRequired('CV'),
    ...isUrl()},
  skills: isRequired('Keahlian'),
  experience_level: isRequired('Pengalaman')
}))

const setOtherInstitution = () => {
  return biodataForm.value.university = 'LAINNYA'
}

const setOtherMajor = () => {
  return biodataForm.value.major = 'LAINNYA'
}

const isOther = (field) => {
  return !!field && field === 'LAINNYA'
}


const $vuelidateBiodata = useVuelidate(rulesBiodata, biodataForm);


const validateTab = (array) => {
  const valid = $vuelidateBiodata.value.$errors.map((el) => {
    return array.includes(el.$propertyPath)
  })
  return valid.every((el) => el === false)
}

const doSubmit = async () => {
  const validPersonal = await validateTab(['gender','religion','birth_date','city','phone_no'])
  const validEducation = await validateTab(['education','university','major','grade','graduation_year','cv_url','skills','experience_level'])
  const valid = await $vuelidateBiodata.value.$validate();
  if(!validPersonal && validEducation) {
    tab.value = 'personal'
  }
  if (validPersonal && !validEducation) {
    tab.value = 'edu_backgrounds'
  }
  if (validPersonal && validEducation && valid){
    try {
      emits("update:biodata")
    }
    catch (err) {
      notify.show({message: err.message, type:"error"})
    }
  }
}


onMounted(async () => {
  await fetchGender();
  fetchSkills();
  await fetchEducation();
})

</script>

<template>
  <q-card>
    <q-tabs
        align="justify"
        v-model="tab"
        inline-label
        indicator-color="grey"
        class="text-dark shadow-2"
    >
      <q-tab name="personal" icon="account_circle" label="Biodata Diri" class="text-h5 text-capitalize"/>
      <q-tab name="edu_backgrounds" icon="school" label="Latar Belakang Pendidikan" class="text-h5 text-capitalize"/>
    </q-tabs>
    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="personal">
        <q-card flat bordered>
          <q-card-section v-if="!props.isReadOnly">
            <div class="q-mt-md q-gutter-lg">
              <div class="row">
                <div id="input-name" class="col-6 q-pr-sm q-gutter-y-sm">
                  <label class="q-my-sm">Nama<span class="text-red">*</span></label>
                  <VInput
                      for="applicants-name-field"
                      v-model="biodataForm.name"
                      @blur="$vuelidateBiodata.name.$touch"
                      :errors="$vuelidateBiodata.name.$errors"
                      :readonly="isReadOnly || !!authState.user"
                  />
                </div>

                <div id="input-birth-date" class="col-6 q-pr-sm q-gutter-y-sm">
                  <label class="q-my-sm">Tanggal Lahir<span class="text-red">*</span></label>
                  <QDateInput
                      id="applicants-birth-date-field"
                      v-model="biodataForm.birth_date"
                      @blur="$vuelidateBiodata.birth_date.$touch"
                      :errors="$vuelidateBiodata.birth_date.$errors"
                      :readonly="isReadOnly"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-6 q-pr-sm q-gutter-y-sm">
                  <label class="q-my-sm">Jenis Kelamin<span class="text-red">*</span></label>
                  <q-option-group
                      :options="genderOptions"
                      v-model="biodataForm.gender"
                      inline
                      :disable="isReadOnly"
                  />
                  <div v-if="$vuelidateBiodata.gender.$errors[0]" class="text-small q-pl-sm" style="color: #c90014">
                    {{$vuelidateBiodata.gender.$errors[0].$message}}
                  </div>
                </div>
                <div class="col-6 q-pr-sm q-gutter-y-sm">
                  <label class="q-my-sm">Agama<span class="text-red">*</span></label>
                  <VPreloadedSelect
                      id="applicants-religion-option"
                      v-model="biodataForm.religion"
                      :fetch="fetchReligion"
                      :options="religionOptions"
                      :transform-value-key="'code'"
                      @blur="$vuelidateBiodata.religion.$touch"
                      :errors="$vuelidateBiodata.religion.$errors"
                      :readonly="isReadOnly"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-6 q-pr-sm q-gutter-y-sm">
                  <label class="q-my-sm">Alamat Domisili<span class="text-red">*</span></label>
                  <VInput
                      for="applicants-city-field"
                      v-model="biodataForm.city"
                      @blur="$vuelidateBiodata.city.$touch"
                      :errors="$vuelidateBiodata.city.$errors"
                      :readonly="isReadOnly"
                      type="textarea"
                  />
                </div>

                <div class="col-6 q-pr-sm q-gutter-y-sm">
                  <label class="q-my-sm">Nomor Telepon<span class="text-red">*</span></label>
                  <VInput
                      for="applicants-phone-field"
                      :prefix="kodeNegara"
                      v-model="biodataForm.phone_no"
                      @blur="$vuelidateBiodata.phone_no.$touch"
                      :errors="$vuelidateBiodata.phone_no.$errors"
                      :readonly="isReadOnly"/>
                </div>
              </div>
            </div>
          </q-card-section>
          <q-card-section class="row" v-else>
            <div class="col">
              <div class="row">
                <div class="col-3 text-bold align-content-center">
                  Nama
                </div>
                <div class="col-9">
                  <VInput
                      for="applicants-name-field"
                      v-model="biodataForm.name"
                      :readonly="true"
                      :outlined="false"
                      borderless
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-3 text-bold align-content-center">
                  Tanggal Lahir
                </div>
                <div class="col-9">
                  <QDateInput
                      id="applicants-birth-date-field"
                      v-model="biodataForm.birth_date"
                      :readonly="true"
                      :outlined="false"
                      borderless
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-3 text-bold align-content-center">
                  Jenis Kelamin
                </div>
                <div class="col-9">
                  <VInput
                      for="applicants-gender-field"
                      v-model="biodataForm.gender"
                      :model-value="biodataForm.gender === 'FEMALE' ? 'Wanita' : 'Pria'"
                      :readonly="true"
                      :outlined="false"
                      borderless
                  />
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-3 text-bold align-content-center">
                  Agama
                </div>
                <div class="col-9">
                  <VPreloadedSelect
                      id="applicants-religion-option"
                      v-model="biodataForm.religion"
                      :fetch="fetchReligion"
                      :options="religionOptions"
                      :transform-value-key="'code'"
                      :readonly="true"
                      :outlined="false"
                      borderless
                      dropdown-icon=""
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-3 text-bold align-content-center">
                  Alamat Domisili
                </div>
                <div class="col-9">
                  <VInput
                      for="applicants-city-field"
                      v-model="biodataForm.city"
                      :readonly="true"
                      :outlined="false"
                      borderless
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-3 text-bold align-content-center">
                  Nomor Telepon
                </div>
                <div class="col-9">
                  <VInput
                      for="applicants-phone-field"
                      :prefix="kodeNegara"
                      v-model="biodataForm.phone_no"
                      :readonly="true"
                      :outlined="false"
                      borderless
                  />
                </div>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-tab-panel>
      <q-tab-panel name="edu_backgrounds">
        <q-card flat bordered>
          <q-card-section v-if="!props.isReadOnly">
            <div class="q-mt-md q-gutter-lg">
              <div class="row">
                <div class="col-6 q-pr-sm q-gutter-y-sm">
                  <label class="q-my-sm">Nama Institusi<span class="text-red">*</span></label>
                  <q-select
                      id="applicants-institution-field"
                      input-debounce="300"
                      outlined dense use-input bottom-slots
                      v-model="biodataForm.university"
                      @filter="filterInstitutions"
                      :options="institutionOptions.value"
                      clearable
                      option-value="value"
                      option-label="label"
                      emit-value
                      map-options
                      :readonly="isReadOnly"
                      :label-slot="!biodataForm.university"
                      @blur="$vuelidateBiodata.university.$touch"
                      :error="!!$vuelidateBiodata.university.$errors?.length && $vuelidateBiodata.university.$errors?.length > 0"
                  >
                    <template v-slot:hint v-if="!biodataForm.university">
                      Ketik 'Universitas'
                    </template>
                    <template v-slot:label v-if="!biodataForm.university">
                      Cari Institusi Pendidikan Terakhir ...
                    </template>
                    <template v-slot:error>
                      <div role="alert" v-for="err in $vuelidateBiodata.university.$errors" :key="err.$uid">
                        {{ err?.$message }}
                      </div>
                    </template>
                    <template v-slot:no-option>
                      <q-item
                          clickable
                          v-ripple
                          @click="setOtherInstitution()"
                      >
                        <q-item-section class="text-grey">
                          Lainnya
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>

                <div class="col-6 q-pr-sm q-gutter-y-sm">
                  <label class="q-my-sm">Jurusan<span class="text-red">*</span></label>
                  <q-select
                      id="applicants-major-option"
                      input-debounce="300"
                      outlined
                      dense
                      use-input
                      v-model="biodataForm.major"
                      @filter="filterMajor"
                      :options="majorOptions.value"
                      clearable
                      bottom-slots
                      :label-slot="!biodataForm.major"
                      option-label="label"
                      option-value="value"
                      emit-value
                      map-options
                      :readonly="isReadOnly"
                      @blur="$vuelidateBiodata.major.$touch"
                      :error="!!$vuelidateBiodata.major.$errors?.length && $vuelidateBiodata.major.$errors?.length > 0"
                  >
                    <template v-slot:hint v-if="!biodataForm.major">
                      Ketik 'Teknik'
                    </template>
                    <template v-slot:label v-if="!biodataForm.major">
                      Cari Jurusan ...
                    </template>
                    <template v-slot:error>
                      <div role="alert" v-for="err in $vuelidateBiodata.major.$errors" :key="err.$uid">
                        {{ err?.$message }}
                      </div>
                    </template>
                    <template v-slot:no-option>
                      <q-item
                          clickable
                          v-ripple
                          @click="setOtherMajor()"
                      >
                        <q-item-section class="text-grey">
                          Lainnya
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>
              </div>

              <div class="row" v-if="isOther(biodataForm.university) || isOther(biodataForm.major)">
                <div class="col-6 q-pr-sm q-gutter-y-sm">
                  <div v-if="isOther(biodataForm.university)">
                    <label class="q-my-sm"> Institusi Lainnya
                      <span class="text-red">*</span></label>
                    <VInput
                        for="applicants-university-other-field"
                        v-model="biodataForm.university_other"
                        @blur="$vuelidateBiodata.university_other.$touch"
                        :errors="$vuelidateBiodata.university_other.$errors"
                        :readonly="isReadOnly"
                    />
                  </div>
                </div>

                <div class="col-6 q-pr-sm q-gutter-y-sm" >
                  <div v-if="isOther(biodataForm.major)">
                    <label class="q-my-sm"> Jurusan Lainnya
                      <span class="text-red">*</span></label>
                    <VInput
                        for="applicants-major-other-field"
                        v-model="biodataForm.major_other"
                        @blur="$vuelidateBiodata.major_other.$touch"
                        :errors="$vuelidateBiodata.major_other.$errors"
                        :readonly="isReadOnly"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6 q-pr-sm q-gutter-y-sm">
                  <label class="q-my-sm">Jenjang<span class="text-red">*</span></label>
                  <VPreloadedSelect
                      id="applicants-education-option"
                      v-model="biodataForm.education"
                      :fetch="fetchEducation"
                      :options="educationOptions"
                      :transform-value-key="'code'"
                      @blur="$vuelidateBiodata.education.$touch"
                      :errors="$vuelidateBiodata.education.$errors"
                      :readonly="isReadOnly"
                  />
                </div>
                <div class="col-6 q-pr-sm q-gutter-y-sm">
                  <label class="q-my-sm">
                    {{!biodataForm.education ? 'Nilai Akhir' : (biodataForm.education.includes('SMA') || biodataForm.education.includes('SMK')) ? 'NEM' : 'IPK'}}
                    <span class="text-red">*</span></label>
                  <VInput
                      for="applicants-grade-field"
                      v-model="biodataForm.grade"
                      @blur="$vuelidateBiodata.grade.$touch"
                      :errors="$vuelidateBiodata.grade.$errors"
                      :readonly="isReadOnly || !biodataForm.education"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6 q-pr-sm q-gutter-y-sm">
                  <label class="q-my-sm">Level Pengalaman<span class="text-red">*</span></label>
                  <VPreloadedSelect
                      id="applicants-experience-option"
                      v-model="biodataForm.experience_level"
                      :fetch="fetchExperience"
                      :options="experienceOptions"
                      :transform-value-key="'code'"
                      @blur="$vuelidateBiodata.experience_level.$touch"
                      :errors="$vuelidateBiodata.experience_level.$errors"
                      :readonly="isReadOnly"
                  />
                </div>

                <div class="col-6 q-pr-sm q-gutter-y-sm">
                  <label class="q-my-sm">Tahun Lulus<span class="text-red">*</span></label>
                  <VInput
                      for="applicants-graduation-year-field"
                      v-model="biodataForm.graduation_year"
                      @blur="$vuelidateBiodata.graduation_year.$touch"
                      :errors="$vuelidateBiodata.graduation_year.$errors"
                      :readonly="isReadOnly"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6 q-pr-sm q-gutter-y-sm">
                  <label class="q-my-sm">Kemampuan Teknis<span class="text-red">*</span></label>
                  <q-select
                      multiple
                      :use-chips="!isReadOnly"
                      id="applicants-skill-options"
                      input-debounce="300"
                      outlined
                      dense
                      use-input
                      v-model="biodataForm.skills"
                      @filter="filterSkills"
                      :options="skillOptions.value"
                      clearable
                      bottom-slots
                      option-label="label"
                      option-value="value"
                      emit-value
                      map-options
                      :label-slot="!biodataForm.skills"
                      :readonly="isReadOnly"
                      @blur="$vuelidateBiodata.skills.$touch"
                      :error="!!$vuelidateBiodata.skills.$errors?.length && $vuelidateBiodata.skills.$errors?.length > 0"
                  >
                    <template v-slot:hint v-if="!biodataForm.skills">
                      Ketik : Laravel
                    </template>
                    <template v-slot:label v-if="!biodataForm.skills">
                      Pilih satu atau lebih kemampuan teknis...
                    </template>
                    <template v-slot:error>
                      <div role="alert" v-for="err in $vuelidateBiodata.skills.$errors" :key="err.$uid">
                        {{ err?.$message }}
                      </div>
                    </template>
                  </q-select>
                </div>

                <div class="col-6 q-pr-sm q-gutter-y-sm">
                  <label class="q-my-sm">CV URL<span class="text-red">*</span></label>
                  <VInput
                      for="applicants-cv-field"
                      v-model="biodataForm.cv_url"
                      @blur="$vuelidateBiodata.cv_url.$touch"
                      :errors="$vuelidateBiodata.cv_url.$errors"
                      :readonly="isReadOnly"
                  />
                </div>
              </div>
            </div>
          </q-card-section>
          <q-card-section class="row" v-else>
            <div class="col">
              <div class="row">
                <div class="col-3 text-bold align-content-center">
                  Nama Institusi
                </div>
                <div class="col-9">
                  <q-select
                      id="applicants-institution-field"
                      v-model="biodataForm.university"
                      :options="institutionOptions.value"
                      option-value="value"
                      option-label="label"
                      emit-value
                      map-options
                      :readonly="true"
                      :outlined="false"
                      borderless
                      dropdown-icon=""
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-3 text-bold align-content-center">
                  Jurusan
                </div>
                <div class="col-9">
                  <q-select
                      id="applicants-major-option"
                      v-model="biodataForm.major"
                      :options="majorOptions.value"
                      option-label="label"
                      option-value="value"
                      emit-value
                      map-options
                      :readonly="true"
                      :outlined="false"
                      borderless
                      dropdown-icon=""
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-3 text-bold align-content-center">
                  Level Pengalaman
                </div>
                <div class="col-9">
                  <VPreloadedSelect
                      id="applicants-experience-option"
                      v-model="biodataForm.experience_level"
                      :fetch="fetchExperience"
                      :options="experienceOptions"
                      :transform-value-key="'code'"
                      :readonly="true"
                      :outlined="false"
                      borderless
                      dropdown-icon=""
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-3 text-bold align-content-center">
                  Kemampuan Teknis
                </div>
                <div class="col-9">
                  <q-select
                      multiple
                      :use-chips="!isReadOnly"
                      id="applicants-skill-options"
                      v-model="biodataForm.skills"
                      :options="skillOptions.value"
                      option-label="label"
                      option-value="value"
                      emit-value
                      map-options
                      :readonly="true"
                      :outlined="false"
                      borderless
                      dropdown-icon=""
                  />
                </div>
              </div>
            </div>
            <div class="col q-gutter-y-md">
              <div class="row">
                <div class="col-3 text-bold align-content-center">
                  Jenjang
                </div>
                <div class="col-9">
                  <VPreloadedSelect
                      id="applicants-education-option"
                      v-model="biodataForm.education"
                      :fetch="fetchEducation"
                      :options="educationOptions"
                      :transform-value-key="'code'"
                      :readonly="true"
                      :outlined="false"
                      borderless
                      dropdown-icon=""
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-3 text-bold">
                  {{!biodataForm.education ? 'Nilai Akhir' : (biodataForm.education.includes('SMA') || biodataForm.education.includes('SMK')) ? 'NEM' : 'IPK'}}
                </div>
                <div class="col-9">
                  {{ biodataForm.grade }}
                </div>
              </div>
              <div class="row">
                <div class="col-3 text-bold">
                  Tahun Lulus
                </div>
                <div class="col-9">
                  {{ biodataForm.graduation_year }}
                </div>
              </div>
              <div class="row">
                <div class="col-3 text-bold">
                  Curriculum Vitae
                </div>
                <div class="col-9">
                  <a :href="biodataForm.cv_url" target="_blank">Link</a>
                </div>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-tab-panel>
    </q-tab-panels>

    <div class="q-pa-sm">
      <div class="row">
        <div class="col-12" align="right">
          <q-btn
              v-if="!isReadOnly"
              unelevated
              color="primary"
              class="float-right"
              :label="SAVE_CONFIRMATION_LABELS"
              @click="doSubmit">
          </q-btn>
        </div>
      </div>
    </div>
  </q-card>
</template>
<style scoped>

</style>
