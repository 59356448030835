import axios from "axios";
import useAuthStore from "../stores/auth.js";
import router from "@/router/index.js";

const baseUrl = import.meta.env.VITE_BE_URL;
const getHeaders = ({
                        isNeedAuthorization,
                        isMultipartForm
                    }) => {
    let obj = {};
    const useAuth = useAuthStore();
    const token = useAuth.token;
    if (isMultipartForm) {
        obj['Content-Type'] = 'multipart/form-data'
    }
    ;
    if (isNeedAuthorization) {
        obj.Authorization = `Bearer ${token}`;
    }
    return obj;
}
export const get = ({
                        path,
                        params,
                        isNeedAuthorization = true,
                        isMultipartform = false
                    }) => {
    return new Promise((resolve, reject) => {
        axios({
            baseURL: baseUrl,
            url: path,
            method: 'get',
            params,
            headers: getHeaders({isNeedAuthorization, isMultipartform})
        })
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                }
            })
            .catch(async error => {
                if (error.response?.data?.message === 'Invalid access token') {
                    await doLogout()
                }
                reject(error.response?.data);
            });
    });
};

export const post = ({
                         path,
                         params, isNeedAuthorization = true,
                         isMultipartForm = false
                     }) => {
    return new Promise((resolve, reject) => {
        axios({
            baseURL: baseUrl,
            url: path,
            method: 'post',
            data: params,
            headers: getHeaders({isNeedAuthorization, isMultipartForm})
        })
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                }
                throw new Error('Terjadi kesalahan');
            })
            .catch(async error => {
                if (error.response?.data?.message === 'Invalid access token') {
                    await doLogout()
                }
                reject(error.response?.data);
            });
    })
}

export const put = ({
                         path,
                         params, isNeedAuthorization = true,
                         isMultipartForm = false
                     }) => {
    return new Promise((resolve, reject) => {
        axios({
            baseURL: baseUrl,
            url: path,
            method: 'put',
            data: params,
            headers: getHeaders({isNeedAuthorization, isMultipartForm})
        })
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                }
                throw new Error('Terjadi kesalahan');
            })
            .catch(async error => {
                if (error.response?.data?.message === 'Invalid access token') {
                    await doLogout()
                }
                reject(error.response?.data);
            });
    })
}

export const del = ({
                           path,
                           params,
                           isNeedAuthorization = true,
                           isMultipartform = false
                       }) => {
    return new Promise((resolve, reject) => {
        axios({
            baseURL: baseUrl,
            url: path,
            method: 'delete',
            params,
            headers: getHeaders({isNeedAuthorization, isMultipartform})
        })
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                }
            })
            .catch(async error => {
                if (error.response?.data?.message === 'Invalid access token') {
                    await doLogout()
                }
                reject(error.response?.data);
            });
    });
};

export const doLogout = async () => {
    const authState = useAuthStore();
    authState.setState(null, null, null, null);
    await router.push({name: 'LoginPage'})
}
