<script setup lang="ts">
</script>

<template>
  <q-layout>
    <q-page-container>
      <q-page padding class="flex justify-center items-start">
        <slot />
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style scoped></style>
