<script setup>
const label = 'Kembali'
</script>

<template>
  <q-btn
      :label="label"
      icon="chevron_left"
      color="grey-5"
      unelevated
      size="md"
      :title="label"
      v-bind="$attrs"
  >
    <!--    <q-inner-loading :showing="loading" size="xs" color="primary" />-->
  </q-btn>
</template>

<style scoped></style>
