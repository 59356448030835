<template>
  <q-btn
      :icon="icon"
      :color="color"
      size="sm"
      :title="title"
      v-bind="{...$attrs}"
  ></q-btn>
</template>

<script>
export default {
  name: "QActionButton",
  props: {
    title: {
      default: 'Aksi'
    },
    color: {
      default: 'orange'
    },
    icon: {
      default: 'assignment'
    }
  }
}
</script>

<style scoped>

</style>