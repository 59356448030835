<script setup lang="ts">
</script>

<template>
  <div class="text-center" style="margin-top: 10vh">
    <q-icon name="warning" size="100px" color="black"  />
    <h1 class="text-h4">Halaman Tidak Ditemukan</h1>
    <p style="font-size: 16px;">Kemungkinan halaman telah di hapus, atau Anda salah menulis URL.</p>
  </div>
</template>

<style scoped></style>
