<script setup>

import AppPage from "@/components/AppPage.vue";
import useAuthStore from "@/stores/auth.js";

const authState = useAuthStore();

</script>

<template>
  <AppPage title="Beranda" disable-bread-crumb disable-slot-margin>
    <q-card-section v-if="!authState.isPelamar">
      <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed elementum, mi in varius malesuada, justo massa scelerisque dolor, ut porttitor urna leo et purus. Sed maximus sed magna eget molestie. Mauris est nunc, pellentesque vitae nunc eu, pulvinar malesuada ligula. Vestibulum quis nulla ac turpis luctus commodo. Nunc nibh ipsum, fringilla vitae ex vestibulum, cursus suscipit libero. Nullam nec euismod ex, a sollicitudin ante. In tincidunt neque vel sem semper, non tempus nulla finibus. Aliquam ullamcorper, sapien quis dapibus lobortis, odio odio porttitor sem, in dapibus metus massa vitae elit. Nulla vel mauris eros. Donec non massa vehicula, vulputate turpis eu, convallis dui. Pellentesque sit amet sem turpis. Suspendisse potenti. Nunc lacus orci, blandit ac nunc a, egestas euismod ex.
      </div>
    </q-card-section>
    <q-card-section class="text-center" v-else>
      <img :src="'tutorial_mendaftar_1.png'" :alt="'tutorial_ars'" style="max-width: 65%">
    </q-card-section>
  </AppPage>
</template>
