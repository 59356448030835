export const currencyFormat = (value) => {
    const newValue = Math.floor(value)
    return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
    }).format(newValue);
}

export const setIsActiveLabel = (value) => {
    switch (value) {
        case 't':
            return 'Aktif'
        case 'f':
            return 'Tidak Aktif'
    }
}

export const parseNestedJson = (str) =>  {
    try {
        return JSON.parse(str, (_, val) => {
            if (typeof val === 'string')
                return parseNestedJson(val)
            return val
        })
    } catch (exc) {
        return str
    }
}
