<script setup>
import VInput from "@/components/input/VInput.vue";
import {computed, ref} from "vue";
import {isRequired, limitCharacter, isEmail, isSame} from "@/lib/ValidationHelper.js"
import useVuelidate from "@vuelidate/core";

const isPwd = ref({
  pwd: true,
  confirmPwd: true
})

const props = defineProps({
  isReadOnly: Boolean,
  title: String,
  form: {
    email: String,
    password: String,
    confirm_password: String,
    name: String,
  }
})

const emits = defineEmits(['update:form']);

const model = computed({
  get(){return props.form},
  set(val){emits('update:form',val)}
})

const rulesAccount = computed(() => ({
  email:  {
    ...isRequired('Email'),
    ...isEmail()},
  password: isRequired('Password'),
  confirm_password: {
    ...isRequired('Konfirmasi password'), ...isSame(model.value.password)},
  name: {
    ...isRequired('Nama'),
    ...limitCharacter('Nama',3)
  },
}))

const $vuelidateAccount = useVuelidate(rulesAccount,model);

</script>

<template>
  <q-card flat bordered
          v-bind="{...$props}"
  >
    <q-card-section>
      <h6>{{title}}</h6>
      <div class="q-mt-md q-gutter-lg">
        <div class="row">
          <div class="col-6 q-pr-sm q-gutter-y-sm">
            <label class="q-my-sm">Nama<span class="text-red">*</span></label>
            <VInput
                v-model="form.name"
                @blur="$vuelidateAccount.name.$touch"
                :errors="$vuelidateAccount.name.$errors"
                :readonly="isReadOnly"
            />
          </div>

          <div class="col-6 q-pr-sm q-gutter-y-sm">
            <label class="q-my-sm">Email<span class="text-red">*</span></label>
            <VInput
                v-model="form.email"
                type="email"
                @blur="$vuelidateAccount.email.$touch"
                :errors="$vuelidateAccount.email.$errors"
                :readonly="isReadOnly"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-6 q-pr-sm q-gutter-y-sm">
            <label class="q-my-sm">Password<span class="text-red">*</span></label>
            <VInput
                :type="isPwd.pwd ? 'password' : 'text'"
                v-model="form.password"
                @blur="$vuelidateAccount.password.$touch"
                :errors="$vuelidateAccount.password.$errors"
                :readonly="isReadOnly"
            >
              <template v-slot:prepend>
                <q-icon
                    :name="isPwd.pwd ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="!isReadOnly ? isPwd.pwd = !isPwd.pwd : isPwd.pwd === true"
                />
              </template>
            </VInput>
          </div>
          <div class="col-6 q-pr-sm q-gutter-y-sm">
            <label class="q-my-sm">Konfirmasi Password<span class="text-red">*</span></label>
            <VInput
                :type="isPwd.confirmPwd ? 'password' : 'text'"
                v-model="form.confirm_password"
                @blur="$vuelidateAccount.confirm_password.$touch"
                :errors="$vuelidateAccount.confirm_password.$errors"
                :readonly="isReadOnly"
            >
              <template v-slot:prepend>
                <q-icon
                    :name="isPwd.confirmPwd ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="!isReadOnly ? isPwd.confirmPwd = !isPwd.confirmPwd : isPwd.confirmPwd === true"
                />
              </template>
            </VInput>
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<style scoped></style>
