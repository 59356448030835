<script setup>
import AppPage from "@/components/AppPage.vue";
import AppTable from "@/components/AppTable.vue";
import {computed, ref} from "vue";
import {deleteMasterQuestion, getListMasterQuestion} from "@/lib/api.js";
import useLoader from "@/plugins/loader/index.js";
import useNotify from "@/plugins/notify/index.js";
import QEditButtonGroup from "../../components/button/QEditButtonGroup.vue";
import QDeleteButtonGroup from "../../components/button/QDeleteButtonGroup.vue";
import Forms from "@/pages/questionBank/Forms.vue";
import useConfirmation from "@/plugins/confirmation/index.js";

const table = ref(null);
const title = 'Bank Soal';
const rows = ref([]);
const notify = useNotify();
const loader = useLoader();
const keyword = ref('');
const showForms = ref(false);
const mode = ref('create');
const countKey = ref(1);
const confirm = useConfirmation();

const reset = () => {
  table.value.reset();
}

const form = ref({
  id: null,
  title: null,
  question: null,
  type: null,
  options: [],
  category: 'UMUM'
})

const fetchListQuestionBank = (offset, limit) => {
  const params = {
    offset,
    limit,
    keyword: keyword.value,
    order: 'desc',
  }

  return getListMasterQuestion(params)
      .then((response) => {
        return {
          rows: response.data,
          total: response.total,
        }
      }).catch((error) => {
        notify.show({message: error.message, type: 'error'})
      }).finally(() => {
        loader.hide()
      })
};

const handleShowEdit = (row) => {
  countKey.value += 1;
  mode.value = 'edit';

  form.value.id = row.id;
  form.value.title = row.title;
  form.value.question = row.question;
  form.value.type = row.type;
  form.value.options = JSON.parse(row.options);
  form.value.category = row.category;

  showForms.value = true;
}

const handleTambah = () => {
  countKey.value += 1;
  mode.value = 'create';
  showForms.value = true;
}

const handleDelete = (id) => {
  confirm
      .setTitle('Konfirmasi')
      .setMessage('Apakah anda yakin akan menghapus data ini?')
      .setOnConfirm(() => {
        deleteMasterQuestion(id)
            .then((res) => {
              notify.show({message: res.message, type: 'success'})
            }).finally(() => { reset(); })
      }).show();
}

const columns = computed(() => {
  return [
    {
      label: 'No',
      name: 'no',
      align: 'center',
      headerStyle: 'width: 1rem'
    },
    {
      label: 'Judul',
      name: 'title',
      field: 'title',
      align: 'left'
    },
    {
      label: "Pertanyaan",
      name: "question",
      field: "question",
      align: "left"
    },
    {
      label: 'Tipe',
      name: 'type',
      field: 'type',
      align: 'left'
    },
    {
      label: 'Aksi',
      name: 'action',
      field: 'action',
      align: 'center',
      headerStyle: 'width: 10rem'

    }
  ]
});

</script>

<template>
  <AppPage
      :title="title"
  >
    <div class="flex justify-end q-mb-md">
      <q-btn id="btn-add-job-vacancy" icon="add" unelevated label="Tambah" color="primary" @click="handleTambah"/>
    </div>
    <AppTable
        ref="table"
        :columns="columns"
        v-model:rows="rows"
        :fetch="fetchListQuestionBank"
    >
      <template v-slot:body-cell-no="props">
        <q-td :props="props">
          {{ props.rowIndex + 1 }}
        </q-td>
      </template>
      <template v-slot:body-cell-action="props">
        <q-td :props="props" class="q-gutter-x-xs">
          <QEditButtonGroup @click="handleShowEdit(props.row)"/>
          <QDeleteButtonGroup @click="handleDelete(props.row.id)"/>
        </q-td>
      </template>
    </AppTable>
    <forms
        ref="modal-form"
        :mode="mode"
        :form="form"
        :show-dialog="showForms"
        @update:show-modal="val => showForms = val"
        @update:form="reset"
    ></forms>
  </AppPage>
</template>

<style scoped></style>
