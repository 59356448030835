<script setup>
import {useRoute, useRouter} from "vue-router";

const router = useRouter();

const goBack = () => {
  router.go(-1)
}
</script>

<template>
  <div class="text-center" style="margin-top: 10vh">
    <q-icon name="warning" size="100px" color="black"  />
    <h1 class="text-h4">Akses ditolak</h1>
    <p style="font-size: 16px;">Anda tidak diizinkan untuk mengakses halaman ini.</p>

    <div class="q-ma-lg">
      <q-btn class="bg-black" label="Kembali" text-color="white" @click="goBack"></q-btn>
    </div>
  </div>
</template>

<style scoped></style>
