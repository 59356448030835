<script setup>
import { computed, ref } from "vue";
import {cleanValue} from "@/components/input/vCurrencyInput/cleanValue.js";
import {formatValue} from "@/components/input/vCurrencyInput/formatValue.js";
import {isNumber} from "@/components/input/vCurrencyInput/isNumber.js";
const props = defineProps({
  modelValue: Number,
  placeholder: String,
  label: String,
  prefix: String,
  suffix: String,
  errors: Array,
  disable: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  outlined: {
    type: Boolean,
    default: true
  },
  withNegativeNumber: {
    type: Boolean,
    default: true
  },
  groupSeparator: {
    type: String,
    default: '.'
  },
  decimalSeparator: {
    type: String,
    default: ','
  },
  intlConfig: {
    type: Function,
    default: () => ({
      locale: 'id-ID',
      currency: 'IDR'
    })
  },
  decimalsLimit: {
    type: Number,
    default: 0
  },
  allowDecimals: {
    type: Boolean,
    default: false
  },
  stackLabel: {
    type: Boolean,
    default: false
  },
  maxLength: {
    type: Number,
    default: 20
  }
})

const emits = defineEmits(['update:modelValue']);
const isError = computed(() => !!props.errors && props.errors.length > 0);
const isNegativeNumber = computed(() => Number(props.modelValue) < 0)
const isFocus = ref(false);
const defCleanValue = (value) => {
  return cleanValue({
    value: String(value),
    decimalsLimit: props.decimalsLimit,
    allowDecimals: props.allowDecimals
  })
}

const defFormatValue = (value) => {
  const { decimalSeparator, decimalsLimit, groupSeparator, intlConfig, prefix, suffix } = props;
  return formatValue({
    decimalScale: decimalsLimit,
    value,
    decimalSeparator,
    groupSeparator,
    disableGroupSeparators: false,
    intlConfig,
    prefix,
    suffix
  });
};

const renderedValue = computed(() => {
  if (props.modelValue == null) {
    return 0;
  }
  if (isFocus.value) {
    return defCleanValue(String(props.modelValue));
  }
  return defFormatValue(String(props.modelValue));
});

const handleOnFocus = () => {
  if (!props.readonly) {
    isFocus.value = true;
  }
};

const handleOnBlur = () => {
  isFocus.value = false;
};

const handleOnChange = (event) => {
  const { target } = event;
  const { value } = target;
  const clean = defCleanValue(value);
  if (clean && isNumber(clean)) {
    emits('update:modelValue', Number(clean));
  }
};
</script>

<template>
  <q-field
      borderless
      dense
      hide-bottom-space
      no-error-icon
      :error="isError"
      :readonly="readonly"
      :disable="disable"
      :outlined="outlined"
      :label="label"
      :stack-label="stackLabel"

  >
    <template v-slot:control="{ id }">
      <input
          type="text"
          inputmode="decimal"
          class="q-field__input text-left"
          :class="{ 'text-red': withNegativeNumber && !isFocus && isNegativeNumber }"
          :id="`currency-input-${id}`"
          :value="renderedValue"
          :placeholder="placeholder"
          :readonly="readonly"
          @focus="handleOnFocus"
          @blur="handleOnBlur"
          @change="handleOnChange"
          :maxlength="maxLength"
      />
    </template>
    <template v-slot:error>
      <slot name="error">
        <template v-if="!!errors?.length && errors?.length > 0">
          <div role="alert" v-for="err in errors" :key="err.$uid">
            {{ err?.$message }}
          </div>
        </template>
      </slot>
    </template>
  </q-field>
</template>
