import {createRouter, createWebHistory} from "vue-router";
import publicRoutes from "./public-routes.js";
import NotFoundPage from "../pages/errors/NotFoundPage.vue";
import useAuthStore from "../stores/auth.js";
import privateRoutes from "./private-routes.js";
import Unauthorized from "@/pages/errors/Unauthorized.vue";

const routes = [
    ...publicRoutes,
    ...privateRoutes,
    {
        path: '/:pathMatch(.*)*',
        component: NotFoundPage,
        name: 'not-found'
    },
    {
        path: '/unauthorized',
        component: Unauthorized,
        name: 'unauthorized'
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const auth = useAuthStore()
    if (to.matched.some(record => !!record.meta.requireRoles)) {
        const guardedRoutes = to.matched.filter(record => !!record.meta.requireRoles);
        let invalid = true;
        guardedRoutes.forEach((gr) => {
            gr.meta.requireRoles.forEach(roles => {
                if(auth.roles && auth.roles.includes(roles)) {
                    invalid = false;
                }
            })
        })
        if(invalid) {
            next({
                name: 'unauthorized'
            })
        }
    }
    if (to.meta.isNeedAuthentication && !auth.isAuthenticated && to.name !== 'LoginPage') {
        next({
            name: 'LoginPage'
        })
        return;
    }
    if (auth.isAuthenticated && to.name === 'LoginPage') {
        next({
            name: 'HomePage'
        })
    }
    next();
})

export default router;
