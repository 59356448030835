<script setup>
import {useRoute, useRouter} from 'vue-router';
import {computed, onMounted, ref} from 'vue';
import AppPage from '@/components/AppPage.vue';
import VInput from '@/components/input/VInput.vue';
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';
import {
  getDetailReferencesMaster,
  postReferencesMaster,
  updateReferencesMaster
} from '@/lib/api.js';
import useNotify from '@/plugins/notify/index.js';
import useLoader from '@/plugins/loader/index.js';
import {MESSAGE_SUCCESS_CREATED, MESSAGE_SUCCESS_UPDATED} from '@/constants/default-messages.js';

const notify = useNotify();
const route = useRoute();
const router = useRouter();
const action = ref(route?.params?.action || 'create');

const pageTitle = computed(() => {
  switch (action.value) {
    case 'create':
      return 'Buat References Baru';
    case 'update':
      return 'Ubah References';
    case 'view':
      return 'Detil References';
    case 'edit':
      return 'Edit References';
  }
  return '';
});

const isReadOnly = computed(() => action.value === 'view');
const isEdit = computed(() => action.value === 'edit');

const loader = useLoader();
const referenceId = ref(route?.params?.id || null);

const form = ref({
  id: null,
  type: null,
  code: null,
  name: null,
  description: null,
});

const rules = computed(() => ({
  type: {required},
  code: {required},
  name: {required},
  description: {required},
}));

const v$ = useVuelidate(rules, form);

const redirectBack = () => {
  router.push({name: 'referenceMaster'});
};

const handleSubmit = async () => {
  const valid = await v$.value.$validate();
  if (!valid) return;

  loader.show();
  const data = {
    id: form.value.id,
    type: form.value.type,
    code: form.value.code,
    name: form.value.name,
    description: form.value.description,
  };

  if (action.value === 'create') {
    handlePostReference(data);
  } else {
    handleUpdateReference(data);
  }
};

const handlePostReference = (data) => {
  postReferencesMaster(data)
      .then(() => {
        notify.show({message: MESSAGE_SUCCESS_CREATED, type: 'success'});
        redirectBack();
      })
      .catch((err) => {
        notify.show({message: err.message, type: 'error'});
      })
      .finally(() => {
        loader.hide();
      });
};

const handleUpdateReference = (data) => {
  updateReferencesMaster(referenceId.value, data)
      .then(() => {
        notify.show({message: MESSAGE_SUCCESS_UPDATED, type: 'success'});
        redirectBack();
      })
      .catch((err) => {
        notify.show({message: err.message, type: 'error'});
      })
      .finally(() => {
        loader.hide();
      });
};

const fetchDetailReference = async (id) => {
  try {
    loader.show();
    const data = await getDetailReferencesMaster(referenceId.value ?? id);
    form.value = data;
  } catch (error) {
    notify.show({message: error.message, type: 'error'});
  } finally {
    loader.hide();
  }
};

onMounted(async () => {
  if (action.value !== 'create') {
    await fetchDetailReference();
  }
});
</script>

<template>
  <AppPage :title="pageTitle">
    <template v-slot:default>
      <q-card flat>
        <q-card-section>
          <div class="q-gutter-lg q-mt-md">
            <div class="row q-col-gutter-md">
              <div class="col-4">
                <label class="q-my-sm text-label">Type <span class="text-red">*</span></label>
                <VInput
                    v-model="form.type"
                    :readonly="isReadOnly || isEdit"
                />
              </div>
              <div class="col-4">
                <label class="q-my-sm text-label">Code <span class="text-red">*</span></label>
                <VInput
                    v-model="form.code"
                    :readonly="isReadOnly || isEdit"
                />
              </div>
              <div class="col-4">
                <label class="q-my-sm text-label">Value <span class="text-red">*</span></label>
                <VInput
                    v-model="form.name"
                    :readonly="isReadOnly"
                />
              </div>
            </div>
            <div class="row q-col-gutter-md">
              <div class="col-12">
                <label class="q-my-sm text-label">Description <span class="text-red">*</span></label>
                <VInput
                    v-model="form.description"
                    :readonly="isReadOnly"
                />
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <div v-if="action === 'create'">
        <div class="flex justify-end q-mt-md q-gutter-x-xs">
          <q-btn label="Batal" unelevated flat @click="redirectBack"/>
          <q-btn label="Simpan" color="primary" unelevated @click="handleSubmit"/>
        </div>
      </div>
      <div v-else>
        <div class="flex justify-end q-mt-md q-gutter-x-xs">
          <q-btn label="Kembali" color="secondary" unelevated @click="redirectBack"/>
          <q-btn label="Simpan" color="orange" unelevated @click="handleSubmit"/>
        </div>
      </div>
    </template>
  </AppPage>
</template>

<style scoped>
.text-label {
  font-weight: bold;
}
</style>
