<script setup>
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref, watch} from "vue";
import AppPage from "@/components/AppPage.vue";
import QDateInput from "@/components/input/VDateInput.vue";
import VInput from "@/components/input/VInput.vue";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {columnsStateFlows} from "@/pages/jobMgmt/jobVacancy/job-vacancy.js";
import VCurrencyInput from "@/components/input/vCurrencyInput/VCurrencyInput.vue";
import useNotify from "@/plugins/notify/index.js";
import {
  generateJobCode,
  getDetailJobVacancy,
  getListState,
  getMasterReferenceByType,
  getPositionList, getQuestionBank,
  postJobVacancy, searchJobVacancy, updateJobVacancy
} from "@/lib/api.js";
import {MESSAGE_SUCCESS_CREATED, MESSAGE_SUCCESS_UPDATED} from "@/constants/default-messages.js";
import VPreloadedSelect from "@/components/input/VPreloadedSelect.vue";
import {EMPLOYMENT_TYPE, WORKPLACE_TYPE} from "@/constants/reference.js";
import dayjs from "@/lib/DateHelper.js";
import useLoader from "@/plugins/loader/index.js";
import draggable from 'vuedraggable';
import {getSkills} from "@/lib/api.js";

const notify = useNotify();
const route = useRoute();
const router = useRouter();
const action = ref(route?.params?.action || 'create');

const pageTitle = computed(() => {
  switch (action.value) {
    case 'create':
      return 'Buat Lowongan Pekerjaan';
    case 'update':
      return 'Ubah Lowongan Pekerjaan';
    case 'view':
      return 'Detil Lowongan Pekerjaan';
    case 'edit':
      return 'Edit Lowongan Pekerjaan';
  }
  return '';
})

const isReadOnly = computed(() => {
  return action.value === 'view'
})

const selectedStep = ref([]);

const loader = useLoader();

const positionOptions = ref([]);
const workplaceTypeOptions = ref([]);
const employmentTypeOptions = ref([]);
const jobReferenceOptions = ref([]);
const jobReferenceId = ref(null);

const vacancyId = ref(route?.params?.id || null);

const form = ref({
  job_title: null,
  position_id: null,
  location: null,
  workplace_type: null,
  employment_type: null,
  min_salary: 0,
  max_salary: 0,
  responsibilities: '',
  requirements: '',
  skills: [],
  start_at: null,
  end_date: null,
  description: '',
  job_code: null,
  state_flows: []
})

const rows = computed(() => {
  if (form.value.state_flows.length > 0) {
    return form.value.state_flows
  }
  return []
})

const rules = {
  job_title: {required},
  position_id: {required},
  location: {required},
  workplace_type: {required},
  employment_type: {required},
  min_salary: {required},
  max_salary: {required},
  responsibilities: {required},
  requirements: {required},
  skills: {required},
  start_at: {required},
  end_date: {required},
  description: {required},
  job_code: {required}
}

const id = ref(null);

const skillOptions = ref([]);
const questionOptions = ref([]);

const getStateFlowValue = () => {
  const rows = [
    ...fixedRows.value,
    ...draggableRows.value
  ];

  return rows.filter(item => item.active).map((item, index) => ({
    state_id: item.id,
    display_order: String(index + 1),
    question_bank_id: item.question_bank_id
  }));
}

const $vuelidate = useVuelidate(rules, computed(() => form.value));

const redirectBack = () => {
  router.push({
    name: 'JobVacancy'
  })
}

const fixedRows = ref([]);
const draggableRows = ref([]);

const handleSubmit = async () => {
  const valid = await $vuelidate.value.$validate();
  if (!valid) return;

  if (form.value.min_salary > form.value.max_salary) {
    notify.show({
      message: 'The minimum salary cannot exceed the maximum salary.',
      type: 'warning'
    })
    return;
  }

  let data = getJobVacancyData();
  loader.show();
  if(action.value !== 'edit') {
    handlePostJobVacancy(data);
  } else {
    data = getJobVacancyDataUpdate()
    handleUpdateJobVacancy(data)
  }
}

const handlePostJobVacancy = (data) => {
  postJobVacancy(data)
      .then(() => {
        notify.show({
          message: MESSAGE_SUCCESS_CREATED,
          type: 'success'
        })
        redirectBack();
      })
      .catch((err) => {
        notify.show({
          message: err.message,
          type: 'error'
        })
      })
      .finally(() => {
        loader.hide();
      })
}

const handleUpdateJobVacancy = (data) => {
  updateJobVacancy(id.value, data)
      .then(() => {
        notify.show({
          message: MESSAGE_SUCCESS_UPDATED,
          type: 'success'
        })
        redirectBack();
      })
      .catch((err) => {
        notify.show({
          message: err.message,
          type: 'error'
        })
      })
      .finally(() => {
        loader.hide();
      })
}

const getJobVacancyData = () => {
  return {
    ...form.value,
    start_at: dayjs(form.value.start_at, 'DD-MM-YYYY').format('YYYY-MM-DD'),
    end_date: dayjs(form.value.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
    workplace_type: form.value.workplace_type.label ? form.value.workplace_type.label : form.value.workplace_type,
    employment_type: form.value.employment_type.label ? form.value.employment_type.label : form.value.employment_type,
    state_flows: JSON.stringify(getStateFlowValue()),
    skills: form.value.skills.join(',')
  }
}

const getJobVacancyDataUpdate = () => {
  return {
    start_at: dayjs(form.value.start_at, 'DD-MM-YYYY').format('YYYY-MM-DD'),
    end_date: dayjs(form.value.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
    workplace_type: form.value.workplace_type.label ? form.value.workplace_type.label : form.value.workplace_type,
    employment_type: form.value.employment_type.label ? form.value.employment_type.label : form.value.employment_type,
    state_flows: JSON.stringify(getStateFlowValue()),
    job_title: form.value.job_title,
    position_id: form.value.position_id,
    location: form.value.location,
    min_salary: form.value.min_salary,
    max_salary: form.value.max_salary,
    responsibilities: form.value.responsibilities,
    requirements: form.value.requirements,
    skills: form.value.skills.join(','),
    description: form.value.description,
  }
}

const fetchPosition = () => {
  return getPositionList()
      .then((res) => {
        return res.data;
      })
      .catch(() => {

      })
}

const fetchWorkplaceType = () => {
  return getMasterReferenceByType(WORKPLACE_TYPE);
}

const fetchEmploymentType = () => {
  return getMasterReferenceByType(EMPLOYMENT_TYPE);
}

const jobCodeGenerate = () => {
  return generateJobCode();
}

const fetchListState = () => {
  return getListState()
      .then((response) => {
        const rows = response.map((item) => {
          return {
            ...item,
            active: true
          }
        })
        fixedRows.value = rows.slice(0, 2)
        draggableRows.value = rows.slice(2);
      })
}
const fetchSkills = () => {
  return getSkills({limit: 1000})
      .then((res) => {
        skillOptions.value = res.map(c => {
          return {
            label: c.name,
            value: c.code,
          }
        });
      }).catch(() => {
        skillOptions.value = null
      });
}

const fetchQuestions = () => {
  return getQuestionBank({limit: 1000})
      .then((res) => {
        questionOptions.value = res.response.map(c => {
          return {
            label: c.name + ' | ' + c.description,
            value: c.id
          }
        });
        questionOptions.value.unshift({value: 0, label: 'Tidak melakukan pengisian'});
      }).catch(() => {
        questionOptions.value = null;
      })
}


const fetchDetailJobVacancy = async (id) => {
  const data = await getDetailJobVacancy(vacancyId.value ?? id);
  data.skills = data.skills.split(',');
  return data;
}

const filterSkills = (val, update, abort) => {
  if (val.length < 2) {
    abort();
    return
  }

  update(async () => {
    const keyword = val.toLowerCase();
    await getSkills({name: keyword})
        .then((res) => {
          skillOptions.value = res.map(c => {
            return {
              label: c.name,
              value: c.code,
            }
          });
        }).catch(() => {
          skillOptions.value = null
        })
  })
}

const filterJobReference = (val, update, abort) => {
  if (val.length < 3) {
    abort();
    return;
  }
  update( async () => {
    const keyword = val.toLowerCase();
    await searchJobVacancy({ keyword }).then(res => {
      jobReferenceOptions.value = res.map(c => {
        return {
          label: c.job_title,
          value: c.id
        }
      });
    }).catch(() => { jobReferenceOptions.value = null })
  })
}

const doPopulateForm = async () => {
  if (!jobReferenceId)
    return false;

  form.value = await fetchDetailJobVacancy(jobReferenceId.value);
  form.value.max_salary = Number(form.value.max_salary);
  form.value.min_salary = Number(form.value.min_salary);
  form.value.job_code = await jobCodeGenerate();

  form.value.start_at = dayjs(form.value.start_at, 'YYYY-MM-DD').format('DD-MM-YYYY')
  form.value.end_date = dayjs(form.value.end_date, 'YYYY-MM-DD').format('DD-MM-YYYY')

}

onMounted(async () => {
  await fetchListState();
  await fetchSkills();
  await fetchQuestions();

  fixedRows.value.forEach(row => {
    if (!row.hasOwnProperty('question_bank_id')) {
      row.question_bank_id = 0;
    }
  });

  draggableRows.value.forEach(row => {
    if (!row.hasOwnProperty('question_bank_id')) {
      row.question_bank_id = 0;
    }
  })

  if (action.value !== 'create') {
    loader.show();
    try {
      form.value = await fetchDetailJobVacancy();

      form.value.start_at = dayjs(form.value.start_at, 'YYYY-MM-DD').format('DD-MM-YYYY')
      form.value.end_date = dayjs(form.value.end_date, 'YYYY-MM-DD').format('DD-MM-YYYY')

      id.value = form.value.id ?? null;
    } finally {
      loader.hide();
    }
  } else {
    form.value.job_code = await jobCodeGenerate();
  }
})

watch(() => selectedStep.value, (value) => {
  console.log(value);
})


</script>

<template>
  <AppPage :title="pageTitle">
    <template v-slot:default>
      <q-card flat>
        <q-card-section>
          <div class="q-gutter-y-lg q-mt-md">
            <div class="row">
              <div class="col-12 q-pb-md" v-if="action === 'create'">
                <label class="q-my-sm text-bold">Referensi Lowongan Pekerjaan</label>
                <q-select
                    id="reference-job-vacancy-input"
                    input-debounce="300"
                    outlined dense use-input bottom-slots
                    v-model="jobReferenceId"
                    @filter="filterJobReference"
                    :options="jobReferenceOptions"
                    clearable
                    option-value="value"
                    option-label="label"
                    emit-value
                    map-options
                    hint="Ketik job title untuk mencari... digunakan sebagai template lowongan kerja baru"
                    @update:model-value="doPopulateForm"
                >
                  <template v-slot:no-option>
                    <div class="q-ma-sm">data tidak ditemukan</div>
                  </template>
                </q-select>
              </div>
              <div class="col-6 q-pr-sm q-gutter-y-sm">
                <label class="q-my-sm text-label">Nama Lowongan <span class="text-red">*</span></label>
                <VInput
                    for="job-vacancy-name-field-id"
                    v-model="form.job_title"
                    @blur="$vuelidate.job_title.$touch"
                    :errors="$vuelidate.job_title.$errors"
                    :readonly="isReadOnly"
                />
              </div>
              <div class="col-6 q-pl-sm q-gutter-y-sm">
                <label class="q-my-sm text-label">Posisi <span class="text-red">*</span></label>
                <VPreloadedSelect
                    for="position-field-id"
                    v-model="form.position_id"
                    :fetch="fetchPosition"
                    :options="positionOptions"
                    @blur="$vuelidate.position_id.$touch"
                    :errors="$vuelidate.position_id.$errors"
                    :readonly="isReadOnly"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 q-pr-sm q-gutter-y-sm">
                <label class="q-my-sm text-label">Lokasi <span class="text-red">*</span></label>
                <VInput
                    for="input-location"
                    v-model="form.location"
                    @blur="$vuelidate.location.$touch"
                    :errors="$vuelidate.location.$errors"
                    :readonly="isReadOnly"
                />
              </div>
              <div class="col-6 q-pl-sm q-gutter-y-sm">
                <label class="q-my-sm text-label">Jenis Tempat Kerja <span class="text-red">*</span></label>
                <VPreloadedSelect
                    for="input-workplace-type"
                    v-model="form.workplace_type"
                    :emit-value="false"
                    :fetch="fetchWorkplaceType"
                    :options="workplaceTypeOptions"
                    @blur="$vuelidate.workplace_type.$touch"
                    :errors="$vuelidate.workplace_type.$errors"
                    :readonly="isReadOnly"
                    :transform-value-key="'code'"
                    :transform-label-key="'name'"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 q-pr-sm q-gutter-y-sm">
                <label class="q-my-sm text-label">Gaji Minimal <span class="text-red">*</span></label>
                <VCurrencyInput
                    for="input-min-salary"
                    v-model="form.min_salary"
                    @blur="$vuelidate.min_salary.$touch"
                    :errors="$vuelidate.min_salary.$errors"
                    :readonly="isReadOnly"
                />
              </div>
              <div class="col-6 q-pl-sm q-gutter-y-sm">
                <label class="q-my-sm text-label">Gaji Maksimal <span class="text-red">*</span></label>
                <VCurrencyInput
                    for="input-max-salary"
                    v-model="form.max_salary"
                    @blur="$vuelidate.max_salary.$touch"
                    :errors="$vuelidate.max_salary.$errors"
                    :readonly="isReadOnly"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 q-pr-sm q-gutter-y-sm">
                <label class="q-my-sm text-label">Jenis Pekerjaan <span class="text-red">*</span></label>
                <VPreloadedSelect
                    for="input-employment-type"
                    v-model="form.employment_type"
                    :fetch="fetchEmploymentType"
                    :emit-value="false"
                    :options="employmentTypeOptions"
                    @blur="$vuelidate.employment_type.$touch"
                    :errors="$vuelidate.employment_type.$errors"
                    :readonly="isReadOnly"
                    :transform-value-key="'code'"
                    :transform-label-key="'name'"
                />
              </div>
              <div class="col-6 q-pr-sm q-gutter-y-sm">
                <label class="q-my-sm text-label">Skills <span class="text-red">*</span></label>
                <q-select
                    v-model="form.skills"
                    use-input
                    use-chips
                    emit-value
                    map-options
                    multiple
                    outlined
                    dense
                    :options="skillOptions"
                    @filter="filterSkills"
                    :readonly="isReadOnly"
                    hide-dropdown-icon
                    input-debounce="300"
                    option-label="label"
                    option-value="value"
                    new-value-mode="add"
                    hint="Tekan enter untuk menambahkan item"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 q-pr-sm q-gutter-y-sm">
                <label class="q-my-sm text-label">Mulai <span class="text-red">*</span></label>
                <QDateInput
                    id="input-start-date"
                    v-model="form.start_at"
                    @blur="$vuelidate.start_at.$touch"
                    :errors="$vuelidate.start_at.$errors"
                    :readonly="isReadOnly"
                />
              </div>
              <div class="col-6 q-pl-sm q-gutter-y-sm">
                <label class="q-my-sm text-label">Selesai <span class="text-red">*</span></label>
                <QDateInput
                    id="input-end-date"
                    v-model="form.end_date"
                    @blur="$vuelidate.end_date.$touch"
                    :errors="$vuelidate.end_date.$errors"
                    :readonly="isReadOnly"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 q-pl-sm q-gutter-y-sm">
                <label class="q-my-sm text-label">Kode Referensi Lowongan <span class="text-red">*</span></label>
                <VInput
                    for="input-job-code"
                    v-model="form.job_code"
                    @blur="$vuelidate.job_code.$touch"
                    :errors="$vuelidate.job_code.$errors"
                    readonly
                    filled
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 q-gutter-y-sm">
                <label class="q-my-sm text-label">Deskripsi <span class="text-red">*</span></label>
                <q-editor
                    for="input-description"
                    v-model="form.description"
                    @blur="$vuelidate.description.$touch"
                    :errors="$vuelidate.description.$errors"
                    type="textarea"
                    :readonly="isReadOnly"
                    :toolbar="[['bold', 'italic', 'strike', 'underline', 'unordered', 'ordered']]"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 q-gutter-y-sm">
                <label class="q-my-sm text-label">Persyaratan <span class="text-red">*</span></label>
                <q-editor
                    type="textarea"
                    for="input-requirements"
                    v-model="form.requirements"
                    @blur="$vuelidate.requirements.$touch"
                    :errors="$vuelidate.requirements.$errors"
                    :readonly="isReadOnly"
                    :toolbar="[['bold', 'italic', 'strike', 'underline', 'unordered', 'ordered']]"
                />
              </div>
            </div>
            <div class="col-12 q-pl-sm q-gutter-y-sm">
              <label class="q-my-sm text-label">Tanggung Jawab <span class="text-red">*</span></label>
              <q-editor
                  type="textarea"
                  for="input-responsibility"
                  v-model="form.responsibilities"
                  @blur="$vuelidate.responsibilities.$touch"
                  :errors="$vuelidate.responsibilities.$errors"
                  :readonly="isReadOnly"
                  :toolbar="[['bold', 'italic', 'strike', 'underline', 'unordered', 'ordered']]"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
      <q-card flat class="q-mt-md">
        <q-card-section v-if="action === 'create'">
          <div class="q-my-md text-weight-bold" style="font-size: 18px">Pilih Alur Lowongan</div>
          <div>Untuk mengatur ulang urutan alur lowongan kerja, klik dan tahan pada item di bawah, lalu geser ke posisi
            yang diinginkan. Lepaskan untuk menetapkan posisi baru.
          </div>
          <q-markup-table>
            <thead class="bg-table-header">
            <tr>
              <th>Urutan</th>
              <th>Nama</th>
              <th>Deskripsi</th>
              <th>Paket soal</th>
              <th></th>
            </tr>
            </thead>
            <tr v-for="(row, index) in fixedRows" :key="index" class="bg-fixed-table-row"
                :class="!row.active ? 'inactive-step' : ''">
              <td>{{ index + 1 }}</td>
              <td>{{ row.name }}</td>
              <td>{{ row.description }}</td>
              <td>
                <q-select :options="questionOptions" v-model="row.question_bank_id" emit-value map-options use-input option-label="label" option-value="value"></q-select>
              </td>
              <td style="text-align: center">
              </td>
            </tr>
            <draggable v-model="draggableRows" tag="tbody" :animation="300" item-key="id">
              <template #item="{ element: row, index}">
                <tr class="draggable-rows" :class="!row.active ? 'inactive-step' : ''">
                  <td>{{ index + 3 }}</td>
                  <td>{{ row.name }}</td>
                  <td>{{ row.description }}</td>
                  <td>
                    <q-select :options="questionOptions" v-model="row.question_bank_id" emit-value map-options use-input option-label="label" option-value="value"></q-select>
                  </td>
                  <td style="text-align: center">
                    <q-checkbox v-model="row.active"/>
                  </td>
                </tr>
              </template>
            </draggable>
          </q-markup-table>
        </q-card-section>
        <q-card-section v-else>
          <div class="q-my-md text-weight-bold" style="font-size: 18px">Daftar Alur Lowongan</div>
          <q-table
              id="table-list-step"
              :columns="columnsStateFlows"
              :rows="rows"
              flat
              table-header-class="bg-table-header"
              bordered
          >
            <template v-slot:body-cell-name="props">
              <q-td :props="props">
                {{ props.row.mststate.name }}
              </q-td>
            </template>
            <template v-slot:body-cell-description="props">
              <q-td :props="props">
                {{ props.row.mststate.description }}
              </q-td>
            </template>
          </q-table>
        </q-card-section>
      </q-card>
      <div v-if="action === 'create'">
        <div class="flex justify-end q-mt-md q-gutter-x-xs">
          <q-btn id="btn-back" label="Batal" unelevated flat @click="redirectBack"/>
          <q-btn id="btn-save" label="Simpan" color="primary" unelevated @click="handleSubmit"/>
        </div>
      </div>
      <div v-else>
        <div class="flex justify-end q-mt-md q-gutter-x-xs">
          <q-btn id="btn-back" label="Kembali" color="secondary" unelevated @click="redirectBack"/>
          <q-btn id="btn-edit" label="Simpan" color="orange" unelevated @click="handleSubmit"/>
        </div>
      </div>
    </template>
  </AppPage>
</template>

<style scoped>
.draggable-rows {
  cursor: grab;
}

.inactive-step {
  background-color: #effce5;
}

.text-label {
  font-weight: bold;
}
</style>
