export const requiredValidateLabel = value => `${value} tidak boleh kosong`;

export const MIN_LENGTH_VALIDATE_LABEL = (field_name, value) => `Field ${field_name} harus lebih dari ${value} karakter`
export const MAX_LENGTH_VALIDATE_LABEL = (field_name, value) => `Field ${field_name} tidak boleh lebih dari ${value} karakter`
export const EMAIL_VALIDATE_LABEL = 'Field bukan alamat email yang valid'

export const IS_SAME_VALIDATE_LABEL = (field1, field2) => `${field1} tidak sama dengan ${field2}`
export const URL_VALIDATE_LABEL = 'Field bukan alamat url yang valid'
export const MAX_VALUE_VALIDATE_LABEL = value => `Nilai maksimal yang diizinkan adalah ${value}`
export const MIN_VALUE_VALIDATE_LABEL = value => `Nilai minimal yang diizinkan adalah ${value}`
export const INTEGER_VALIDATE_LABEL = 'Field bukan merupakan bilangan integer'
export const DECIMAL_VALIDATE_LABEL = 'Field bukan merupakan bilangan desimal'
export const ALPHA_VALIDATE_LABEL = 'Field bukan merupakan sebuah alfabet'
export const ALPHA_NUMERIC_VALIDATE_LABEL = 'Field bukan merupakan sebuah alfa-numeric'
export const NUMERIC_VALIDATE_LABEL = 'Field harus merupakan bilangan numeric'
export const IP_ADDRESS_VALIDATE_LABEL = 'Field bukan merupakan Alamat IP'

export const CONFIRM_PASSWORD_LABEL = 'Konfirmasi password tidak valid'
