<script setup>
import {computed, onMounted, ref, watch} from "vue";
import AppPage from "@/components/AppPage.vue";
import JobVacancyInformation from "@/components/shared/JobVacancyInformation.vue";
import {
  getDetailJobVacancy,
  postNextStep,
  getByJob,
  getReferences,
  getSkills,
  getMajor,
  getInstitutionsByName, getCountCandidateAudit, duplicatJobVacancy
} from "@/lib/api.js";
import {useRoute} from "vue-router";
import VPrevStepperButton from "@/components/button/VPrevStepperButton.vue";
import {
  BACK_ACTION_LABEL,
  CONFIRMATION_TITLE_LABEL,
  NEXT_ACTION_LABEL,
  PREV_ACTION_LABEL, SAVE_CONFIRMATION_LABELS,
  NEXT_STEP_CONFIRMATION_TITLE_LABEL, DONE_ACTION_LABEL
} from "@/constants/label.js";
import router from "@/router/index.js";
import VNextStepperButton from "@/components/button/VNextStepperButton.vue";
import JobVacancyStep from "@/pages/jobMgmt/jobVacancy/JobVacancyFlow/JobVacancyStep.vue";
import useConfirmation from "@/plugins/confirmation/index.js";
import AppModal from "@/components/AppModal.vue";
import {
  columnsKomentar,
  defaultComment,
} from "@/pages/jobMgmt/jobVacancy/job-vacancy.js";
import useNotify from "@/plugins/notify/index.js";
import useLoader from "@/plugins/loader/index.js";
import QBackButton from "@/components/button/QBackButton.vue";
import AppTable from "@/components/AppTable.vue";
import FilterCard from "@/components/FilterCard.vue";
import VInput from "@/components/input/VInput.vue";
import VPreloadedSelect from "@/components/input/VPreloadedSelect.vue";
import {EDUCATION_TYPE, LEVEL_TYPE} from "@/constants/reference.js";

const confirm = useConfirmation();
const route = useRoute();
const experienceOptions = ref([]);
const educationOptions = ref([]);
const universitasOptions = ref([]);
const majorOptions = ref([]);
const skillOptions = ref([]);
const step = ref(1);
const information = ref([]);
const vacancyId = ref(route?.params?.id || null);
const stepper = ref(null);
const rows = ref([]);
const modal = ref(false);
const notify = useNotify();
const loader = useLoader();
const filter = ref({
  job_id: information.value.id,
  name: null,
  university_name: null,
  experience_level: null,
  education: null,
  major: null,
  skills: null
});
const isDuplicateActive = ref(false);

const isFilterActive = computed(() => {
  for (let item in filter.value) {
    if (filter.value[item] !== null) {
      return true;
    }
  }
  return false;
})
const redirectBack = () => {
  router.push({
    name: 'JobVacancy'
  })
}
const getLabelButtonNext = computed(() => {
  let label = NEXT_ACTION_LABEL;
  if (information.value.state_flows !== undefined && Number(step.value) === Number(information.value.state_flows.length)) {
    label = DONE_ACTION_LABEL;
  }
  return label;
})

const isLastStep = computed(() => {
  let bool = false;
  if (information.value.state_flows !== undefined && Number(step.value) === Number(information.value.state_flows.length)) {
    bool = true;
  }
  return bool;
})

const selectedApplicants = ref([]);

const fetchApplicants = () => {
  const filterData = getFilterApplicants();
  return getByJob(filterData).then((res) => {
    if (res.data) {
      rows.value = mappingRowsValue(res.data);
    }
  })
}

const fetchExperience = () => {
  return getReferences(LEVEL_TYPE).then((res) => {
    return res
  }).catch(() => {
  })
}

const fetchEducation = () => {
  return getReferences(EDUCATION_TYPE).then((res) => {
    return res
  }).catch(() => {
  })
}

const getFilterApplicants = () => {
  const filterData = {};
  if (information.value.id) filterData.job_id = information.value.id;
  if (filter.value.name) filterData.name = filter.value.name;
  if (filter.value.education) filterData.education = filter.value.education;
  if (filter.value.skills) filterData.skills = filter.value.skills;
  if (filter.value.university_name) filterData.university_name = filter.value.university_name;
  if (filter.value.major) filterData.major = filter.value.major;
  if (filter.value.experience_level) filterData.experience_level = filter.value.experience_level;
  return filterData;
}

const resetFilter = () => {
  for (let item in filter.value) {
    filter.value[item] = null
  }
  fetchApplicants();
}

const filterMajor = (val, update, abort) => {
  if (val.length < 3) {
    abort();
    return;
  }
  update(async () => {
    const keyword = val.toLowerCase();
    await getMajor({name: keyword}).then(res => {
      majorOptions.value = res.map(c => {
        return {
          label: c.name,
          value: c.code
        }
      });
    }).catch(() => {
      majorOptions.value = null
    })
  })
}

const filterKeahlian = (val, update, abort) => {
  if (val.length < 2) {
    abort();
    return
  }

  update(async () => {
    const keyword = val.toLowerCase();
    await getSkills({name: keyword})
        .then((res) => {
          skillOptions.value = res.map(c => {
            return {
              label: c.name,
              value: c.code,
            }
          });
        }).catch(() => {
          skillOptions.value = null
        })
  })
}

const filterUniversitas = (val, update, abort) => {
  if (val.length < 3) {
    abort();
    return;
  }
  update(async () => {
    const keyword = val.toLowerCase();
    await getInstitutionsByName({name: keyword}).then(res => {
      universitasOptions.value = res.map(c => {
        return {
          label: c.name,
          value: c.code
        }
      });
    }).catch(() => {
      universitasOptions.value = null
    })
  })
}

const onPrevStep = () => {
  if (step.value <= 1) {
    redirectBack();
  }
  return stepper.value?.previous();
}

const latestState = computed(() => {
  return information.value.latest_state;
});

const isStateFinished = computed(() => {
  return Number(information.value.state_flows.length) === Number(step.value);
})

const rejectedApplicants = computed(() => {
  const applicants = JSON.parse(JSON.stringify(rows.value));
  return applicants.reduce((acc, item) => {
    let filteredLog = item.step_log.filter(e => !e.is_audited && (latestState.value.id === e.job_state_id));
    if (filteredLog.length > 0) {
      acc.push({
        ...item
      })
    }
    return acc;
  }, [])
})

const onNextStep = () => {
  if (Number(information.value.latest_state.display_order) !== step.value) return stepper.value?.next();
  handleShowModal();
}

const handleShowModal = async () => {
  const applicant_ids = rows.value.map(item => { return item.id });
  const params = {
    job_id: information.value.latest_state?.job_id,
    job_state_id: information.value.latest_state?.id,
    applicant_ids: applicant_ids.join(",")
  }
  let checkAuditedCandidate
  try {
    loader.show();
    checkAuditedCandidate = await getCountCandidateAudit(params);
  } finally {
    loader.hide();
  }
  if (rejectedApplicants.value.length <= 0 && checkAuditedCandidate.response?.not_audited === 0) {
    saveApplicantStep();
  } else {
    notify.show({
      message: 'Tidak dapat melakukan lanjut tahapan, terdapat pelamar yang masih belum diproses',
      type: 'error'
    })
  }
}

const initiateComment = (participant) => {
  let changedComment = JSON.parse(JSON.stringify(comment.value));

  const replacements = {
    "{candidate_name}": participant.name ?? '',
    "{place}": participant.job_title ?? '',
    "{university}": participant.university ?? ''
  };


  Object.keys(replacements).forEach((placeholder) => {
    changedComment = changedComment.replace(new RegExp(placeholder, 'g'), replacements[placeholder]);
  });

  return changedComment;
}

const saveApplicantStep = () => {
  confirm
      .setTitle(CONFIRMATION_TITLE_LABEL)
      .setMessage(NEXT_STEP_CONFIRMATION_TITLE_LABEL)
      .setOnConfirm(() => {
        loader.show();
        const params = setNextStepParams();
        postNextStep(params, information.value.id, step.value, information.value.latest_state.display_order)
            .then(() => {
              notify.show({
                message: 'Step berhasil di update',
                type: 'success'
              })
              modal.value = false;
              comment.value = [];
              fetchJobVacancy(vacancyId.value)
              fetchApplicants();
              return stepper.value?.next();
            })
            .finally(() => {
              loader.hide()
            });
      })
      .show()
}

const setNextStepParams = () => {
  return rows.value.map((item) => {
    const log_step = item.step_log.map((log_item) => {
      if (Number(log_item.state_id) === step.value) {
        log_item.comment = !log_item.is_pass ? comment : '';
      }
      return log_item
    })
    return {
      applicant_id: item.applicant_id,
      state_id: step.value,
      log_step
    }
  });
}

const doDuplicate = () => {
  if (selectedApplicants.value.length > 0) {
    saveDuplicateJob();
  } else {
    notify.show({
      message: 'Silakan pilih minimal 1 pelamar untuk melanjukan proses duplikasi',
      type: 'warning'
    })
  }
}

const saveDuplicateJob = () => {
  confirm
      .setTitle(CONFIRMATION_TITLE_LABEL)
      .setMessage(`Apakah anda yakin akan melakukan duplikat dan memasukkan ${selectedApplicants.value.length} pelamar terpilih pada lowongan pekerjaan ini`)
      .setOnConfirm(async () => {
        const applicant_ids = [];
        selectedApplicants.value.forEach((item) => {
          applicant_ids.push(item.applicant_id);
        })
        try {
          const duplicatedJob = await duplicatJobVacancy(information.value.id, {applicant_ids})
          vacancyId.value = duplicatedJob.response?.job_id;
          confirmationRedirectPage()
        } finally {
          selectedApplicants.value = [];
          isDuplicateActive.value = false;
        }

      })
      .show()
}

const isInitialFetch = ref(true);
let preventWatch = ref(true);
const fetchJobVacancy = (id) => {
  return getDetailJobVacancy(id)
      .then((res) => {
        information.value = res;
        if (isInitialFetch.value) {
          step.value = Number(information.value.latest_state.state_id);
          isInitialFetch.value = false;
        }
        selectedApplicants.value = [];
      })
}

const mappingRowsValue = (applicants) => {
  return applicants.reduce((acc, item) => {
    let step_log = JSON.parse(item.step_log);
    if (step_log[step.value - 2]?.is_pass || step.value === 1) {
      acc.push({
        ...item,
        status_recruitment: item.status_recruitment,
        min_salary: item.min_salary,
        max_salary: item.max_salary,
        step_log: step_log,
        applicant_id: item.applicant_id
      });
    }
    return acc;
  }, []);
}

const doFetch = async () => {
  await fetchJobVacancy(vacancyId.value);
  await fetchApplicants();
}

const onCancelDuplicate = () => {
  confirm
      .setTitle(CONFIRMATION_TITLE_LABEL)
      .setMessage('Batalkan aksi duplikasi lowongan kerja?')
      .setOnConfirm(() => {
        isDuplicateActive.value = false;
        selectedApplicants.value = [];
      }).setOnCancel(() => { return false; })
      .show();
}

const handleDuplicateJob = () => {
  confirm
      .setTitle(CONFIRMATION_TITLE_LABEL)
      .setMessage('Apakah anda ingin duplikat lowongan kerja ini?')
      .setOnConfirm(() => {
        isDuplicateActive.value = true;
      }).setOnCancel(() => { return false; })
      .show();
}

onMounted(async () => {
  await doFetch();
})

const confirmationRedirectPage = () => {
  confirm
      .setTitle('Duplikat berhasil')
      .setMessage('Apakah anda ingin pergi ke halaman lowongan duplikat ini?')
      .setOnConfirm(() => {
        router.push({
          name: 'JobVacancyFlow',
          params: {
            id: vacancyId.value
          }
        })
        doFetch()
        notify.show({
          message: 'Berhasil beralih ke halaman lowongan yang telah di duplikat',
          type: 'success'
        })
      })
      .show();
}


const comment = ref(defaultComment(rows.value));
watch(() => step.value, (value, oldValue) => {
  if (value !== oldValue && !preventWatch.value) {
    fetchJobVacancy(vacancyId.value);
    fetchApplicants();
  }
  preventWatch.value = false;
})

watch(() => isDuplicateActive.value, (value) => {
  if (value) {
    notify.show({
      message: 'Pilih pelamar yang ingin Anda masukkan ke daftar Lamaran Pekerjaan yang akan diduplikat dengan cara ceklis kolom pertama pada daftar kandidat',
      timeout: 7000
    })
  }
})

</script>

<template>
  <AppPage title="Alur Lowongan Kerja">
    <div class="q-pa-md">
      <JobVacancyInformation
          :data="information"
          :use-copy-clipboard="true"
          title="Informasi Lowongan Kerja"
      >
        <template v-slot:top-action>
          <q-btn class="bg-red-3 text-white" flat dense unelevated icon="dynamic_feed" v-show="!isDuplicateActive"
                 @click="handleDuplicateJob">
            <q-tooltip class="bg-indigo" :offset="[10, 10]">
              Duplikat Lowongan Kerja
            </q-tooltip>
          </q-btn>
        </template>
      </JobVacancyInformation>
      <FilterCard @filter="fetchApplicants" @reset="resetFilter">
        <div class="row q-gutter-xl q-mb-md">
          <div class="col">
            <div class="q-mb-sm">Nama Pelamar</div>
            <VInput v-model="filter.name"/>
          </div>
          <div class="col">
            <div class="q-mb-sm">Universitas</div>
            <q-select
                input-debounce="300"
                outlined
                dense
                use-input
                v-model="filter.university_name"
                @filter="filterUniversitas"
                :options="universitasOptions"
                clearable
                option-value="value"
                option-label="label"
                emit-value
                map-options
                hint="Ketik minimal 3 huruf untuk menampilkan hasil filter"
            ></q-select>
          </div>
        </div>
        <div class="row items-center q-gutter-xl q-mb-md">
          <div class="col">
            <div class="q-mb-sm">Tingkat Keahlian</div>
            <VPreloadedSelect
                for="input-filter-workplace-type"
                v-model="filter.experience_level"
                emit-value
                transformValueKey="code"
                :fetch="fetchExperience"
                :options="experienceOptions"
            />
          </div>
          <div class="col">
            <div class="q-mb-sm">Pendidikan</div>
            <VPreloadedSelect
                for="input-filter-workplace-type"
                v-model="filter.education"
                emit-value
                :fetch="fetchEducation"
                transformValueKey="code"
                :options="educationOptions"
            />
          </div>
        </div>
        <div class="row items-center q-gutter-xl q-mb-md">
          <div class="col">
            <div class="q-mb-sm">Jurusan</div>
            <q-select
                input-debounce="300"
                outlined
                dense
                use-input
                v-model="filter.major"
                @filter="filterMajor"
                :options="majorOptions"
                clearable
                option-value="value"
                option-label="label"
                emit-value
                map-options
                hint="Ketik minimal 3 huruf untuk menampilkan hasil filter"
            ></q-select>
          </div>
          <div class="col">
            <div class="q-mb-sm">Keahlian</div>
            <q-select
                input-debounce="300"
                outlined
                dense
                use-input
                v-model="filter.skills"
                @filter="filterKeahlian"
                :options="skillOptions"
                clearable
                option-value="value"
                option-label="label"
                emit-value
                map-options
                hint="Ketik minimal 2 huruf untuk menampilkan hasil filter"
            ></q-select>
          </div>
        </div>
      </FilterCard>

      <q-stepper
          v-model="step"
          header-nav
          ref="stepper"
          color="primary"
          flat
          active-color="purple"
          done-color="green"
      >
        <q-step
            v-for="(item, index) in information.state_flows"
            :key="index"
            :name="index + 1"
            :title="item.mststate.name"
            icon="settings"
            :done-icon="index+1 >= Number(information.latest_state.display_order) ? 'settings': 'done'"
            :done="true"
        >
          <div class="text-red" v-show="isDuplicateActive">
            <i>*Silakan pilih (&check;) pelamar yang akan diduplikat pada tahapan ini</i>
          </div>
          <div>
            <JobVacancyStep :key="'job_vacancy-step-' + index" id="job-vacancy-step" :job="information" :rows="rows" v-model:selected="selectedApplicants"
                            :current-state="step" @audited="doFetch" :is-filter-active="isFilterActive"
                            :isDuplicateActive="isDuplicateActive" :has-form="item.hasform" :job-state-id="item.id"/>
          </div>
        </q-step>
        <template v-slot:navigation>
          <div class="flex justify-end q-mr-md q-gutter-md" v-show="isDuplicateActive">
            <q-btn
                label="Batal"
                flat
                color="grey"
                size="md"
                @click="onCancelDuplicate"
            />
            <q-btn
                label="Duplikat"
                icon="dynamic_feed"
                unelevated
                color="red-3"
                size="md"
                @click="doDuplicate"
            />
          </div>
          <q-separator/>
          <q-stepper-navigation class="flex justify-between q-mt-md">
            <VPrevStepperButton id="prev-step-button" :style="{ visibility: step === 1 ? 'hidden'  : ''}"
                                :label="PREV_ACTION_LABEL"
                                @click="onPrevStep" v-show="!isDuplicateActive"/>
            <VNextStepperButton id="next-step-button"
                                :label="getLabelButtonNext"
                                @click="onNextStep" v-show="!isDuplicateActive && !isStateFinished"/>
          </q-stepper-navigation>
        </template>
      </q-stepper>
      <div class="q-pt-sm q-mb-md">
        <QBackButton @click="redirectBack"/>
      </div>
      <AppModal
          title="Submit Komentar"
          v-model:modal="modal"
          persistent
          width="90em"
      >
        <template v-slot:content>
          <div>Daftar kandidat yang tidak lolos ke tahap selanjutnya, silakan isi komentar di form yang telah di
            sediakan
          </div>
          <AppTable
              :columns="columnsKomentar"
              :rows="rejectedApplicants"
              hide-pagination
              table-header-class="bg-table-header"
              flat
              enable-filter
          >
          </AppTable>
          <q-editor v-model="comment"></q-editor>
        </template>
        <template v-slot:action>
          <q-btn unelevated color="primary" class="q-mr-sm" :label="SAVE_CONFIRMATION_LABELS"
                 @click="saveApplicantStep"></q-btn>
        </template>
      </AppModal>
    </div>
  </AppPage>
</template>

<style>
.q-stepper__tab--active {
  background: #f1f1f1 !important;
}
</style>
