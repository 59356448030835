<script setup>
import AppPage from "@/components/AppPage.vue";
import AppTable from "@/components/AppTable.vue";
import {columnsJobPosition} from "@/pages/jobMgmt/position/job-position.js";
import {deletePosition, getPositionList, postPosition, putPosition} from "@/lib/api.js";
import {ref} from "vue";
import {
  MESSAGE_FAILED,
  MESSAGE_SUCCESS_CREATED,
  MESSAGE_SUCCESS_DELETED,
  MESSAGE_SUCCESS_UPDATED
} from "@/constants/default-messages.js";
import useNotify from "@/plugins/notify/index.js";
import QEditButtonGroup from "@/components/button/QEditButtonGroup.vue";
import QDeleteButtonGroup from "@/components/button/QDeleteButtonGroup.vue";
import {CONFIRMATION_TITLE_LABEL, DELETE_CONFIRMATION_LABEL} from "@/constants/label.js";
import useLoader from "@/plugins/loader/index.js";
import useConfirmation from "@/plugins/confirmation/index.js";
import FormPosition from "@/pages/jobMgmt/position/FormPosition.vue";

const title = 'Posisi Pekerjaan'
const keyword = ref(null);
const rows = ref([]);
const notify = useNotify();
const modal = ref(false);
const form = ref({
  id: null,
  name: '',
  description: ''
})
const tablePosition = ref(null);
const loader = useLoader();

const confirmation = useConfirmation();


const fetchJobPosition = (offset, limit) => {
  const params = {
    offset,
    limit,
    keyword: keyword.value,
    order: 'desc',
    sort: 'id'
  }
  return getPositionList(params).then((res) => {
    return {
      rows: res.data,
      total: res.total
    }
  }).catch((err) => {
    notify.show({
      message: err.message || err.messages || err.error || MESSAGE_FAILED,
      type: 'error'
    })
  })
}

const handleSave = () => {
  loader.show();
  return postPosition(form.value).then(() => {
    notify.show({
      message: MESSAGE_SUCCESS_CREATED,
      type: 'success'
    })
    modal.value = false;
    tablePosition.value.reset();
    loader.hide();
  }).catch((err) => {
    notify.show({
      message: err.message || err.messages || err.error || MESSAGE_FAILED,
      type: 'error'
    })
  })
}

const handleUpdate = () => {
  loader.show();
  return putPosition(form.value.id, form.value).then(() => {
    notify.show({
      message: MESSAGE_SUCCESS_UPDATED,
      type: 'success'
    })
    modal.value = false;
    tablePosition.value.reset();
    loader.hide();
  }).catch((err) => {
    notify.show({
      message: err.message || err.messages || err.error || MESSAGE_FAILED,
      type: 'error'
    })
  })
}

const handleTambah = () => {
  mode.value = 'CREATE'
  modal.value = true;
}

const mode = ref('CREATE');

const onDeleteItem = (id) => {
  confirmation
      .setTitle(CONFIRMATION_TITLE_LABEL)
      .setMessage(DELETE_CONFIRMATION_LABEL)
      .setOnConfirm(() => {
        loader.show();
        return deletePosition(id)
            .then(() => {
              notify.show({
                message: MESSAGE_SUCCESS_DELETED,
                type: 'success'
              })
              tablePosition.value.reset();
            })
            .catch((err) => {
              notify.show({
                message: err.message || err.messages || err.error || MESSAGE_FAILED,
                type: 'error'
              })
            })
            .finally(() => {
              loader.hide();
            })
      }).show();
}

const handleShowModalEdit = (row) => {
  mode.value = 'EDIT'
  form.value.id = row.id;
  form.value.name = row.name;
  form.value.description = row.description;
  modal.value = true;
}

</script>

<template>
  <AppPage :title="title">
    <template v-slot:default>
      <div class="flex justify-end q-mb-md">
        <q-btn id="btn-add-position" icon="add" unelevated label="Tambah" color="primary" @click="handleTambah"/>
      </div>
      <AppTable
          ref="tablePosition"
          enable-filter
          :fetch="fetchJobPosition"
          id="table-list-position"
          :columns="columnsJobPosition"
          v-model:keyword="keyword"
          v-model:rows="rows"
      >
        <template v-slot:body-cell-no="props">
          <q-td :props="props">
            {{ props.rowIndex + 1 }}
          </q-td>
        </template>
        <template v-slot:body-cell-action="props">
          <q-td :props="props" class="q-gutter-x-xs">
            <QEditButtonGroup @click="handleShowModalEdit(props.row)" />
            <QDeleteButtonGroup @click="onDeleteItem(props.row?.id)"/>
          </q-td>
        </template>
      </AppTable>
    </template>
  </AppPage>
  <FormPosition
      v-model="modal"
      :mode="mode"
      v-model:form="form"
      @save="handleSave"
      @update="handleUpdate"
  />
</template>
