<script setup>
import AppModal from "@/components/AppModal.vue";
import {computed} from "vue";
import dayjs from "@/lib/DateHelper.js";


const props = defineProps({
  modal: {
    type: Boolean,
    default: false
  },
  detailApplicants: {
    type: Object
  }
})

const emits = defineEmits(['update:modal', 'closeModal']);

const jenisKelaminField = (gender) => {
  switch (gender) {
    case 'MALE':
      return 'Laki-laki'
    case 'FEMALE':
      return 'Perempuan'
  }
}

const modal = computed({
  get() {
    return props.modal;
  },
  set(value) {
    emits('update:modal', value)
  }
})

</script>

<template>
  <AppModal
      v-model:modal="modal"
      title="Detail Pelamar"
      persistent
  >
    <template v-slot:content>
      <div class="text-weight-bold text-h6">Biodata Diri</div>
      <div class="row">
        <div class="col-3 text-bold">
          Nama
        </div>
        <div class="col-9">
          {{ detailApplicants?.name }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-bold">
          Jenis Kelamin
        </div>
        <div class="col-9">
          {{ jenisKelaminField(detailApplicants?.gender) }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-bold">
          Agama
        </div>
        <div class="col-9">
          {{ detailApplicants?.mstreligion ? detailApplicants?.mstreligion.name : detailApplicants.religion_other }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-bold">
          Domisili
        </div>
        <div class="col-9">
          {{ detailApplicants?.city }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-bold">
          Tanggal Lahir
        </div>
        <div class="col-9">
          {{ dayjs(detailApplicants?.birth_date, 'YYYY-MM-DD').format('DD-MM-YYYY') }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-bold">
          Nomor Telepon
        </div>
        <div class="col-9">
          <a target="_blank" :href="'https://wa.me/62' + detailApplicants?.phone_no">
            +62 {{ detailApplicants?.phone_no }}
          </a>
        </div>
      </div>
      <div class="text-weight-bold text-h6 q-mt-lg">Latar Belakang Pendidikan</div>
      <div class="row">
        <div class="col-3 text-bold">
          Pendidikan
        </div>
        <div class="col-9">
          {{ detailApplicants?.education_name }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-bold">
          Nama Kampus
        </div>
        <div class="col-9">
          {{ detailApplicants?.university_name ? detailApplicants?.university_name : (detailApplicants?.mstuniversity? detailApplicants?.mstuniversity.name : detailApplicants?.university_other) }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-bold">
          Jurusan
        </div>
        <div class="col-9">
          {{ detailApplicants?.major_name ? detailApplicants?.major_name : (detailApplicants?.mstmajor? detailApplicants?.mstmajor.name : detailApplicants?.major_other)}}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-bold">
          Email
        </div>
        <div class="col-9">
          {{ detailApplicants?.email }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-bold">
          Tingkat Keahlian
        </div>
        <div class="col-9">
          {{ detailApplicants?.experience_level_name }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-bold">
          Alamat CV
        </div>
        <div class="col-9">
          <a target="_blank" :href="detailApplicants?.cv_url">
            {{ detailApplicants?.cv_url }}
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-bold">
          Keahlian
        </div>
        <div class="col-9">
          {{ detailApplicants.skill_names ? detailApplicants?.skill_names.replaceAll(',',', ') : detailApplicants.skills.replaceAll(',', '') }}
        </div>
      </div>
    </template>
    <template v-slot:action>
      <q-btn
          label="Kembali"
          color="grey-5"
          unelevated
          @click="emits('closeModal')"
      ></q-btn>
    </template>
  </AppModal>

</template>

<style scoped>

</style>
