import { useQuasar } from 'quasar';
import { ref } from 'vue';
import LoadingDialog from '@/components/dialog/LoadingDialog.vue';

export default function useLoader() {
    const $q = useQuasar();
    const dialog = ref(null);

    const show = () => {
        dialog.value = $q.dialog({
            component: LoadingDialog,
            persistent: true,
            ok: false
        });
    };
    const hide = () => {
        dialog.value?.hide();
    };
    return {
        show,
        hide
    };
}
