<script setup>
import {ref, watch} from "vue";

const props = defineProps({
  label: {
    type: String,
    default: 'next'
  }
})

watch(() => props.label, (value, oldValue) => {
  btnLabel.value = value;
})

const btnLabel = ref(props.label);
</script>

<template>
  <q-btn
      :label="btnLabel"
      icon-right="chevron_right"
      unelevated
      color="primary"
      size="md"
      v-bind="$attrs"
  />
</template>

<style scoped></style>
