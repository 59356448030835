<script setup>
const props = defineProps({
  menu: Array
});
const setMenuName = (name) => {
  if (!name) return '';
  return name.split(' ').join('-').toLowerCase();
}
</script>

<template>
  <q-list style="font-size: 16px" class="q-mt-md q-px-sm">
    <div v-for="(item, index) in menu" :key="index">
      <q-expansion-item
          :label="item.name"
          :icon="item.icon"
          v-if="item.children && item.children.length > 0"
          :id="`menu-${setMenuName(item.name)}`"
          default-opened
      >
        <q-list class="q-pl-lg" v-for="(child, childIndex) in item.children" :key="childIndex" :id="`list-${setMenuName(child.name)}`">
          <q-item v-ripple clickable :to="{name: child.to}" :id="`item-${setMenuName(child.name)}`">
            <q-item-section avatar :id="`item-section-${setMenuName(child.name)}`">
              <q-icon :name="child.icon"/>
            </q-item-section>
            <q-item-section :id="`item-${setMenuName(item.name)}`">{{ child.name }}</q-item-section>
          </q-item>
        </q-list>
      </q-expansion-item>
      <q-item v-else v-ripple clickable :to="{name: item.to}" :id="`item-${setMenuName(item.name)}`">
        <q-item-section avatar>
          <q-icon :name="item.icon"/>
        </q-item-section>
        <q-item-section :id="`item-section-${setMenuName(item.name)}`">{{ item.name }}</q-item-section>
      </q-item>
    </div>
  </q-list>
  <div class="q-ma-sm claim-footer">
    @2024 Akhdani Reka Solusi
  </div>
</template>

<style>
 .claim-footer {
   position: fixed;
   bottom: 0;
   width: inherit;
   left: 1.5em;
   font-weight: 1000;
 }
</style>
