import { createApp } from 'vue'
import router from './router';
import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/src/css/index.sass'
import App from './App.vue'
import useQuasar from "@/plugins/quasar/index.js";
import {createPinia} from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
// import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './base.css'
import * as Sentry from '@sentry/vue';
// import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
const env = import.meta.env;


const myApp = createApp(App)
const pinia = createPinia();

Sentry.init({
    app: myApp,
    dsn: env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    enabled: env.VITE_SENTRY_ENABLED,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^http:\/\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

useQuasar(myApp);
pinia.use(piniaPluginPersistedstate);
myApp.use(pinia);
// myApp.use(CkeditorPlugin)
myApp.use(router);
myApp.mount('#app')
