<script setup>
import {onMounted, reactive, ref} from "vue";
import storeJobCode from "@/stores/applicants.js";
import { useRouter } from 'vue-router';
import {getJobByCode} from "@/lib/api.js";
import useNotify from "@/plugins/notify/index.js";
import VInput from "@/components/input/VInput.vue";
import AppPage from "@/components/AppPage.vue";
import useAuthStore from "@/stores/auth.js";
const form = reactive({
  jobCode: '',
})
const loading = ref(false);
const authState = useAuthStore()
const jobState = storeJobCode();
const router = useRouter();
const notify = useNotify();

const doSubmit = async () => {

  if (!form.jobCode) {
    notify.show({
      message: 'Code cannot be empty',
      type: 'error'
    })
    return;
  }

  loading.value = true;
  getJobByCode(form.jobCode)
      .then((res) => {
        if (res.job_code) {
          jobState.setState(form.jobCode);
          notify.show({
            message: 'Success',
            type: 'success'
          })
          router.push({
            name: 'Application',
          })
        } else {
          notify.show({
            message: 'Cannot find data',
            type: 'error'
          })
        }
      })
      .catch((err) => {
        notify.show({
          message: err.message,
          type: 'error'
        })
      })
      .finally(() => {
        loading.value = false;
      })
}

const resetJobCode = () => {
  jobState.setState(null)
}

onMounted(() => {
  resetJobCode()
})

</script>

<template>

  <AppPage disable-bread-crumb>
    <q-card flat class="shadow-2 q-px-md" style="width: 40rem; margin: 0 auto">

      <q-card-section>
        <div class="text-h5 text-bold text-center q-pa-md">Selamat datang, {{authState.user}} </div>
        <div class="text-center q-pa-xs">Masukkan kode referensi yang telah dikirim melalui email</div>
      </q-card-section>

      <q-card-section>
        <q-form class="q-gutter-y-md" id="login-form" @submit="doSubmit">
          <label class="q-mx-sm">Kode<span class="text-red">*</span></label>
          <VInput
              type="text"
              v-model="form.jobCode"
              dense
              outlined
              for="job-code-field"
          />
          <q-btn
              id="submit-job-btn"
              label="Lanjut"
              no-caps
              class="full-width"
              style="border-radius: 10px"
              :loading="loading"
              @click="doSubmit"
              color="primary">
          </q-btn>
        </q-form>
      </q-card-section>


    </q-card>
  </AppPage>
</template>

<style scoped>
body{
  background: red;
}
</style>
